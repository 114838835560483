/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Card, Col, ListGroup } from "react-bootstrap";
import { Path } from "utils/constant.data";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { capitalize } from "utils/strings";

const MyCoursesCard = () => {
  const profileState = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const profile = profileState?.profileInfo?.data?.profile;

 
  return (
    <Col xl="6">
      {profileState?.isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Card>
          <Card.Header className="d-block">
            <Card.Title>My Courses</Card.Title>
            <Card.Text className="m-0 subtitle"></Card.Text>
          </Card.Header>
          {profile ? (
            profileState?.profileInfo?.data?.profile?.consent_accepted ? (
              <Card.Body className="pt-0">
                <ListGroup variant="flush">
                  <ListGroup.Item
                    className="link-hover"
                    onClick={() => navigate(Path.FELLOW_MY_COURSES)}
                  >
                    {capitalize(profile?.fellow_course?.title ?? profile?.current_learning_course)}
                  </ListGroup.Item>
                  <ListGroup.Item></ListGroup.Item>
                </ListGroup>
                {/* */}
              </Card.Body>
            ) : (
              <Fragment>
                <Card.Body>
                  <div className="d-flex justify-content-center">
                    <BagIcon />
                  </div>
                  <div className="d-flex justify-content-center">
                    <p>You have no courses yet</p>
                  </div>
                </Card.Body>
              </Fragment>
            )
          ) : (
            <Card.Body>
              <div className="d-flex justify-content-center">
                <BagIcon />
              </div>
              <div className="d-flex justify-content-center">
                <p>You have no courses yet</p>
              </div>
            </Card.Body>
          )}
        </Card>
      )}
    </Col>
  );
};

export default MyCoursesCard;
