import { useState } from "react";
import { useGetProviderBranchesQuery } from "store/services/adminAPI";
import { FadeLoader } from "react-spinners";
import { Card, Button } from "react-bootstrap";
import SubApplicationForm from "./ProviderBranchForm";
import EmptyNotice from "component/ErrorNotice";

const ProviderBranches = () => {
    const {data:branches, isLoading:fetchingBranches} = useGetProviderBranchesQuery();  
    const [createMode, setCreateMode] = useState(false)

    const toggleCreateMode = () => {
        setCreateMode(false)
    }


    return (
        <div className="pt-4 border-bottom-1 pb-3">
          <div className="row justify-content-between align-items-center mb-4">
        <div className="col-md-auto align-items-center">
            <h4 className="fs-26 font-w800 text-black mb-3 text-pigment-green">Your Branches</h4>
            <p className="text-warning block">
                {branches?.data?.length >= 7 ? (
                    "You have used up all your branches allocation"
                ) : (
                    <>
                    You can add only{" "}
                    {5 - branches?.data?.length === 1
                        ? "1 additional branch"
                        : `${5 - branches?.data?.length} additional branches`}
                    </>
                )}
            </p>
        </div>
    <div className="col-md-auto d-flex align-items-center">

            <Button
                type="submit"
                disabled={branches?.data?.length > 6}
                className="py-3 btn-pigment-green "
                onClick={() => setCreateMode(!createMode)}
            >
                {createMode ? "Discard" : "Add branch"}
            </Button>

        
    </div>
</div>

            
            {createMode ? (
                <SubApplicationForm 
                    toggleCreateMode={toggleCreateMode}
                />
            ) : (
                <>
                {fetchingBranches ? (
                    <div className="d-flex justify-content-center align-items-center height-15 bg-white">
                        <FadeLoader color="#36d7b7" />
                    </div>
                ) : (
                    branches && branches.data.length > 0 ? (
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            {branches.data.map((branch) => (
                                <div className="col" key={branch.id}>
                                    <div className="card peach-container">
                                        <div className="card-header">
                                            <h4>{branch.branch_name}</h4>
                                        </div>
                                        <div className="card-body">
                                            <p><strong>Branch Email:</strong> {branch.email}</p>
                                            <p><strong>Branch Phone:</strong> {branch.phone_number}</p>
                                            <p><strong>Branch State:</strong> {branch.state}</p>
                                            <p><strong>Branch LGA:</strong> {branch.lga}</p>
                                            <p><strong>Branch Address:</strong> {branch.address}</p>
                                            <p><strong>Staff Count:</strong> {branch.staff_count}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Card.Body>
                            <EmptyNotice text="You have not added any branches" />
                        </Card.Body>
                    )
                )}
            </>
            
            )}
         </div>

    );
    
}

export default ProviderBranches;