/** @format */

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import ProfileForm from "./ProfileForm";
import ProviderBranches from "./ProviderBranches";

const ProfileContent = () => {
  const [activeToggle, setActiveToggle] = useState("aboutMe");


  return (
    <Fragment>
      ´
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("aboutMe")}
                    >
                      <Link
                        to="#about-me"
                        data-toggle="tab"
                        className={`nav-link  ${
                          activeToggle === "aboutMe" ? "active show text-pigment-green" : ""
                        } text-sm`}
                      >
                        Applied Learning Cluster Information
                      </Link>
                    </li>
                    <li className="nav-item"
                      onClick={() => setActiveToggle("branches")}>
                    <Link
                        to="#branches"
                        data-toggle="tab"
                        className={`nav-link  ${
                          activeToggle === "branches" ? "active show text-pigment-green" : ""
                        } text-sm`}
                      >
                        Branches
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="about-me"
                      className={`tab-pane fade ${
                        activeToggle === "aboutMe" ? "active show" : ""
                      }`}
                    >
                      <div className="profile-about-me">
                        <div className="pt-4 border-bottom-1 pb-3"></div>
                      </div>
                      <ProfileForm />
                    </div>
                    <div 
                      id="branches"
                      className={`tab-pane fade ${
                        activeToggle === "branches" ? "active show" : ""
                      }`}
                    >
                      <ProviderBranches />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileContent;
