/** @format */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { Path } from "utils/constant.data";
import { validStatuses } from "utils/strings";


const useAllowedFellow = () => {
const navigate = useNavigate();
  const { profileInfo } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;

  useEffect(() => {
    if (profile) {

      const isProfileValid = validStatuses.includes(profile?.status?.toLowerCase()) && profile?.consent_accepted;

      if (!isProfileValid) {
        navigate(Path.FELLOW_DASHBOARD);
      }
    }
  }, [navigate, profile]);
};

export default useAllowedFellow;
