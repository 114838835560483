/** @format */

import React from "react";
import { CourseInterest } from "utils/constant.data";

const QuestionsTwo = ({ formik, setStep }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="form-group mb-3">
          <label className="mb-1">
            <strong>Course</strong>
          </label>

          <select
            id="current_learning_course"
            name="current_learning_course"
            className="form-control"
            {...formik.getFieldProps("current_learning_coursel")}
          >
            {/* <option value="">Select Course</option> */}
            {CourseInterest.map((_course) => {
              return <option value={_course}>{_course}</option>;
            })}
          </select>

          {formik.touched.current_learning_course &&
          formik.errors.current_learning_course ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.current_learning_course}
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="mb-3">
          <label className="mb-1">
            <strong>Name Of Organisation</strong>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Name"
            disabled={true}
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger mt-2 fs-12">{formik.errors.name}</div>
          ) : null}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="mb-3">
          <label className="mb-1">
            <strong> Provide prior track record of your organisation</strong>
          </label>

          <textarea
            className="w-100 form-control"
            placeholder="Your answer"
            id="track_record_of_previous_training"
            name="track_record_of_previous_training"
            {...formik.getFieldProps("track_record_of_previous_training")}
            rows="3"
          />

          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger mt-2 fs-12">{formik.errors.name}</div>
          ) : null}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group mb-3 d-flex justify-content-between">
          <button
            type="button"
            onClick={() => setStep(1)}
            className="submit btn  btn-pigment-green-outline"
          >
            Previous
          </button>
          <button
            type="button"
            onClick={() => setStep(3)}
            className="submit btn btn-pigment-green"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionsTwo;
