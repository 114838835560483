/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";

import { GenderOptions, stateOptions } from "utils/strings";

import { useGetPublicCohortsQuery, useGetAllCoursesPublicQuery } from "store/services/adminAPI";

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  restoreFellows,
  clearFilter,
}) => {
  const [selectedGender, setGender] = useState([]);
  const [selectedState, setState] = useState([]);
  const [selectedCourse, setCourse] = useState([]);
  const [selectedCohort, setCohort] =  useState(null)



  const {
    data:cohorts,
  } = useGetPublicCohortsQuery("");

  const {data:courses} = useGetAllCoursesPublicQuery()



  const CohortOptions =
  cohorts?.data?.cohorts?.length > 0
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort?.id,
        label: `Cohort ${cohort?.rank}`,
      }))
    : [];

    const CoursesOptions =
    courses?.data?.courses?.length > 0
      ? courses?.data?.courses?.map((course) => ({
          value: course?.id,
          label: course?.title,
        }))
      : [];

  useEffect(() => {
    setGender([]);
    setState([]);
    setCourse([]);
    setCohort([]);
  }, [clearFilter]);



  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    let gender = 0;

    if (typeof selectedGender === "object" && !Array.isArray(selectedGender)) {
      gender = selectedGender?.value?.length;
    } else {
      gender = selectedGender.length;
    }

    if (
      selectedState.length === 0 &&
      selectedCourse.length === 0 &&
      selectedCohort.length === 0 &&
      gender === 0
    ) {
      toast.warn("✔️ Please select a valid filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        course: selectedCourse.length > 0 ? selectedCourse : [],
        gender: selectedGender?.value ? selectedGender?.value : "",
        residence: selectedState.length > 0 ? selectedState : [],
        cohort_id: selectedCohort.value ? selectedCohort?.value : ""
      });

    }

  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Selected Fellows</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <Col md={12}>
                  <p className="p-1 m-0">Filter by State</p>
                  <div className="form-group mb-3">
                    <Select
                      components={{ ClearIndicator }}
                      value={selectedState}
                      onChange={setState}
                      options={stateOptions}
                      isMulti
                      styles={Style}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Filter by Gender</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedGender}
                      onChange={setGender}
                      options={GenderOptions}
                      styles={Style}
                    />
                  </div>
                </Col>

                {
                  CoursesOptions?.length > 0 && (
                    <Col md={12}>
                    <p className="p-1 m-0">Filter by course</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCourse}
                        onChange={setCourse}
                        options={CoursesOptions}
                        isMulti
                        styles={Style}
                      />
                    </div>
                  </Col>
                  )
                }
               

                {CohortOptions?.length > 0 && (
                  <Col md={12}>
                    <p className="p-1 m-0">Filter by Cohort</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCohort}
                        onChange={setCohort}
                        options={CohortOptions}
                        styles={Style}
                      />
                    </div>
                  </Col>
                )}
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setGender([]);
                  setState([]);
                  setCourse([]);
                  setCohort([]);
                  restoreFellows();
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Fellows"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
