/** @format */

import Metismenu from "metismenujs";
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "context/ThemeContext";
import { useSelector } from "react-redux";
import { getProviderProfile } from "store/actions/profile";
import { useDispatch } from "react-redux";
// import MMLogo from "images/3MTT/fg.png";
import { FadeLoader } from "react-spinners";
import { Path } from "utils/constant.data";

import {
  mapStatusHeading,
  mapStatusInformation,
  mapStatus,
} from "utils/strings";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const profileState = useSelector((state) => state.profile);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderProfile()).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  // deshBoard = ["dashboard"],
  // community = ["community"]
  let profile = ["profile"];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {/* <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.COMMUNITY_DASHBOARD}>
              <i className="bi bi-grid"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li> */}

          {/* <li className={`${community.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.COMMUNITY_PAGE}>
              {" "}
              <i className="bi bi-people"></i>{" "}
              <span className="nav-text">Community</span>
            </Link>
          </li> */}

          <li className={`${profile.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.COMMUNITY_PROFILE}>
              <i className="bi bi-person"></i>
              <span className="nav-text">Profile</span>
            </Link>
          </li>
        </MM>

        {profileState?.isLoading ? (
          <div className="d-flex justify-content-center my-4">
            <FadeLoader color="#26a65b" />
          </div>
        ) : (
          <Fragment>
            <div className="plus-box">
              <div className="d-flex align-items-center">
                <h5>Your 3MTT Status</h5>
              </div>
              <p>
                {profileState?.profileInfo?.data?.profile?.status &&
                  mapStatusInformation(
                    profileState?.profileInfo?.data?.profile?.status
                  )}
              </p>

              {profileState?.profileInfo?.data?.profile?.status && (
                <div
                  to={"#"}
                  className={`btn ${mapStatus(
                    profileState?.profileInfo?.data?.profile?.status
                  )}  btn-sm `}
                >
                  {profileState?.profileInfo?.data?.profile?.status &&
                    mapStatusHeading(
                      profileState?.profileInfo?.data?.profile?.status
                    )}
                </div>
              )}
            </div>
          </Fragment>
        )}

        {/* <Link to={Path.COMMUNITY_PAGE} className="ministry-logo">
          <figure>
            <img src={MMLogo} alt="" />
          </figure>
        </Link> */}

        <div className="copyright">
          <p>
            <b>FMoCIDE</b> © {new Date().getFullYear()} All Rights Reserved
          </p>
          <p className="fs-12 text-center">
            <span className="heart" style={{ display: "none" }}></span>
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
