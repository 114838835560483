/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { countWords } from "utils/strings";
import { level_seven } from "utils/constant.data";

const ProfileLevelSeven = ({ profile, formik, validateFormStep, setStep, updating, handleSubmit }) => {
  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-1">
          <strong>
            {" "}
            Describe the monitoring and evaluation framework your organisation
            employs to assess the effectiveness and impact of the training
            programs. Provide examples of how feedback from monitoring and
            evaluation has been used to improve past training programs. (Max 500
            words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.monitoring_and_evaluation : ""}
          id="monitoring_and_evaluation"
          name="monitoring_and_evaluation"
          {...formik.getFieldProps("monitoring_and_evaluation")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.monitoring_and_evaluation ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.monitoring_and_evaluation}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.monitoring_and_evaluation
              ? `${countWords(formik?.values.monitoring_and_evaluation)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe your organisation's approach for assessing the skill
            development benchmarks of trainings that go through your programmes.
            This will include how you assess the growth of trainees that are
            accepted into your programmes. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.approach_to_assessing_skill : ""}
          id="approach_to_assessing_skill"
          name="approach_to_assessing_skill"
          {...formik.getFieldProps("approach_to_assessing_skill")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.approach_to_assessing_skill ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.approach_to_assessing_skill}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.approach_to_assessing_skill
              ? `${countWords(formik?.values.approach_to_assessing_skill)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe the tools and methods used for collecting and analysing
            feedback from trainees and stakeholders (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.tools_and_method : ""}
          id="tools_and_method"
          name="tools_and_method"
          {...formik.getFieldProps("tools_and_method")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.tools_and_method ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.tools_and_method}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.tools_and_method
              ? `${countWords(formik?.values.tools_and_method)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          className="ms-2 me-2 py-3 btn-pigment-green-outline "
          disabled={updating}
          style={{ width: "100% !important" }}
          type="button"
          onClick={() => {
            setStep(6);
          }}
        >
          Previous
        </Button>


        <Button
          type="submit"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
          onClick={() => {
            const L7_ERROR = validateFormStep(level_seven);
            if (L7_ERROR) {
              return
            }
            handleSubmit(formik.values, level_seven, 7)
            }
          }
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelSeven;
