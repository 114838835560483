import {useState } from "react";
import { capitalize } from "utils/strings";

import FellowProfessionalDataForm from "./FellowProfessionalDataForm";

const ProfessionalData = ({ data }) => {
    const [editMode, setEditMode] = useState(false);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const renderField = (label, value) => {
        return (
            <div className="row mb-2">
                <div className="col-3">
                    <h5 className="f-w-500">
                        {label}
                        <span className="pull-right">:</span>
                    </h5>
                </div>
                <div className="col-9">
                    <span>{value || "Not provided"}</span>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="profile-personal-info pt-4">
                <div className="mb-4 mt-3">
                    <button className="btn btn-primary small col-" onClick={toggleEditMode}>
                        {editMode ? 'Discard Changes' : 'Update Data'}
                    </button>
                </div>
                {editMode ? (
                    <FellowProfessionalDataForm
                        currentProfile={data}
                        toggleEditMode={toggleEditMode}
                    />
                ) : (
                    <>
                        {renderField("Field of Study", data?.field_of_study)}
                        {renderField("Graduation Year", data?.graduation_year)}
                        {renderField("Graduation Class", capitalize(data?.graduation_class))}
                        {renderField("NYSC Completion Year", data?.nysc_completion_year)}
                        {renderField("NYSC Certificate", data?.nysc_certificate)}
                        {renderField("CV", <a href={data?.cv} target="_blank" rel="noopener noreferrer">{data?.cv || "Not provided"}</a>)}
                        {renderField("LinkedIn", <a href={data?.linkedin_url} target="_blank" rel="noopener noreferrer">{data?.linkedin_url || "Not provided"}</a>)}
                        {renderField("English Proficiency Level", capitalize(data?.english_proficiency))}
                        {renderField("Other Languages", capitalize(data?.other_languages))}
                    </>
                )}
            </div>
        </div>
    );
};

export default ProfessionalData;
