/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { level_two } from "utils/constant.data";
import { No_of_staffs } from "utils/constant.data";
import { countWords } from "utils/strings";

const ProfileLevelTwo = ({ profile, formik, validateFormStep, setStep, updating, handleSubmit }) => {
  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-1">
          <strong>
            How long has your company been delivering training programs? (How
            many years has your organisation been actively involved in
            delivering training programs?).
          </strong>
        </label>
        <select
          defaultValue={profile ? profile?.training_period : ""}
          id="training_period"
          name="training_period"
          className="form-control"
          {...formik.getFieldProps("training_period")}
        >
          <option value="">Select</option>
          <option value="1 Year">1 Year</option>
          <option value="2 Years">2 Years</option>
          <option value="3 Years">3 Years</option>
          <option value="4 Years">4 Years</option>
          <option value="5 Years">5 Years+</option>
        </select>

        {formik.errors.training_period ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.training_period}
          </div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>Total number of staff in your organisation</strong>
        </label>
        <select
          defaultValue={profile ? profile?.staff_count : ""}
          id="staff_count"
          name="staff_count"
          className="form-control"
          {...formik.getFieldProps("staff_count")}
        >
          <option value="">Choose </option>
          {No_of_staffs?.map((val, i) => (
            <option value={val}>{val} </option>
          ))}
        </select>
        {formik.errors.staff_count ? (
          <div className="text-danger mt-2 fs-12">
            {formik.errors.staff_count}
          </div>
        ) : null}
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            List up to three major training programs your organization has
            successfully executed in the past two years, including the number of
            trainees and the outcomes achieved. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.track_record_of_previous_training : ""}
          id="track_record_of_previous_training"
          name="track_record_of_previous_training"
          {...formik.getFieldProps("track_record_of_previous_training")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.track_record_of_previous_training ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.track_record_of_previous_training}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.track_record_of_previous_training
              ? `${countWords(
                  formik?.values.track_record_of_previous_training
                )}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Please provide any accreditations, certifications, or awards your
            organization has received in relation to training delivery. (Max 500
            words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.accreditation_certification : ""}
          id="accreditation_certification"
          name="accreditation_certification"
          {...formik.getFieldProps("accreditation_certification")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.accreditation_certification ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.accreditation_certification}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.accreditation_certification
              ? `${countWords(formik?.values.accreditation_certification)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          className="ms-2 me-2 py-3 btn-pigment-green-outline "
          disabled={updating}
          style={{ width: "100% !important" }}
          type="button"
          onClick={() => {
            setStep(1);
          }}
        >
          Previous
        </Button>

        <Button
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
          onClick={() => {
            const L2_ERROR = validateFormStep(level_two);
            if (L2_ERROR) {
              return
            }
            handleSubmit(formik.values, level_two, 2)
            }
          }
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Save"
          )}
        </Button>
        <Button
          onClick={() => {
            const L2_ERROR = validateFormStep(level_two);
            if (!L2_ERROR) {
              setStep(3);
            }
          }}
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelTwo;
