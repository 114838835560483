import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { extractErrorMessage, GraduationClassOptions, replaceUnderscoresWithSpaces } from "utils/strings";

import { useUpdateFellowProfileMutation } from "store/services/adminAPI";

import {
  EnglishProficiencyLevelOptions,
} from "utils/strings";

const FellowProfessionalDataForm = ({currentProfile, toggleEditMode}) => {

    const [updateFellowProfile, { isLoading: updating }] =
    useUpdateFellowProfileMutation();


    const handleSubmit = async (values) => {
        const initialValues = formik.initialValues;
        const editedFields = {};

        Object.keys(values).forEach((fieldName) => {
            if (values[fieldName] !== initialValues[fieldName]) {
                editedFields[fieldName] = values[fieldName];
            }
        });

        await updateFellowProfile(editedFields)
        .unwrap()
        .then((data)=>{
            toast.success(`✔️ Profile updated !`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            toggleEditMode();
        })
        .catch((error) => {
            const formattedError = extractErrorMessage(error)
            Swal.fire({
              title:
                replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
              text: formattedError,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
        
    };

    const formik = useFormik({
        initialValues : {
            field_of_study: currentProfile.field_of_study !== null ? currentProfile.field_of_study : "",
            graduation_year: currentProfile.graduation_year !== null ? currentProfile.graduation_year : "",
            graduation_class: currentProfile.graduation_class !== null ? currentProfile.graduation_class : "",
            nysc_completion_year: currentProfile.nysc_completion_year !== null ? currentProfile.nysc_completion_year : "",
            nysc_certificate: currentProfile.nysc_certificate !== null ? currentProfile.nysc_certificate : "",
            cv: currentProfile.cv !== null ? currentProfile.cv : "",
            other_languages: currentProfile.other_languages !== null ? currentProfile.other_languages : "",
            english_proficiency: currentProfile.english_proficiency !== null ? currentProfile.english_proficiency : "",
            linkedin_url: currentProfile.linkedin_url !== null ? currentProfile.linkedin_url : "",
        },
        onSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: FormSchema,
    })

    return (
        <div className="basic-form">
            <form onSubmit={formik.handleSubmit}>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Field of Study</strong></label>
                        <input
                            id="field_of_study"
                            name="field_of_study"
                            value={formik.values.field_of_study}
                            placeholder="Field of Study"
                            className="form-control"
                            {...formik.getFieldProps("field_of_study")}
                        />
                        {formik.errors.field_of_study ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.field_of_study}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Graduation Class</strong></label>
                        <select
                            id="graduation_class"
                            name="graduation_class"
                            value={formik.values.graduation_class}
                            className="form-control"
                            onChange={(e) => {
                                const selectedEnglishProficiency = e.target.value;
                                formik.setFieldValue("graduation_class", selectedEnglishProficiency);
                            }}
                            {...formik.getFieldProps("graduation_class")}
                        >
                            {GraduationClassOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        {formik.errors.graduation_class ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.graduation_class}</div>
                        ) : null}
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Graduation Year</strong></label>
                        <input
                            id="graduation_year"
                            name="graduation_year"
                            type="text"
                            value={formik.values.graduation_year}
                            placeholder="Graduation Year (YYYY)"
                            className="form-control"
                            {...formik.getFieldProps("graduation_year")}
                        />
                        {formik.errors.graduation_year ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.graduation_year}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>NYSC Completion Year</strong></label>
                        <input
                            id="nysc_completion_year"
                            name="nysc_completion_year"
                            type="text"
                            value={formik.values.nysc_completion_year}
                            placeholder="NYSC Completion Year (YYYY)"
                            className="form-control"
                            {...formik.getFieldProps("nysc_completion_year")}
                        />
                        {formik.errors.nysc_completion_year ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.nysc_completion_year}</div>
                        ) : null}
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Please share the URL to your NYSC certificate</strong></label>
                        <input
                            id="nysc_certificate"
                            name="nysc_certificate"
                            value={formik.values.nysc_certificate}
                            placeholder="Please share the URL to your NYSC certificate"
                            className="form-control"
                            {...formik.getFieldProps("nysc_certificate")}
                        />
                        {formik.errors.nysc_certificate ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.nysc_certificate}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Please share the URL to your CV</strong></label>
                        <input
                            id="cv"
                            name="cv"
                            value={formik.values.cv}
                            placeholder="Please share the URL to your CV"
                            className="form-control"
                            {...formik.getFieldProps("cv")}
                        />
                        {formik.errors.cv ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.cv}</div>
                        ) : null}
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Please share the url to your LinkedIn profile.</strong></label>
                        <input
                            id="linkedin_url"
                            name="linkedin_url"
                            value={formik.values.linkedin_url}
                            placeholder="Please share the url to your LinkedIn profile."
                            className="form-control"
                            {...formik.getFieldProps("linkedin_url")}
                        />
                        {formik.errors.linkedin_url ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.linkedin_url}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Other Languages</strong></label>
                        <input
                            id="other_languages"
                            name="other_languages"
                            value={formik.values.other_languages}
                            placeholder="Enter a list of all the other languages you speak if any"
                            className="form-control"
                            {...formik.getFieldProps("other_languages")}
                        />
                        {formik.errors.other_languages ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.other_languages}</div>
                        ) : null}
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>English Proficiency</strong></label>
                        <select
                            id="english_proficiency"
                            name="english_proficiency"
                            value={formik.values.english_proficiency}
                            className="form-control"
                            onChange={(e) => {
                                const selectedEnglishProficiency = e.target.value;
                                formik.setFieldValue("english_proficiency", selectedEnglishProficiency);
                            }}
                            {...formik.getFieldProps("english_proficiency")}
                        >
                            {EnglishProficiencyLevelOptions.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        {formik.errors.english_proficiency ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.english_proficiency}</div>
                        ) : null}
                    </div>
                </div>
                <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                    disabled={updating || !formik.dirty}
                  >
                    {updating ? (
                      <span
                        role="button"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      "Update"
                    )}
                  </button>
            </form>
        </div>
    );
}

export default FellowProfessionalDataForm;

const FormSchema = Yup.object().shape({
    field_of_study: Yup.string()
        .min(5, 'Field of study must be at least 5 characters long')
        .nullable(),
    graduation_class: Yup.string().required("Graduation class is required"),
    nysc_certificate: Yup.string().url('Enter a valid URL'),
    cv: Yup.string().url('Enter a valid URL').required("CV is required"),
    other_languages: Yup.string(),
    english_proficiency: Yup.string().required("Please select your English proficiency level"),
    linkedin_url: Yup.string().url('Enter a valid URL').required("Your LinkedIn profile is required"),
    graduation_year: Yup.string()
        .matches(/^\d{4}$/, 'Graduation year must be exactly 4 numbers')
        .nullable(),
    nysc_completion_year: Yup.string()
        .matches(/^\d{4}$/, 'NYSC completion year must be exactly 4 numbers')
        .nullable(),
});