/** @format */

import React, { Fragment } from "react";
import { Tab } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
import { Accordion } from "react-bootstrap";
import { capitalize } from "utils/strings";

const JobsTab = ({ jobs }) => {
  return (
    <Fragment>
      <Tab.Content className="pt-4">
        <p>All Jobs</p>
        <p>
          You can now explore job opportunities on the 3MTT job boards! These job boards are accessible to fellows who have completed both online courses and ALC training. Visit the links to stay updated with new job postings regularly.
        </p>

        <div className="custome-accordion">
          <Accordion id="accordionExample" defaultActiveKey="0">
            {jobs?.filter((_) => _?.status && _?.type === "jobs")?.length >
            0 ? (
              jobs
                .filter((_) => _?.status && _?.type === "jobs")
                .map((item) => (
                  <div className="card-schedule" key={item?.id}>
                    <span
                      className={`side-label ${item?.cardClass}  bg-primary `}
                    ></span>
                    <div className="up-comming-schedule">
                      <div>
                        <h4>{capitalize(item.name)}</h4>
                        <div className="mb-sm-0 mb-2">
                          <img src={item.image} className="me-2" alt="" />
                          {/* <span>Ms. Samantha William</span> */}
                        </div>
                      </div>
                      <div>
                        <span>{capitalize(item?.description)}</span>
                      </div>
                      <div>{/* <span>07.00 - 08.00 AM</span> */}</div>
                      <div>
                        <a
                          href={item?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="text-primary mr-2">View Job</span>
                          <i className="las la-angle-right text-secondary"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="bg-white py-5">
                <EmptyNotice text="No job at the moment" />
              </div>
            )}
          </Accordion>
        </div>
      </Tab.Content>
    </Fragment>
  );
};

export default JobsTab;
