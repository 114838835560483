/**
 * eslint-disable no-lone-blocks
 *
 * @format
 */

/** @format */
import { useGetWebinarsQuery } from "store/services/adminAPI";
import React from "react";
import { FadeLoader } from "react-spinners";
import { Col, Card } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
// import { ReactComponent as Up } from "images/svg/up.svg";
import course1 from "images/courses/cohort2.jpg";
// import career from "images/courses/career.jpg";
import { Link } from "react-router-dom";

const Webinars = () => {
  const { data, isLoading } = useGetWebinarsQuery();

  // const image = {
  //   dd: course1,
  //   ddd: career,
  // };

  return (
    <Col xl="12">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Card>
          <Card.Header className="d-block">
            <Card.Title>Community Webinars</Card.Title>
            <Card.Text className="m-0 subtitle">
              Check regularly for webinars
            </Card.Text>
          </Card.Header>

          {data?.data?.results.length > 0 ? (
            <Card.Body className="pt-0 community-card cscrollable">
              <div className="row">
                {data?.data?.results.map((webinar) => {
                  return (
                    webinar?.episodes?.length > 0 &&
                    webinar?.episodes?.map((episode) => {
                      return (
                        <div className="col-xl-4 col-md-6" key={episode?.id}>
                          <div className="card all-crs-wid">
                            <div className="card-body">
                              <div className="courses-bx">
                                <div className="dlab-media">
                                  <img
                                    src={
                                      webinar?.thumbnail ? webinar?.thumbnail : course1
                                    }
                                    alt="course"
                                    className="contain-img"
                                  />
                                </div>
                                <div className="dlab-info">
                                  <div className="dlab-title d-flex justify-content-between">
                                    <div>
                                      <h4>
                                        <Link
                                          to={episode?.link}
                                          target="_blank"
                                        >
                                          {episode?.title}
                                        </Link>
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between content align-items-center">
                                    <span></span>
                                    <Link
                                      to={episode?.link}
                                      target="_blank"
                                      className="btn btn-primary btn-sm"
                                    >
                                      View
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  );
                })}
              </div>
            </Card.Body>
          ) : (
            <Card.Body>
              <EmptyNotice text="No webinar at the moment" />
            </Card.Body>
          )}
        </Card>
      )}
    </Col>
  );
};

export default Webinars;

