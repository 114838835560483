/** @format */
import { useGetFellowCourseModulesQuery } from "store/services/adminAPI";
import React from "react";
import { FadeLoader } from "react-spinners";
import { Col, Card } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
import { Link } from "react-router-dom";
import course1 from "images/courses/course1.jpg";
import FilterModal from "./FilterModal";
import { useState } from "react";
import { extractFilterValues } from "utils/functions";
import { useEffect } from "react";

const CommunityResources = () => {
  const [skip, setSkip] = useState(false);
  const [filter, setFilter] = useState(`community&page=1`);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const {
    data: courses,
    isLoading: fetchingCourses,
    isFetching: filtering,
  } = useGetFellowCourseModulesQuery(filter, {
    skip: skip,
  });

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);

    const filterString = `?fellow_type=community&page=${_page}${
      values?.skills?.length > 0 ? `&skills=${values?.skills?.join(",")}` : ""
    }${
      values?.level_of_difficulty?.length > 0
        ? `&level_of_difficulty=${values?.level_of_difficulty?.join(",")}`
        : ""
    }${values?.duration?.length > 0 ? `&duration=${values?.duration}` : ""}`;
    setFilter(filterString);
    setFiltered(true);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  return (
    <Col xl="12">
      {fetchingCourses || filtering ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex flex-column">
                <Card.Title>Community Resources</Card.Title>
                <Card.Text className="m-0 subtitle">
                  Check regularly for community resources
                </Card.Text>
              </div>

              <div className="d-flex">
                {filtered && (
                  <button
                    onClick={() => {
                      setFiltered(false);
                      setClear(!clear);
                      setFilter(`community&page=1`);
                      setModalFilter(null);
                    }}
                    className="btn text-danger"
                  >
                    Reset Filter
                  </button>
                )}

                <button
                  className="btn my-2 btn-primary px-4"
                  onClick={() => setShowFilter(true)}
                >
                  Filter
                </button>
              </div>
            </div>
          </Card.Header>

          {courses?.data &&
            (courses?.data?.length > 0 ? (
              <Card.Body className="pt-0 community-card  cscrollable">
                <div className="row">
                  {[...courses?.data]
                    ?.sort(
                      (a, b) =>
                        new Date(b?.created_at) - new Date(a?.created_at)
                    )
                    ?.map((list) => (
                      <div className="col-xl-4 col-md-6" key={list?.id}>
                        <div className="card all-crs-wid">
                          <div className="card-body">
                            <div className="courses-bx">
                              <div className="dlab-media">
                                <img
                                  src={list?.image ? list?.image : course1}
                                  alt=""
                                  className="contain-img"
                                />
                              </div>
                              <div className="dlab-info">
                                <div className="dlab-title d-flex justify-content-between">
                                  <div>
                                    <h4>
                                      <Link to={list?.link} target="_blank">
                                        {list?.name}
                                      </Link>
                                    </h4>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between content align-items-center">
                                  <span></span>
                                  <Link
                                    to={list?.link}
                                    target="_blank"
                                    className="btn btn-primary btn-sm"
                                  >
                                    View
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Card.Body>
            ) : (
              <Card.Body>
                <EmptyNotice text="No module at the moment" />
              </Card.Body>
            ))}

          <FilterModal
            setFilter={setModalFilter}
            setShowFilter={setShowFilter}
            showModal={showFilter}
            filtering={filtering}
            clearFilter={clear}
          />
        </Card>
      )}
    </Col>
  );
};

export default CommunityResources;
