/** @format */

import CryptoJS from "crypto-js";

export const encryptionKey = process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY;

export const encrypt = (value) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(value, encryptionKey).toString();
    return encrypted;
  } catch (error) {
    return "";
  }
};

export const decrypt = (value) => {
  try {
    const bytes = CryptoJS.AES.decrypt(value, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
};

export const getLocalStorage = (key) => {
    try {
      if (typeof window !== 'undefined') {
        const data = window.localStorage.getItem(key) || '';
        return data && JSON.parse(decrypt(data));
      }
    } catch (error) {
      if (error.message === 'Unexpected end of JSON input') {
        return window.location.origin;
      }
      return null;
    }
  };

export const GenerateId = () => {
  // generates uuid for booking when routing to payent page
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
