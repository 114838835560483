/** @format */

// import { useGetFellowAppliedModulesQuery } from "store/services/adminAPI";
import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";

import { FadeLoader } from "react-spinners";
import { Tab } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
// import Swal from "sweetalert2";
import {
  // useSubmitFellowAssesmentMutation,
  useGetFellowCourseModuleQuery,
} from "store/services/providerAPI";
// import { capitalize, replaceUnderscoresWithSpaces } from "utils/strings";
import CardModule from "views/providers/pages/Courses/components/CardModule";
import { profileSelector } from "store/reducers/ProfileReducer";
import { useSelector } from "react-redux";



const FellowAppliedContent = () => {
  // eslint-disable-next-line no-unused-vars
  // const { data: courses, isLoading: fetchingCourses } =
  //   useGetFellowAppliedModulesQuery();
  const { data: courses, isLoading: fetchingCourses } =
    useGetFellowCourseModuleQuery();
  // const [submitFellowAssesment, { isLoading: isSubmitting }] =
  //   useSubmitFellowAssesmentMutation();

  // const handleSubmit = async (id) => {
  //   const payload = {
  //     assesment_id: id,
  //     status: "submitted",
  //   };
  //   await submitFellowAssesment(payload)
  //     .unwrap()
  //     .then((data) => {})
  //     .catch((error) => {
  //       Swal.fire({
  //         title: error?.data?.code
  //           ? replaceUnderscoresWithSpaces(error?.data?.code)
  //           : "Error Occured",
  //         text: error?.data?.detail,
  //         iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
  //         showCloseButton: true,
  //         customClass: {
  //           icon: "no-border",
  //         },
  //         confirmButtonText: "Try Again",
  //       });
  //     });
  // };
  const { profileInfo, isLoading:gettingProfile } = useSelector(profileSelector);

  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;

  return (
    <Fragment>
      {fetchingCourses ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Tab.Content className="pt-4">
          {!gettingProfile && profile && profile?.cohort?.rank === 2 && (
            <p>
              Your weekly Course Modules contain everything you need for your learning, including the assigned online course module and assignments/tasks. Be sure to check them regularly and complete all tasks as required.
            </p>
          )}

          <Row className="row be">
            <Col xl="12" className="course-modules">
              <Card className="pb-5">
                <Card.Header className="d-flex justify-content-between align-items-center mb-5">
                  <Card.Title>Course Modules</Card.Title>
                </Card.Header>

                {courses && courses?.data?.length > 0 ? (
                  <div className="row">
                    {courses.data.map((course) => {
                      return (
                        <CardModule data={course} key={course?.id} />
                        // <Card.Body className="fellow-module">
                        //   <Accordion
                        //     className="accordion accordion-active-header"
                        //     defaultActiveKey={course.id}
                        //     key={course.id}
                        //   >
                        //     <Accordion.Item eventKey="7">
                        //       <Accordion.Header
                        //         className={`accordion-header  accordion-header-primary`}
                        //       >
                        //         <div className="d-flex flex-column">
                        //           <h4> {course?.name} </h4>
                        //         </div>
                        //       </Accordion.Header>
                        //       <Accordion.Collapse
                        //         eventKey="7"
                        //         className="accordion__body"
                        //       >
                        //         <div className="accordion-body">
                        //           {course?.assessments?.length > 0 && (
                        //             <div className="d-flex align-items-center justify-content-between course-lesson cta-btns"></div>
                        //           )}

                        //           {course?.assessments?.length > 0 ? (
                        //             course?.assessments?.map((assesment) => {
                        //               return (
                        //                 <div className="d-flex align-items-center justify-content-between course-lesson">
                        //                   <div className=" d-flex flex-column">
                        //                     <h5> {assesment?.title} </h5>
                        //                     <p>Week {assesment?.week_number}</p>
                        //                   </div>

                        //                   <div className="d-flex align-items-center justify-content-between course-lesson cta-btns">
                        //                     {assesment?.status ===
                        //                       "disapproved" ||
                        //                     assesment?.status === "pending" ? (
                        //                       <button
                        //                         className="btn btn-outline mr-2"
                        //                         disabled={
                        //                           isSubmitting ? true : false
                        //                         }
                        //                         onClick={() =>
                        //                           handleSubmit(assesment.id)
                        //                         }
                        //                       >
                        //                         {isSubmitting ? (
                        //                           <span
                        //                             role="status"
                        //                             aria-hidden="true"
                        //                             className="spinner-border spinner-border-sm ms-2 text-primary"
                        //                           ></span>
                        //                         ) : (
                        //                           <>
                        //                             <i
                        //                               class="fa fa-pencil text-primary"
                        //                               aria-hidden="true"
                        //                             ></i>
                        //                             Mark as submitted
                        //                           </>
                        //                         )}
                        //                       </button>
                        //                     ) : (
                        //                       assesment?.status && (
                        //                         <button
                        //                           className={`btn ${
                        //                             assesment?.status ===
                        //                             "approved"
                        //                               ? "btn-pigment-green"
                        //                               : "btn-poly-green"
                        //                           }  `}
                        //                           disabled={true}
                        //                         >
                        //                           {capitalize(assesment?.status)}
                        //                         </button>
                        //                       )
                        //                     )}
                        //                   </div>
                        //                 </div>
                        //               );
                        //             })
                        //           ) : (
                        //             <div className="bg-white py-5">
                        //               <EmptyNotice text="No assesmnet for this module at the moment" />
                        //             </div>
                        //           )}
                        //         </div>
                        //       </Accordion.Collapse>
                        //     </Accordion.Item>
                        //   </Accordion>
                        // </Card.Body>
                      );
                    })}
                  </div>
                ) : (
                  <div className="bg-white py-5">
                    <EmptyNotice text="No module at the moment" />
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Tab.Content>
      )}
    </Fragment>
  );
};

export default FellowAppliedContent;
