/** @format */

// export const extractFilterValues = (filter) => {
//   const res = {};
//   for (const key of Object.keys(filter)) {
//     if (key === "gender" || key === "page" ||  key === "cohort_id" || key="duration" ) {
//       res[key] = filter[key];
//     } else {
//       const values = filter[key].map((value) => value?.value);
//       res[key] = values;
//     }
//   }

//   return res;
// };


export const extractFilterValues = (filter) => {
  const res = {};
  for (const [key, value] of Object.entries(filter)) {
    if (["gender", "page", "cohort_id", "duration"].includes(key)) {
      res[key] = value;
    } else {
      res[key] = value.map((item) => item?.value);
    }
  }
  return res;
};
