/** @format */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { Path } from "utils/constant.data";


const useCommunity = () => {
const navigate = useNavigate();
  const { profileInfo } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;

  useEffect(() => {
    if (profile) {

      const isProfileValid = ['application_completed'].includes(profile?.status?.toLowerCase());

      if (!isProfileValid) {
        navigate(Path.FELLOW_DASHBOARD);
      }
    }
  }, [navigate, profile]);
};

export default useCommunity;
