/** @format */

import dayjs from "dayjs";
import "dayjs/locale/en";

export const formatDate = (rawDate, format = "ddd, D MMMM, HH:mm") => {
  if (rawDate) {
    const parsedDate = dayjs(rawDate);

    parsedDate.locale("en");

    const formattedDate = parsedDate.format(format);

    return formattedDate;
  } else {
    return "";
  }
};

export const formatDateWithoutTime = (rawDate) => {
  if (rawDate) {
    const parsedDate = dayjs(rawDate);

    parsedDate.locale("en");

    const formattedDate = parsedDate.format("ddd, D MMMM, YYYY");

    return formattedDate;
  } else {
    return "";
  }
};

export const checkDateValidity = (rawDate) => {
  const [day, month, year] = rawDate.split('/').map(Number);
  const inputDate = new Date(year, month - 1, day);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return today < inputDate;
}