/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { Fragment, useEffect } from "react";
import Educat from "images/education-girl.png";
import Calpng from "images/vector/calpng.png";
import Book from "images/vector/book.png";
import CommunityLayout from "../../Community";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
// import CourseBlog from "../../components/Dashboard/Dashboard/CourseBlog";
import { getProviderProfile } from "store/actions/profile";
import { profileSelector } from "store/reducers/ProfileReducer";

const CommunityHome = () => {
  const dispatch = useDispatch();
  const { profileInfo, isLoading } = useSelector(profileSelector);

  useEffect(() => {
    getProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getProfileInfo = () => {
    dispatch(getProviderProfile());
  };

  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;

  return (
    <CommunityLayout>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Fragment>
          <div className="row">
            <div className="col-xl-6 col-xxl-12">
              <div className="row">
                <div className="col-xl-12 col-xxl-12">
                  <div className="card dlab-join-card h-auto">
                    <div className="card-body">
                      <div className="dlab-media d-flex justify-content-between">
                        <div className="dlab-content">
                          <h4>
                            Hey {profile?.first_name}, Welcome to your 3MTT
                            Dashboard
                          </h4>
                        </div>
                        <div className="dlab-img">
                          <img src={Educat} alt="" />
                        </div>
                        <div className="dlab-icon">
                          <img src={Calpng} alt="" className="cal-img" />
                          <img src={Book} alt="" className="book-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-xl-12 bt-order">
                  <CourseBlog />
                </div> */}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </CommunityLayout>
  );
};

export default CommunityHome;
