/** @format */

import React from "react";
import avatar from "images/3MTT/user-avatar.png";
import { capitalize } from "utils/strings";

const ProfileHeader = ({ profileInfo }) => {
  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="profile card card-body px-3 pt-3 pb-0">
          <div className="profile-head">
            <div className="photo-content ">
            </div>
            <div className="profile-info">
              <div className="profile-photo">
                <img
                  src={avatar}
                  className="img-fluid rounded-circle"
                  alt="profile"
                />
              </div>
              <div className="profile-details">
                <div className="profile-email px-2 pt-2">
                  <h3 className="text-black mb-0">
                    {profile ? capitalize(profile?.name) : "N/A"}
                  </h3>
                  <p> {profile ? capitalize(profile?.website) : "N/A"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
