/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import UploadContract from "./UploadContract";
import { useRejectContractQuery } from "store/services/adminAPI";
import { getProviderProfile } from "store/actions/profile";
import { verify_data, provider_preparrations } from "utils/constant.data";

const ProviderStatus = () => {
  const dispatch = useDispatch();
  const [acceptContract, setAcceptContract] = useState(false);
  const [skip, setSkip] = useState(true);
  const profileState = useSelector((state) => state.profile);

  const {
    data: rejectResponse,
    isLoading: rejeting,
    error,
    isError,
  } = useRejectContractQuery(undefined, {
    skip,
  });

  useEffect(() => {
    if (rejectResponse) {
      dispatch(getProviderProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectResponse]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title:
          replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
        text: error?.data?.detail,
        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Try Again",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const profile = profileState?.profileInfo?.data?.profile
    ? profileState?.profileInfo?.data?.profile
    : null;

  return (
    <div className="col-xl-12">
      {profile && profile?.status?.toLowerCase() === "selected" && (
        <Card>
          <Card.Header>
            <Card.Title>Application Update</Card.Title>
          </Card.Header>
          <Card.Body>
            {profile?.contract_rejected ? (
              <h2 className="text-danger">
                You have now been exempted from being a applied learning cluster for
                the 3MTT programme.
              </h2>
            ) : (
              <>
                {profile?.contract_submitted ? null : (
                  <>
                    <p>
                      Dear Training Provider, Your application has been
                      verified. To proceed, kindly review the 3MTT training
                      provider contract &nbsp;
                      <a
                        href="https://drive.google.com/file/d/1sG7kBEwGZ8OAfAlmItwiowWznsK0R1ZV/view?usp=drive_link"
                        rel="noreferrer"
                        target="_blank"
                        className="text-primary"
                      >
                        here
                      </a>
                    </p>
                    <ul className="provider-verification ">
                      {verify_data.map((_data) => {
                        return (
                          <li key={_data}>
                            <p>{_data}</p>
                          </li>
                        );
                      })}
                    </ul>
                    <p>
                      By accepting, you acknowledge that you have read,
                      understood and agree to the obligations stated in your
                      signed copy of the contract you will be attaching below.
                    </p>
                  </>
                )}

                {profile?.contract_submitted ? (
                  <>
                    {profile?.contract_accepted ? (
                      <h4 className="text-primary font-w600">
                        You have been officially approved as an applied learning cluster
                        for the 3MTT program.
                      </h4>
                    ) : (
                      <>
                        <p>
                          Thank you for executing the training provider
                          contract. Kindly see next steps below:
                        </p>
                        <ul className="provider-verification ">
                          {provider_preparrations.map((_data) => {
                            return (
                              <li key={_data}>
                                <p>{_data}</p>
                              </li>
                            );
                          })}
                        </ul>
                        <h4 className="text-pigment-green font-w600">
                          Submitted successfully. The counter-signed version of
                          your contract will be shared via email.
                        </h4>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {acceptContract ? (
                      <UploadContract setAcceptContract={setAcceptContract} />
                    ) : (
                      <div>
                        <button
                          onClick={async () =>
                            Swal.fire({
                              title: "Notice",
                              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                              text: "Do you want to reject your selection as a training applied learning cluster for the 3MTT programme?",
                              showCloseButton: true,
                              customClass: {
                                icon: "no-border",
                              },
                              cancelButtonText: "Reject",
                              showCancelButton: true,
                              confirmButtonText: "Cancel",
                              allowOutsideClick: false,
                              allowEscapeKey: false,
                            }).then(async (result) => {
                              if (!result.isConfirmed) {
                                setSkip(false);
                              }
                            })
                          }
                          className="btn btn-danger"
                        >
                          {rejeting ? (
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm ms-2"
                            ></span>
                          ) : (
                            "Reject"
                          )}
                        </button>
                        <button
                          onClick={() => setAcceptContract(true)}
                          className="btn btn-primary accept-button"
                        >
                          Accept Terms
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default ProviderStatus;
