/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { level_six } from "utils/constant.data";
import { countWords } from "utils/strings";

const ProfileLevelSix = ({ profile, formik, validateFormStep, setStep, updating, handleSubmit }) => {
  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe your existing job placements plan. Provide examples of any
            past programs where your organisation successfully facilitated job
            placements for trainees. Include the percentage of successful
            placements for graduates in your previous programs. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.existing_placement_plan : ""}
          id="existing_placement_plan:"
          name="existing_placement_plan:"
          {...formik.getFieldProps("existing_placement_plan")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.existing_placement_plan ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.existing_placement_plan}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.existing_placement_plan
              ? `${countWords(formik?.values.existing_placement_plan)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe your plan for facilitating job placements for trainees
            post-training as part of the 3MTT programme. (Max 500
            words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.plan_facilitating_job_placements : ""}
          id="plan_facilitating_job_placements"
          name="plan_facilitating_job_placements"
          {...formik.getFieldProps("plan_facilitating_job_placements")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.plan_facilitating_job_placements ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.plan_facilitating_job_placements}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.plan_facilitating_job_placements
              ? `${countWords(
                  formik?.values.plan_facilitating_job_placements
                )}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe partnerships, if any, with industries, organisations, or
            job placement agencies to support trainee job placements. (Max 500
            words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.partnerships_for_job_placements : ""}
          id="partnerships_for_job_placements"
          name="partnerships_for_job_placements"
          {...formik.getFieldProps("partnerships_for_job_placements")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.partnerships_for_job_placements ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.partnerships_for_job_placements}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.partnerships_for_job_placements
              ? `${countWords(
                  formik?.values.partnerships_for_job_placements
                )}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          className="ms-2 me-2 py-3 btn-pigment-green-outline "
          disabled={updating}
          style={{ width: "100% !important" }}
          type="button"
          onClick={() => {
            setStep(5);
          }}
        >
          Previous
        </Button>
        <Button
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
          onClick={() => {
            const L6_ERROR = validateFormStep(level_six);
            if (L6_ERROR) {
              return
            }
            handleSubmit(formik.values, level_six, 6)
            }
          }
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Save"
          )}
        </Button>

        <Button
          onClick={() => {
            const L6_ERROR = validateFormStep(level_six);
            if (!L6_ERROR) {
              setStep(7);
            }
          }}
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelSix;
