/** @format */

import { capitalize } from "utils/strings";
export const COLUMNS = [
  {
    Header: "Name",
    accessor: "first_name",
    sortType: "alphanumeric",
    Cell: ({ cell }) => {
      return `${
        Array.from(capitalize(cell?.row?.original?.first_name))[0]
      }  ${capitalize(cell?.row?.original?.last_name)}`;
    },
  },
  // {
  //   Header: "Last Name",
  //   accessor: "last_name",
  //   Cell: ({ cell: { value } }) => {
  //     return `${capitalize(value)}`;
  //   },
  // },
  {
    Header: "Course",
    accessor: "current_learning_course",
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
  {
    Header: "Fellow ID",
    accessor: "fellow_id",
    Cell: ({ cell: { value } }) => {
      return <span className="text-uppercase">{capitalize(value)}</span>;
    },
  },
   {
    Header: "Cohort",
    accessor: "cohort",
    Cell: ({ cell }) => {
      return `Cohort ${cell?.row?.original?.cohort?.rank}`;
    },
  },
  {
    Header: "State of Residence",
    accessor: "residence",
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
  {
    Header: "Gender",
    accessor: "gender",
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
];
