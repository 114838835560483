/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const CertificateModal = ({ showPrompt, setShowPrompt }) => {
  return (
    <Modal className="modal fade" show={showPrompt}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">General Notice for Cohort 1</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowPrompt(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <p>
              For you to be able to Generate your Certificate, you need to have
              completed your course (100%) on your online learning platform. You
              are also required to have completed your physical classes with
              your Applied Learning Cluster. Your 3MTT Status must be either
              "ALC Completed" or "Employed" for you to be eligible for
              certification. Reach out to your Applied Learning Cluster if your
              current status is not mentioned above.
            </p>

            <div className="col-lg-12  w-100 d-flex justify-content-center">
              <div className="form-group mb-3">
                <button
                  type="button"
                  onClick={() => setShowPrompt(false)}
                  className="submit btn btn-pigment-green"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CertificateModal;
