/** @format */

import React from "react";

import ProviderLayout from "views/providers/Provider";
import ProviderFellowsTable from "../../components/table/fellows/ProviderFellowsTable";
import { useGetAllFellowsQuery } from "store/services/adminAPI";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";

const ProviderCohortFellows = () => {
  const { data, isLoading: fetchingFellows } = useGetAllFellowsQuery("taiwo");

  return (
    <ProviderLayout>
      <div className="row">
        {fetchingFellows ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : data?.data?.length > 0 ? (
          <ProviderFellowsTable fellows={data?.data} />
        ) : (
          <div className="card">
            <div className="card-body text-center ai-icon">
              <BagIcon />
              <h4 className="my-2">No Fellows assigned yet</h4>
              <p>
                Fellows will only be assigned after Providers are shortlisted
              </p>
              {/* <button className="btn my-2 btn-primary btn-lg px-4">
                <i className="fa fa-usd"></i> Earn Budges
              </button> */}
            </div>
          </div>
        )}
      </div>
    </ProviderLayout>
  );
};
export default ProviderCohortFellows;
