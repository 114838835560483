/** @format */
import { decrypt } from "utils/secure";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const accessToken = decrypt(window.localStorage.getItem("3MTAT"));
    headers.set("Content-Type", "application/json");
    if (accessToken) {
      headers.set("Authorization", `${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // window.localStorage.removeItem("3MTAT");
    // window.localStorage.removeItem("3MTUSER");
    // window.location.replace("/login");

    // const refreshResult = await baseQuery("token/refresh/", api, extraOptions);
    // if (refreshResult.data) {
    //   api.dispatch(tokenUpdated({ accessToken: refreshResult.data }));
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    //   _result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logout());
    // }
  }
  return result;
};

export default baseQueryWithReauth;
