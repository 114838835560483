import React, { useState } from "react";
import { LocalGov, Nigerian_States, No_of_staffs} from "utils/constant.data";
import { countWords, extractErrorMessage } from "utils/strings";
import { Button } from "react-bootstrap";
import { useCreateProviderBranchApplicationMutation } from "store/services/adminAPI";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {replaceUnderscoresWithSpaces } from "utils/strings";

const SubApplicationForm = ({toggleCreateMode}) => {

    const [createBranchApplication, {isLoading}] = useCreateProviderBranchApplicationMutation(); 

    const [LGAs, setLGAs] = useState([]);


    const handleSubmit =async(values)=>{

        Swal.fire({
            title: "",
            iconHtml: `<i class="fa fa-info-circle text-greyy" aria-hidden="true"></i>`,
            text: "Are you sure you want to proceed?",
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Proceed",
            cancelButtonText: "Cancel",
          }).then(async (result) => {
            if (result.isConfirmed) {
              await createBranchApplication({
                ...values,
              })
                .unwrap()
                .then((data) => {
                  toast.success(`✔️ Application submitted succesfully!`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                  });
                  toggleCreateMode()
                })
                .catch((error) => {
                    const formattedError = extractErrorMessage(error)
                  Swal.fire({
                    title: replaceUnderscoresWithSpaces(error?.data.detail),
                    iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                    text: formattedError,
                    showCloseButton: true,
                    customClass: {
                      icon: "no-border",
                    },
                    confirmButtonText: "Try Again",
                  });
                });
            }
          });

    }

    const formik = useFormik({
        initialValues: {
            branch_name: "",
            state: "",
            lga: "",
            address: "",
            staff_count: "",
            email: "",
            phone_number: "",
            physical_infrastructure_description: "",
            experience_impl_projects_more_than_one_state: "",
            experience_training_plwds_in_state: "",
            faculty_relevance_experience: "",
          },
        validationSchema: FormSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    })

    return (
        <div className="pt-4 border-bottom-1 pb-3">
        <div className="col-xl-6 col-md-8">
            <div className="profile-skills mb-5">
                
                <form onSubmit={formik.handleSubmit}>
                <h4 class="fs-26 font-w400 text-black mb-3 text-pigment-green">Add branch</h4>
                    <div className="mb-3">
                        <label className="mb-1">
                            <strong>Branch Name</strong>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="branch_name"
                            name="branch_name"
                            placeholder="Branch Name"
                            {...formik.getFieldProps("branch_name")}

                        />
                        {formik.errors.branch_name ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.branch_name}</div>
                        ) : null}
                    </div>

                    <div className="form-group mb-3">
                        <label className="mb-1">
                            <strong>State of training centre</strong>
                        </label>
                        <select
                        id="state"
                        name="state"
                        className="form-control"
                        onChange={(e) => {
                            const selectedState = e.target.value;
                            formik.setFieldValue("state", selectedState);
                            formik.setFieldTouched("state", true);

                            const _selectedLGAs =
                            LocalGov.find(
                                (val) =>
                                val?.state?.toLowerCase() === selectedState.toLowerCase()
                            )?.lgas || [];

                            setLGAs([..._selectedLGAs]);
                        }}
                        >
                        {Nigerian_States.map((state) => {
                            return <option value={state}>{state}</option>;
                        })}
                        </select>
                        {formik.errors.state ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.state}</div>
                        ) : null}
                    </div>
                    <div className="form-group mb-3">
                        <label className="mb-1">
                            <strong>Local Government</strong>
                        </label>

                        <select
                            id="lga"
                            name="lga"
                            className="form-control"
                            {...formik.getFieldProps("lga")}
                            onChange={(e) => {
                                if (e.target.value?.length > 0) {
                                formik.setFieldValue("lga", e.target.value);
                                formik.setFieldError("lga", "");
                                } else {
                                formik.setFieldError("lga", `Local Government is required`);
                            }
                        }}
                        >
                        <option value="">Select Local Government</option>

                        {LGAs
                            ? LGAs.map((state) => {
                                return <option value={state}>{state}</option>;
                            })
                            : null}
                        </select>

                        {formik.errors.lga ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.lga}</div>
                        ) : null}

                    </div>
                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>Address</strong>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            placeholder="Address"
                            {...formik.getFieldProps("address")}

                            />
                            {formik.errors.address ? (
                            <div className="text-danger mt-2 fs-12">{formik.errors.address}</div>
                            ) : null}
                    </div>


                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>Email</strong>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Branch Email"
                            {...formik.getFieldProps("email")}
                            />
                        {formik.errors.email ? (
                        <div className="text-danger mt-2 fs-12">
                            {formik.errors.email}
                        </div>
                        ) : null}
                    </div>

                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>Phone Number</strong>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="phone_number"
                            name="phone_number"
                            placeholder="Phone number"
                            {...formik.getFieldProps("phone_number")}

                            />
                        {formik.errors.phone_number ? (
                        <div className="text-danger mt-2 fs-12">
                            {formik.errors.phone_number}
                        </div>
                        ) : null}
                    </div>

                    <div className="mb-3">
                        <label className="mb-1">
                            <strong>Staff count</strong>
                        </label>
                        <select
                            id="staff_count"
                            name="staff_count"
                            className="form-control"
                            {...formik.getFieldProps("staff_count")}
                            >
                            <option value="">Choose </option>
                            {No_of_staffs?.map((val, i) => (
                                <option value={val}>{val} </option>
                            ))}
                        </select>
                        {formik.errors.staff_count ? (
                        <div className="text-danger mt-2 fs-12">
                            {formik.errors.staff_count}
                        </div>
                        ) : null}
                    </div>

                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>
                        Physical infrastructure available for in-person training delivery, including space, equipment, and work tools. (Max 2000 words)
                        </strong>
                        </label>

                        <textarea
                        className="w-100 form-control"
                        placeholder="Your answer"
                        id="physical_infrastructure_description"
                        name="physical_infrastructure_description"
                        rows="3"
                        {...formik.getFieldProps("physical_infrastructure_description")}
                        />

                        <div className="d-flex align-items-center justify-content-between">
                        {formik.errors.physical_infrastructure_description ? (
                            <div className="text-danger mt-2 fs-12">
                            {formik.errors.physical_infrastructure_description}
                            </div>
                        ) : null}
                        <div className="text-warning mt-2 fs-12">
                            {formik?.values.physical_infrastructure_description
                            ? `${countWords(
                                formik?.values.physical_infrastructure_description
                                )}/2000`
                            : ""}

                            
                        </div>
                        </div> 
                    </div>

                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>
                        Do you have experience implementing projects in more than one state? If Yes Share with us a report of evidence of such or a link for Social Media Post. (Max 2000 words)
                        </strong>
                        </label>

                        <textarea
                        className="w-100 form-control"
                        placeholder="Your answer"
                        id="experience_impl_projects_more_than_one_state"
                        name="experience_impl_projects_more_than_one_state"
                        {...formik.getFieldProps("experience_impl_projects_more_than_one_state")}
                        rows="3"
                        />

                        <div className="d-flex align-items-center justify-content-between">
                        {formik.errors.experience_impl_projects_more_than_one_state ? (
                            <div className="text-danger mt-2 fs-12">
                            {formik.errors.experience_impl_projects_more_than_one_state}
                            </div>
                        ) : null}
                        <div className="text-warning mt-2 fs-12">
                            {formik?.values.experience_impl_projects_more_than_one_state
                            ? `${countWords(
                                formik?.values.experience_impl_projects_more_than_one_state
                                )}/2000`
                            : ""}

                            
                        </div>
                        </div> 
                    </div>

                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>
                        Do you have experience and infrastructure for training PLWDs in this state?If Yes please provide record or any proof. (Max 2000 words)
                        </strong>
                        </label>

                        <textarea
                        className="w-100 form-control"
                        placeholder="Your answer"
                        id="experience_training_plwds_in_state"
                        name="experience_training_plwds_in_state"
                        {...formik.getFieldProps("experience_training_plwds_in_state")}
                        rows="3"
                        />

                        <div className="d-flex align-items-center justify-content-between">
                        {formik.errors.experience_training_plwds_in_state ? (
                            <div className="text-danger mt-2 fs-12">
                            {formik.errors.experience_training_plwds_in_state}
                            </div>
                        ) : null}
                        <div className="text-warning mt-2 fs-12">
                            {formik?.values.experience_training_plwds_in_state
                            ? `${countWords(
                                formik?.values.experience_training_plwds_in_state
                                )}/2000`
                            : ""}

                            
                        </div>
                        </div> 
                    </div>

                    <div className="mb-3">
                        <label className="mb-1">
                        <strong>
                        Faculty Relevant Experience. (Max 2000 words)
                        </strong>
                        </label>

                        <textarea
                        className="w-100 form-control"
                        placeholder="Your answer"
                        id="faculty_relevance_experience"
                        name="faculty_relevance_experience"
                        {...formik.getFieldProps("faculty_relevance_experience")}
                        rows="3"
                        />

                        <div className="d-flex align-items-center justify-content-between">
                        {formik.errors.faculty_relevance_experience ? (
                            <div className="text-danger mt-2 fs-12">
                            {formik.errors.faculty_relevance_experience}
                            </div>
                        ) : null}
                        <div className="text-warning mt-2 fs-12">
                            {formik?.values.faculty_relevance_experience
                            ? `${countWords(
                                formik?.values.faculty_relevance_experience
                                )}/2000`
                            : ""}

                            
                        </div>
                        </div> 

                    </div>
                    <Button
                        type="submit"
                        className="ms-2 pull-right  py-3 btn-pigment-green"
                    >
                        {isLoading ? (
                            <span
                            role="status"
                            aria-hidden="true"
                            className="spinner-border spinner-border-sm ms-2 text-white"
                            ></span>
                        ) : (
                            "Submit"
                        )}
                    </Button>

                   

                </form>

            </div>
        </div>
        </div>
    )
}

export default SubApplicationForm;

Yup.addMethod(Yup.string, "wordLength", function (errorMessage, maxLength) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;
      const wordArray = value?.trim().split(/\s+/);
  
      return (
        (value && wordArray.length < maxLength) ||
        createError({ path, message: errorMessage })
      );
    });
  });

const FormSchema = Yup.object().shape({
    branch_name: Yup.string().min(5, "Branch namer must be at least 5 characters").required("Branch name is required"),
    state: Yup.string().required("State is required"),
    lga: Yup.string().required("Local government is required"),
    address: Yup.string().required("Address is required"),
    staff_count: Yup.string().required("Staff count is required"),
    email: Yup.string().email("Invalid email address").required("Branch email is required"),
    phone_number: Yup.string().min(10, "Phone number must be at least 10 numbers").required("Phone number is required"),
    physical_infrastructure_description: Yup.string()
    .required("Physical infrastructure proof is required")
    .wordLength("Total word count can not exceed 2000", 2001),
    experience_impl_projects_more_than_one_state: Yup.string()
    .required("Experience implenting projects in more than one state is required")
    .wordLength("Total word count can not exceed 2000", 2001),
    experience_training_plwds_in_state: Yup.string()
    .required("Experience training pwlds in state required")
    .wordLength("Total word count can not exceed 2000", 2001),
    faculty_relevance_experience: Yup.string()
    .required("Faculty relevance experience ")
    .wordLength("Total word count can not exceed 2000", 2001),
})
