import {useEffect, useState} from "react"
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";

import { useUpdateFellowProfileMutation, useGetAllCoursesQuery } from "store/services/adminAPI";
import { checkDateValidity } from "utils/date";

import {
    Nigerian_States,
    EducationData,
    LocalGov,
  } from "utils/constant.data";

  import {
    AgeOptions,
    GenderOptions,
    SkillOptions,
    EmploymentOptions
  } from "utils/strings";

const FellowProfileForm = ({currentProfile, toggleEditMode}) => {
    const [LGAs, setLGAs] = useState([]);

    const {data:courses, isLoading:loadingCourses} = useGetAllCoursesQuery()

    const [updateFellowProfile, { isLoading: updating }] =
    useUpdateFellowProfileMutation();

    const deadlineIsValid = checkDateValidity("12/09/2024")
    const blockedCohorts = [1,2]
    const cohort_rank = currentProfile?.cohort?.rank
    const isDisabled = blockedCohorts.includes(cohort_rank)

    const CoursesOptions = courses?.data?.courses?.length > 0
    ? courses?.data?.courses?.map((course) => ({
        value: course?.id,
        label: course?.title
    })) : [];

    const handleSubmit = async (values) => {
        const initialValues = formik.initialValues;
        const editedFields = {};

        Object.keys(values).forEach((fieldName) => {
            if (values[fieldName] !== initialValues[fieldName]) {
                editedFields[fieldName] = values[fieldName];
            }
        });

        await updateFellowProfile(editedFields)
        .unwrap()
        .then((data)=>{
            toast.success(`✔️ Profile updated !`, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
            });
            toggleEditMode();
        })
        .catch((error) => {
            const formattedError = extractErrorMessage(error)
            Swal.fire({
              title:
                replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
              text: formattedError,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
        
    };


    const formik = useFormik({
        initialValues: {
            first_name: currentProfile?.first_name,
            last_name: currentProfile?.last_name,
            age: currentProfile?.age,
            gender: currentProfile.gender,
            state_of_origin: currentProfile.state_of_origin,
            residence: currentProfile.residence,
            level_of_education: currentProfile.level_of_education,
            fellow_course: currentProfile.fellow_course?.id,
            technical_skill_level: currentProfile.technical_skill_level,
            address: currentProfile.address,
            lga: currentProfile.lga,
            phone: currentProfile.phone,
            employment_status: currentProfile.employment_status
        },
        onSubmit: handleSubmit,
        enableReinitialize: true,
        validationSchema: FormSchema,
    })

    useEffect(() => {
        const selectedState = formik.values.residence;
        const selectedLGAs = LocalGov.find(
          (val) => val?.state?.toLowerCase() === selectedState.toLowerCase()
        )?.lgas || [];
        setLGAs([...selectedLGAs]);
      }, [formik.values.residence]);

    return (
        <div className="basic-form">
            <form onSubmit={formik.handleSubmit}>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>First name</strong></label>
                            <input 
                                defaultValue={currentProfile.first_name}
                                name="first_name"
                                id="first_name"
                                className="form-control"
                                placeholder="First name"
                                {...formik.getFieldProps("first_name")}
                                disabled={isDisabled}
                            />
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Last name</strong></label>
                            <input 
                                name="last_name"
                                id="last_name"
                                className="form-control"
                                placeholder="Last name"
                                {...formik.getFieldProps("last_name")}
                                disabled={isDisabled}
                            />
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Age</strong></label>
                        <select
                            id="age"
                            name="age"
                            className="form-control"
                            value={formik.values.age}
                            onChange={(e) => {
                                const selectedAgeRange = e.target.value;
                                formik.setFieldValue("age", selectedAgeRange);
                            }}
                            {...formik.getFieldProps("age")}
                            disabled={isDisabled}

                            >
                            {AgeOptions.map((age) => {
                                return <option value={age.value}>{age.label}</option>;
                            })}
                        </select>
                        {formik.errors.age ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.age}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Gender</strong></label>
                        <select
                            id="gender"
                            name="gender"
                            value={formik.values.gender}

                            className="form-control"
                            onChange={(e) => {
                                const selectedGender = e.target.value;
                                formik.setFieldValue("gender", selectedGender);
                            }}
                            {...formik.getFieldProps("gender")}
                            disabled={isDisabled}

                            >
                            {GenderOptions.map((gender) => {
                                return <option value={gender.value}>{gender.label}</option>;
                            })}
                        </select>
                        {formik.errors.gender ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.gender}</div>
                        ) : null}
                    </div>
                </div>
                <div className="row g-3 mb-3">
                    {
                        loadingCourses ? null : (
                            <div className="col-md-6">
                        <label className="mb-1"><strong>Course</strong></label>
                        <select
                            id="fellow_course"
                            name="fellow_course"
                            value={formik.values.fellow_course}
                            className="form-control"
                            onChange={(e) => {
                                const selectedCourse = e.target.value;
                                formik.setFieldValue("fellow_course", selectedCourse);
                            }}
                            {...formik.getFieldProps("fellow_course")}
                            disabled={!(cohort_rank !== 1 && deadlineIsValid) && isDisabled}


                            >
                            {CoursesOptions.map((courseoption) => {
                                return <option value={courseoption.value}>{courseoption.label}</option>;
                            })}
                        </select>
                        {formik.errors.fellow_course ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.fellow_course}</div>
                        ) : null}
                    </div>
                        )
                    }
                    

                    <div className="col-md-6">
                        <label className="mb-1"><strong>Skill level </strong></label>
                        <select
                            id="technical_skill_level"
                            name="technical_skill_level"
                            value={formik.values.technical_skill_level}
                            className="form-control"
                            onChange={(e) => {
                                const selectedSkill = e.target.value;
                                formik.setFieldValue("technical_skill_level", selectedSkill);
                            }}
                            {...formik.getFieldProps("technical_skill_level")}
                            disabled={isDisabled}

                            >
                            {SkillOptions.map((skill) => {
                                return <option value={skill.value}>{skill.label}</option>;
                            })}
                        </select>
                        {formik.errors.technical_skill_level ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.technical_skill_level}</div>
                        ) : null}
                    </div>
                    
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Education Level</strong></label>
                        <select
                            id="level_of_education"
                            name="level_of_education"
                            value={formik.values.level_of_education}
                            className="form-control"
                            onChange={(e) => {
                                const selectedEducationLevel = e.target.value;
                                formik.setFieldValue("level_of_education", selectedEducationLevel);
                                formik.setFieldTouched("state", true);
                            }}
                            {...formik.getFieldProps("level_of_education")}
                            disabled={isDisabled}

                            >
                            {EducationData.map((education) => {
                                return <option value={education}>{education}</option>;
                            })}
                        </select>
                        {formik.errors.education ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.education}</div>
                        ) : null}
                    </div>


                    <div className="col-md-6">
                        <label className="mb-1"><strong>Address </strong></label>
                        <input
                            id="address"
                            name="address"
                            value={formik.values.address}
                            placeholder="Address"
                            className="form-control"
                            {...formik.getFieldProps("address")}
                            disabled={!(cohort_rank !== 1 && deadlineIsValid) && isDisabled}
                           
                            />
                           
                        {formik.errors.address ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.address}</div>
                        ) : null}
                    </div>
                    
                </div>
                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>State of origin</strong></label>
                       
                        <select
                            id="state_of_origin"
                            name="state_of_origin"
                            value={formik.values.state_of_origin}
                            className="form-control"
                            onChange={(e) => {
                                const selectedState = e.target.value;
                                formik.setFieldValue("state_of_origin", selectedState);
                                formik.setFieldTouched("state_of_origin", true);
                            }}
                            {...formik.getFieldProps("state_of_origin")}
                            disabled={isDisabled}

                            >
                        {Nigerian_States.map((state) => {
                            return <option value={state}>{state}</option>;
                        })}
                    </select>
                    {formik.errors.state_of_origin ? (
                    <div className="text-danger mt-2 fs-12">{formik.errors.state_of_origin}</div>
                    ) : null}
                    </div>

                    <div className="col-md-6">
                        <label className="mb-1"><strong>State of residence</strong></label>
                       
                        <select
                            id="residence"
                            name="residence"
                            value={formik.values.residence}
                            className="form-control"
                            onChange={(e) => {
                                const selectedState = e.target.value;
                                formik.setFieldValue("residence", selectedState);
                                formik.setFieldTouched("residence", true);

                                const _selectedLGAs =
                                    LocalGov.find(
                                        (val) =>
                                        val?.state?.toLowerCase() === selectedState.toLowerCase()
                                    )?.lgas || [];

                                setLGAs([..._selectedLGAs]);

                            }}
                            disabled={!(cohort_rank !== 1 && deadlineIsValid) && isDisabled}

                            >
                        {Nigerian_States.map((state) => {
                            return <option value={state}>{state}</option>;
                        })}
                    </select>
                    {formik.errors.residence ? (
                    <div className="text-danger mt-2 fs-12">{formik.errors.residence}</div>
                    ) : null}
                    </div>
                </div>

                <div className="row g-3 mb-3">
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Local Government of residence</strong></label>
                        <select
                        id="lga"
                        name="lga"
                        value={formik.values.lga}
                        className="form-control"
                        {...formik.getFieldProps("lga")}

                        onChange={(e) => {
                            if (e.target.value?.length > 0) {
                            formik.setFieldValue("lga", e.target.value);
                            formik.setFieldError("lga", "");
                            } else {
                            formik.setFieldError("lga", `Local Government is required`);
                            }
                        }}
                        disabled={!(cohort_rank !== 1 && deadlineIsValid) && isDisabled}

                        >
                        <option value="">Select Local Government</option>

                        {LGAs
                                ? LGAs.map((state) => {
                                    return <option value={state}>{state}</option>;
                                })
                                : null}
                        </select>
                        {formik.errors.lga ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.lga}</div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <label className="mb-1"><strong>Phone number</strong></label>

                        <input
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            placeholder="Phone"
                            className="form-control"
                            {...formik.getFieldProps("phone")}
                            disabled={isDisabled}
                           
                            />
                           
                        {formik.errors.phone ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.phone}</div>
                        ) : null}
                        
                    </div>
                </div>
                <div className="row g-3 mb-3">
                <div className="col-md-6">
                        <label className="mb-1"><strong>Employment Status</strong></label>
                        <select
                            id="employment_status"
                            name="employment_status"
                            value={formik.values.employment_status}

                            className="form-control"
                            onChange={(e) => {
                                const selectedEmploymentStatus = e.target.value;
                                formik.setFieldValue("employment_status", selectedEmploymentStatus);
                            }}
                            {...formik.getFieldProps("employment_status")}
                            disabled={isDisabled}

                            >
                            {EmploymentOptions.map((employment_status) => {
                                return <option value={employment_status.value}>{employment_status.label}</option>;
                            })}
                        </select>
                        {formik.errors.employment_status ? (
                        <div className="text-danger mt-2 fs-12">{formik.errors.employment_status}</div>
                        ) : null}
                    </div>
                    
                </div>
                <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                    disabled={updating || !formik.dirty}
                  >
                    {updating ? (
                      <span
                        role="button"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      "Update"
                    )}
                  </button>
            </form>
        </div>
    );
}

export default FellowProfileForm;

const FormSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    state_of_origin: Yup.string().required("State of origin is required"),
    age: Yup.string().required("Age is required"),
    fellow_course: Yup.string().required("Course is required"),
    gender: Yup.string().required("Gender is required"),
    technical_skill_level: Yup.string().required("Skill level is required"),
    level_of_education: Yup.string().required("Education is required"),
    address: Yup.string().required("Address is required"),
    residence: Yup.string().required("State of residence is required"),
    lga: Yup.string().required("State of residence is required"),
    phone: Yup.string().min(10, "Phone number must be at least 10 numbers").required("Phone number is required"),
    employment_status: Yup.string().required("Employment Status is required"),
});