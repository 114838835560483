/** @format */

import React from "react";
import { CourseInterest } from "utils/constant.data";

const QuestionsThree = ({ formik, setStep }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="form-group mb-3">
          <label className="mb-1">
            <strong>Course</strong>
          </label>

          <select
            id="current_learning_course"
            name="current_learning_course"
            className="form-control"
            {...formik.getFieldProps("current_learning_coursel")}
          >
            {/* <option value="">Select Course</option> */}
            {CourseInterest.map((_course) => {
              return <option value={_course}>{_course}</option>;
            })}
          </select>

          {formik.touched.current_learning_course &&
          formik.errors.current_learning_course ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.current_learning_course}
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group mb-3 d-flex justify-content-between">
          <button
            type="button"
            onClick={() => setStep(2)}
            className="submit btn  btn-pigment-green-outline"
          >
            Previous
          </button>
          <button type="submit" className="submit btn btn-pigment-green">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionsThree;
