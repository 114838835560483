import React from "react";
import MMLogo from "images/3MTT/3MM.png";
import { Link } from "react-router-dom";
import { Path } from "utils/constant.data";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FadeLoader } from "react-spinners";

import { useLazyVerifyFellowCertificationQuery } from "store/services/adminAPI";



const VerifyFellowCertification = () => {

    const [verifyFellow, {isLoading}] = useLazyVerifyFellowCertificationQuery()

  const handleSubmit = async (values) => {
    try {
      await verifyFellow(values.fellow_id)
      .unwrap();
      // console.log(data);
    } catch (error) {
      console.error("Error verifying fellow:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      fellow_id: "",
    },
    validationSchema: VerifyFellowSchema,
    onSubmit: handleSubmit,
  });

  return (
    <section className="external__table p-4">
      <div className="d-flex justify-content-center align-items-center logo__holder">
        <div className="text-center mm__logo mb-2">
          <Link to={Path.LOGIN}>
            <img src={MMLogo} alt="FMCIDE logo" />
          </Link>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center logo__holder mb">
        <div className="text-center">
          <h3>VERIFY FELLOW'S CERTIFICATE</h3>
        </div>
        <div className="text-center">
          <p>
            Verify the authenticity and validity of the certificate held by a
            participant in the program.
          </p>
        </div>
      </div>
      <div className="d-flex h-100 minvh">
        <div className="container h-100">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="d-flex gap-1">
                        <div className="col-9">
                          <input
                            type="text"
                            placeholder="Enter fellow ID"
                            className="form-control me-2"
                            name="fellow_id"
                            value={formik.values.fellow_id}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.fellow_id &&
                          formik.errors.fellow_id ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.fellow_id}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-3">
                          <button
                            className="btn btn-primary"
                            type="submit"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {formik.isSubmitting || isLoading  (
                  <div className="d-flex justify-content-center my-4">
                    <FadeLoader color="#26a65b" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyFellowCertification;

const VerifyFellowSchema = Yup.object().shape({
  fellow_id: Yup.string().required("Fellow ID is required"),
});
