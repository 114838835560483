/** @format */

import Metismenu from "metismenujs";
import React, {
  Component,
  useContext,
  useEffect,
  useState,
  Fragment,
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "context/ThemeContext";
import { useSelector } from "react-redux";
import { getProviderProfile } from "store/actions/profile";
import { useDispatch } from "react-redux";
import MMLogo from "images/3MTT/fg.png";
import { Path } from "utils/constant.data";
import {
  mapStatusHeading,
  mapStatusInformation,
  mapStatus,
} from "utils/strings";
import { FadeLoader } from "react-spinners";
import { useGetProviderCoursesQuery } from "store/services/providerAPI";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const profileState = useSelector((state) => state.profile);
  let dispatch = useDispatch();

  const { data: courses, isLoading: fetchingCourses } =
    useGetProviderCoursesQuery();

  useEffect(() => {
    dispatch(getProviderProfile()).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const pathName = window.location.pathname;
  let path = pathName.split("/");
  path = path[path.length - 1];

  let deshBoard = ["dashboard"],
    // broadcasts = ["broadcasts"],
    // notifications = ["notifications"],
    faqs = ["faqs"],
    // forums = ["forums"],
    fellows = ["fellows"],
    attendance = ["attendance"],
    assesments = ["assesments"],
    application = ["application"],
    support = ["support"];

  const pathIncludesCourses = pathName.includes("courses");
  const pathIncludesAttendance = pathName.includes("attendance");

  const profile = profileState?.profileInfo?.data?.profile
    ? profileState?.profileInfo?.data?.profile
    : null;

  // console.log("profile", profile);
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.PROVIDER_DASHBOARD}>
              <i className="bi bi-grid"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>

          <li className={`${application.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.PROVIDER_PROFILE}>
              <i className="bi bi-person"></i>
              <span className="nav-text">Application</span>
            </Link>
          </li>

          {profile?.status?.toLowerCase() === "selected" && (
            <>
              <li className={`${fellows.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to={Path.PROVIDER_FELLOWS}>
                  {" "}
                  <i className="bi bi-people"></i>{" "}
                  <span className="nav-text">Fellows</span>
                </Link>
              </li>

              {fetchingCourses ? (
                <div className="d-flex justify-content-center my-4">
                  <FadeLoader color="#26a65b" />
                </div>
              ) : (
                courses?.data?.length > 0 && (
                  <li
                    className={`course-link-item ${
                      pathIncludesCourses ? "active-parent" : ""
                    }`}
                  >
                    <Link className="has-arrow parent-link" to="#">
                      <i className="bi bi-book"></i>
                      <span className="nav-text">Courses</span>
                    </Link>
                    {courses?.data?.length > 0 && (
                      <ul>
                        {courses?.data?.map((course) => {
                          return (
                            <li>
                              <Link
                                className={`${
                                  path === "product-management"
                                    ? "mm-active"
                                    : ""
                                } child-link`}
                                to={`${Path.PROVIDER__COURSE}${course?.course_id}`}
                              >
                                {course?.course?.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                )
              )}
{/* 
              <li className={`${attendance.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to={Path.PROVIDER_ATTENDANCE}>
                  {" "}
                  <i className="bi bi-calendar-week"></i>{" "}
                  <span className="nav-text">Attendance</span>
                </Link>
              </li> */}

              <li
                className={`course-link-item ${
                  pathIncludesAttendance ? "active-parent" : ""
                }`}
              >
                <Link className="has-arrow parent-link" to="#">
                  <i className="bi bi-book"></i>
                  <span className="nav-text"> Attendance</span>
                </Link>

                <ul>
                  <li>
                    <Link
                      className={`${
                        attendance.includes(path) ? "mm-active" : ""
                      } child-link`}
                      to={`${Path.PROVIDER_ATTENDANCE}`}
                    >
                      Attendance List
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        attendance.includes(path) ? "mm-active" : ""
                      } child-link`}
                      to={`${Path.PROVIDER_ATTENDANCE_REPORT}`}
                    >
                      Attendance Report
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={`${assesments.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to={Path.PROVIDER_ASSESMENTS}>
                  {" "}
                  <i className="bi bi-bookshelf"></i>{" "}
                  <span className="nav-text">Assesments</span>
                </Link>
              </li>

              {/* <li className={`${broadcasts.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to={Path.PROVIDER_BROADCAST}>
                  <i className="bi bi-bullseye"></i>
                  <span className="nav-text">Broadcasts</span>
                </Link>
              </li>

              <li
                className={`${notifications.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="ai-icon" to={Path.PROVIDER_NOTIFICATIONS}>
                  <i className="bi bi-bell"></i>
                  <span className="nav-text">Notifications</span>
                </Link>
              </li> */}

              <li className={`${support.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to={Path.PROVIDER_SUPPORT_PAGE}>
                  {" "}
                  <i className="bi bi-headset"></i>{" "}
                  <span className="nav-text">Support</span>
                </Link>
              </li>

              <li className={`${faqs.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to={Path.PROVIDER_FAQS}>
                  <i className="bi bi-bullseye"></i>
                  <span className="nav-text">FAQs</span>
                </Link>
              </li>

              {/* <li className={`${forums.includes(path) ? "mm-active" : ""}`}>
                  <Link className="ai-icon" to={Path.PROVIDER_FORUM}>
                    <i className="bi bi-server"></i>
                    <span className="nav-text">Forums</span>
                  </Link>
                </li> */}
            </>
          )}
        </MM>

        {profileState?.isLoading ? (
          <div className="d-flex justify-content-center my-4">
            <FadeLoader color="#26a65b" />
          </div>
        ) : (
          <Fragment>
            <div className="plus-box">
              <div className="d-flex align-items-center">
                <h5>Applied Learning Cluster Status </h5>
              </div>
              <p>{profile?.status && mapStatusInformation(profile?.status)}</p>

              {profile?.status && (
                <div
                  to={"#"}
                  className={`btn ${mapStatus(profile?.status)}  btn-sm w-100 `}
                >
                  {profile?.status && mapStatusHeading(profile?.status)}
                </div>
              )}
            </div>
          </Fragment>
        )}

        <Link to={Path.PROVIDER_DASHBOARD} className="ministry-logo">
          <figure>
            <img src={MMLogo} alt="" />
          </figure>
        </Link>

        <div className="copyright">
          <p>
            <b>FMoCIDE</b> © {new Date().getFullYear()} All Rights Reserved
          </p>
          <p className="fs-12 text-center">
            <span className="heart" style={{ display: "none" }}></span>
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
