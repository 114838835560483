/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { skills, levels } from "utils/strings";

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
  clearFilter,
}) => {
  const [selectedLevel, setLevel] = useState([]);
  const [selectedSkill, setSkill] = useState([]);
  const [duration, setDuration] = useState("");

  useEffect(() => {
    setLevel([]);
    setSkill([]);
    setDuration("");
  }, [clearFilter]);

  const handleFilter = () => {
    if (
      selectedLevel.length === 0 &&
      selectedSkill.length === 0 &&
      duration.length === 0
    ) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        skills: selectedSkill.length > 0 ? selectedSkill : [],
        level_of_difficulty: selectedLevel ? selectedLevel : "",
        duration: duration ? duration : "",
      });
    }
  };

  const SkillOptions = skills.map((course) => ({
    value: course,
    label: course,
  }));

  const LevelOptions = levels.map((course) => ({
    value: course,
    label: course,
  }));

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Fellows</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <Col md={12}>
                  <p className="p-1 m-0">Filter with Duration</p>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="image"
                      name="image"
                      placeholder="Duration in Minutes"
                      value={duration}
                      onChange={(e) => {
                        setDuration(e.target.value);
                      }}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Filter with Level</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedLevel}
                      onChange={setLevel}
                      options={LevelOptions}
                      styles={Style}
                      isMulti
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Filter with Skill</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedSkill}
                      onChange={setSkill}
                      options={SkillOptions}
                      styles={Style}
                      isMulti
                    />
                  </div>
                </Col>
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setLevel([]);
                  setSkill([]);
                  setDuration("");

                  setFilter({
                    // age: [],
                    // skill_level: [],
                    status: [],
                    gender: "",
                    selectedCourse: [],
                  });
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Fellows"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
