import React, { useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import RatingReview from "component/RatingComponent";
import { useConfirmFellowAttendanceMutation } from "store/services/providerAPI";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";


const RatingModal = ({ showModal, setShowModal, attendanceId }) => {
  const [rating, setRating] = useState(0);
  const [confirmFellowAttendance, { isLoading: isSubmitting }] =
    useConfirmFellowAttendanceMutation();

  const handleSubmit = async (values) => {

    if (rating < 1) {
        toast.error("Select a star rating")
        return
    }
    
    const payload = {
      attendance_id: attendanceId,
      comment: values.comment,
      rating: rating,
    };

    try {
      await confirmFellowAttendance(payload).unwrap();
      toast.success(`✔️ Attendance marked Succesfully!`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
      });
      window.location.reload();
    } catch (error) {
      const formattedError = extractErrorMessage(error);
      Swal.fire({
        title: error?.data?.code
          ? replaceUnderscoresWithSpaces(error?.data?.code)
          : "Error Occurred",
        text: formattedError,
        iconHtml: `<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Try Again",
      });
    }
  };

  const validationSchema = Yup.object({
    comment: Yup.string().required("Comment is required"),
    rating: Yup.number().required("Rating is required"),
  });

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className="modal fade"
      id="postModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Rate your ALC</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowModal(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <Formik
            initialValues={{
              comment: "",
              rating: 0,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Col md={12}>
                  <RatingReview 
                  rating={rating} 
                  setRating={setRating} />
                </Col>

                <Col md={12}>
                  <p className="p-1 m-0">Comment</p>
                  <Field
                    as="textarea"
                    type="text"
                    placeholder="Comment on your ALC's performance this week"
                    className="form-control"
                    name="comment"
                  />
                </Col>

                <div className="d-flex w-100 justify-content-center align-items-center mt-2">
                  <Button
                    type="submit"
                    className="ms-2 pull-right py-3 btn-pigment-green"
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    {isSubmitting ? "Confirming" : "Confirm"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default RatingModal;