/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
// import { Path } from "utils/constant.data";
import QuestionsOne from "./Steps/Step1";
import QuestionsTwo from "./Steps/Step2";
import QuestionsThree from "./Steps/Step3";
// import { mapLevel } from "utils/strings";
// import Swal from "sweetalert2";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProviderProfile } from "store/actions/profile";
import { Button } from "react-bootstrap";

const QuestionsModal = ({ showQuestions, setShowQuestions, profile }) => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  // const navigate = useNavigate();

  const handleSubmit = async (values) => {
    dispatch(getProviderProfile());
    setShowQuestions(false);
    // if (
    //   mapLevel(profile?.technical_skill_level)?.toLowerCase() ===
    //     "intermediate" ||
    //   mapLevel(profile?.technical_skill_level)?.toLowerCase() === "advanced"
    // ) {
    //   Swal.fire({
    //     title: "Notice",
    //     text: "You will be redirected to your skill test page to ascertain your technical expertise",
    //     icon: "success",
    //     confirmButtonText: "Ok",
    //   }).then((result) => {
    //     navigate(Path.FELLOW_SKILL_TEST);
    //   });
    // } else {
    //   navigate(Path.FELLOW_PROFILE);
    // }
  };

  const formik = useFormik({
    initialValues: {
      current_learning_course: profile ? profile?.current_learning_course : "",
      technical_skill_level: profile ? profile?.technical_skill_level : "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal className="modal fade" show={showQuestions}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Questionnaire</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowQuestions(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            {step === 1 && <QuestionsOne formik={formik} setStep={setStep} />}
            {step === 2 && <QuestionsTwo formik={formik} setStep={setStep} />}
            {step === 3 && <QuestionsThree formik={formik} setStep={setStep} />}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default QuestionsModal;

const FormSchema = Yup.object().shape({
  technical_skill_level: Yup.string().required("Skill level is required"),
  current_learning_course: Yup.string().required("Course is required"),
});
