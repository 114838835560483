/** @format */

import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { extractFilterValues } from "utils/functions";
import { COLUMNS } from "./Columns";
import FilterModal from "./Filter";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";

export const SelectedFellowsTable = ({
  fellows,
  setFilter,
  filter,
  setSkip,
  filtering,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState(null);
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (fellows) {
      setData([...fellows?.data?.results]);
      setMetadata({
        ...fellows?.data?.metadata,
      });
      setSkip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fellows]);

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);

    const filterString = `page=1${
      values?.course?.length > 0 ? `&course=${values?.course?.join(",")}` : ""
    }${
      values?.residence?.length > 0
        ? `&residence=${values?.residence?.join(",")}`
        : ""
    }${values?.gender?.length > 0 ? `&gender=${values?.gender}` : ""}${values?.cohort_id ? `&cohort_id=${values?.cohort_id}` : ""}
    `;
    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const columns = useMemo(() => COLUMNS, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: "first_name", desc: false }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const restoreFellows = () => {
    setFiltered(false);
    setFilter(`page=1&cohort_id=3330e7dc-75c5-452d-ace9-8a4acfaef48d`);
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const hasNext = fellows?.data?.metadata?.next > 0;
  const hasPrev = fellows?.data?.metadata?.previous > 0;

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreFellows={restoreFellows}
        clearFilter={clear}
      />

      <div className="card">
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <h4 className="card-title">Fellows</h4>

          <div className="d-flex  align-items-center">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by name or fellow ID"
            />

            {searched ? (
              <button
                type="button"
                className="btn btn-outline ml"
                onClick={() => {
                  setSearch("");
                  setFilter(`page=1&cohort_id=3330e7dc-75c5-452d-ace9-8a4acfaef48d`);
                  setSearched(false);
                }}
              >
                Clear
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline ml"
                onClick={() => {
                  if (search?.length > 0) {
                    setClear(!clear);
                    setFilter(`page=1&search=${search}`);
                    setFiltered(false);
                    setSearched(true);
                  }
                }}
              >
                Search
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary ml"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {fellows?.data?.results?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        if (filtered) {
                          setSearch("");
                          if (modalFilter) {
                            handleAllFilter(modalFilter, _newPage);
                          } else {
                            setFilter(`page=${_newPage}`);
                          }
                        } else {
                          setFilter(`page=${_newPage}`);
                        }
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;
                        if (filtered) {
                          setSearch("");
                          if (modalFilter) {
                            handleAllFilter(modalFilter, _newPage);
                          } else {
                            setFilter(`page=${_newPage}`);
                          }
                        } else {
                          setFilter(`page=${_newPage}`);
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-body text-center ai-icon">
                <BagIcon />
                <h4 className="my-2">Record not found</h4>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setFilter(`page=1`);
                    setSearch("");
                  }}
                >
                  Clear All Filters
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default SelectedFellowsTable;
