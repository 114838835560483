/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";

import RegisterValidateEmail from "./ValidateEmail";
import RegisterValidateOTP from "./ValidateOTP";
import RegisterSetPassword from "./SetPassword";

import loginbg from "images/bg-1.svg";

import {
  useResendOTPMutation,
  useSendOTPasPasswordMutation,
} from "store/services/authAPI";
import {replaceUnderscoresWithSpaces } from "utils/strings";

import MMLogo from "images/3MTT/3MM.png";

import { Path } from "utils/constant.data";
import Swal from "sweetalert2";

function Register() {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(null);

  const [resendOTP, { isLoading: authenticating }] =
    useResendOTPMutation();

  const [sendOTPasPassword, { isLoading: sendingViaSMS}] =
    useSendOTPasPasswordMutation();

  const _resendOTP = async () => {
    user &&
      (await resendOTP({
        session_id: user?.session_id,
      })
        .unwrap()
        .then((data) => {
          Swal.fire({
            text: data?.detail,
            icon: "success",
            showCloseButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setStep(3);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: error?.data?.code
              ? replaceUnderscoresWithSpaces(error?.data?.code)
              : "Error Occured",
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        }));
  };

  const resendOTPasSMS = async () => {
    user &&
      (await sendOTPasPassword({
        session_id: user?.session_id,
      })
        .unwrap()
        .then((data) => {
          Swal.fire({
            text: data?.detail,
            icon: "success",
            showCloseButton: true,
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              setStep(3);
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: error?.data?.code
              ? replaceUnderscoresWithSpaces(error?.data?.code)
              : "Error Occured",
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Try Again",
          });
        }));
  };

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>

                      <h4 className="text-center mb-4 ">
                        {step === 2 ? "Create a Password" : "Continue Registration"}
                      </h4>

                      {step === 1 && (
                        <RegisterValidateEmail
                          setStep={setStep}
                          setUser={setUser}
                        />
                      )}
                      {step === 2 && (
                        <RegisterSetPassword
                          user={user}
                          setStep={setStep}
                          setUser={setUser}
                        />
                      )}
                      {step === 3 && (
                        <RegisterValidateOTP
                          user={user}
                          authenticating={authenticating}
                          sendingViaSMS={sendingViaSMS}
                          resendOTP={_resendOTP}
                          resendOTPasSMS={resendOTPasSMS}
                        />
                      )}

                      {step === 3 ? (
                        <div></div>
                      ) : (
                        <div className="new-account mt-3">
                          <p className="">
                            Already have an account?{" "}
                            <Link className="text-primary" to="/login">
                              Sign in
                            </Link>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
