/** @format */

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "context/ThemeContext";
import { Path } from "utils/constant.data";

import MMLogo from "images/3MTT/main.png";
import { getLocalStorage } from "utils/secure";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);
  const user = getLocalStorage("3MTUSER") || null;

  return (
    <div className="nav-header">
      {user && (
        <Link
          to={
            user?.account_type === "PROVIDER"
              ? Path.PROVIDER_DASHBOARD
              : user?.account_type === "FELLOW"
              ? Path.FELLOW_DASHBOARD
              : Path.LOGIN
          }
          className="brand-logo jc"
        >
          <figure className="main-logo">
            <img src={MMLogo} alt="" />
          </figure>
        </Link>
      )}

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
