/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { API_TAGS } from "utils/strings";
import baseQueryWithReauth from "./baseQuery";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    profileKyb: builder.query({
      query: () => `provider/verify`,
      invalidatesTags: [API_TAGS.FETCH_PROVIDER_INFO],
    }),
    profileInfo: builder.query({
      query: () => `account/profile`,
      providesTags: [API_TAGS.FETCH_PROVIDER_INFO],
    }),
  }),
});

export const {
  // useProfileKybMutation,
  useProfileKybQuery,
  useProfileInfoQuery,
} = profileApi;
