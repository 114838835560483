/** @format */

import React, { useState } from "react";
import { toast } from "react-toastify";
import axios_instance from "store/constant/axiosInstance";
import { useDispatch } from "react-redux";
import { getProviderProfile } from "store/actions/profile";

const UploadContract = ({ setAcceptContract }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type !== "application/pdf") {
        toast.error("❌ Only PDF files are allowed. !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedFile(null);
      } else if (file.size > 2 * 1024 * 1024) {
        toast.error("❌ File size exceeds the 2MB limit.!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      if (selectedFile) {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append("file", selectedFile);

          await axios_instance.post("provider/contract", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          toast.success(`✔️ File uploaded successfully!`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
          dispatch(getProviderProfile()).then(() => {});
        } catch (error) {
          //   console.error("Error uploading file:", error.message);
          toast.error("❌ Error uploading file. !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">
            Upload you signed contract below
          </label>
          <input
            className="form-control"
            type="file"
            id="formFile"
            accept=".pdf"
            onChange={handleFileChange}
          />
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <button
            onClick={() => setAcceptContract(false)}
            className="btn btn-danger"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={!selectedFile || loading}
            className="btn btn-primary  accept-button"
          >
            {loading ? (
              <span
                role="status"
                aria-hidden="true"
                className="spinner-border spinner-border-sm ms-2"
              ></span>
            ) : (
              "Upload Document"
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default UploadContract;
