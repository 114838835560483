/** @format */

import React, { createContext, useState,useContext } from "react";

export const FeatureFlag = createContext(null);

export const FeatureFlagsProvider = ({ children }) => {
  const isNotProd = process.env.REACT_APP_ENVIRONMENT !== "production";
  const [features] = useState({
    hideForProd: isNotProd,
    hideForAll: false
  });

  return (
    <FeatureFlag.Provider value={{ features }}>
      {children}
    </FeatureFlag.Provider>
  );
};


export const useFlag = () => {
    const context = useContext(FeatureFlag);
    if (context === null) {
      throw new Error("useFlagmust be used within a FeatureFlagProvider");
    }
    return context;
  };
  
