/** @format */

import { useState } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { extractErrorMessage, UpdateFellowStatusOptions } from "utils/strings";

import { useBulkUpdateFellowStatusMutation } from "store/services/providerAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";

export const StatusUpdateModal = ({
  setAction,
  action,
  selectedIds,
  setSelectedIds,
}) => {
  const [selectedStatus, setStatus] = useState(null);
  const [bulkUpdateFellowStatus, { isLoading }] = useBulkUpdateFellowStatusMutation();

  const handleSubmit = async () => {
    await bulkUpdateFellowStatus({
      status: selectedStatus,
      fellows: [...selectedIds],
      
    })
      .unwrap()
      .then((data) => {
        Swal.fire({
          title: "Success",
          text: `Fellow status updated`,
          icon: "success",
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "ok",
        });
        setSelectedIds([]);
        setAction("");
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  return (
    <Modal
      show={action === "status_update" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Update Fellows Stats</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="course-dedails-bx">
              <div className="pt-0 d-flex flex-column">
              <Col md={12}>
                  <p className="p-1 m-0">Status</p>
                  <div className="form-group mb-3">
                    <select
                      defaultValue={"option"}
                      id="inputState"
                      className="form-control"
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      {UpdateFellowStatusOptions.map((_status) => {
                        return (
                          <option
                            value={_status?.value}
                          >
                            {_status?.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                    </Col>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              disabled={!selectedStatus || selectedStatus.length < 1}


              type="button"
              onClick={() => {
                Swal.fire({
                  title: "Confirm Action",
                  text: "Are you sure you want to proceed with this action?",
                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                  showCloseButton: true,
                  customClass: {
                    icon: "no-border",
                  },

                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: "Proceed",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleSubmit();
                  }
                });
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                `Update`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
