/** @format */

import React, { useState } from "react";
import * as Yup from "yup";
import { useSetUserPasswordMutation } from "store/services/authAPI";
import { useFormik } from "formik";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import Swal from "sweetalert2";

function RegisterSetPassword({ user, setStep, setUser }) {
  const [setUserPassword, { isLoading: authenticating }] =
    useSetUserPasswordMutation();
  const [isShown, setIsSHown] = useState(false);
  const [show, setShow] = useState(false);

  const handleSubmit = async (values) => {
    setUser({
      ...user,
      password: values?.password,
    });
    await setUserPassword({
      session_id: user?.session_id,
      password: values?.password,
    })
      .unwrap()
      .then((data) => {
        Swal.fire({
          text: data?.detail,
          showCloseButton: true,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            setStep(3);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text: error?.data?.message,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          cancelButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      username: user?.username,
      account_type: user?.account_type,
      session_id: user?.session_id,
      password: "",
      confirmPassword: "",
    },
    validationSchema: PasswordSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const toggleConfirm = () => {
    setShow((show) => !show);
  };

  return (
    <form className="form" onSubmit={formik.handleSubmit} autocomplete="off">
      <>
        <div className="form-group mb-3">
          <label className="mb-1 ">
            <strong>User Type</strong>
          </label>
          <input
            readOnly
            disabled
            style={{ background: "#D3D3D3" }}
            type="text"
            className="form-control"
            placeholder="Applied Learning Cluster"
            id="type"
            name="type"
            autocomplete="off"
            {...formik.getFieldProps("account_type")}
          />
        </div>

        <div className="form-group mb-3">
          <label className="mb-1 ">
            <strong>Username</strong>
          </label>
          <input
            readOnly
            disabled
            style={{ background: "#D3D3D3" }}
            type="text"
            className="form-control"
            placeholder="Test User"
            id="name"
            name="name"
            autocomplete="off"
            {...formik.getFieldProps("username")}
          />
        </div>

        <div className="mb-3 from-group">
          <label className="mb-1">
            <strong>Password</strong>
          </label>

          <div className="password__container">
            <input
              type={isShown ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter Password"
              className="form-control"
              {...formik.getFieldProps("password")}
            ></input>
            <button type="button" onClick={togglePassword}>
              <div class="icon">
                {isShown ? (
                  <i class="bi bi-eye"></i>
                ) : (
                  <i class="bi bi-eye-slash"></i>
                )}
              </div>
            </button>
          </div>

          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.password}
            </div>
          ) : null}
        </div>

        <div className="mb-3 from-group">
          <label className="mb-1">
            <strong>Confirm Password</strong>
          </label>

          <div className="password__container">
            <input
              type={show ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              className="form-control"
              placeholder="Confirm Password"
              {...formik.getFieldProps("confirmPassword")}
            ></input>
            <button type="button" onClick={toggleConfirm}>
              <div class="icon">
                {show ? (
                  <i class="bi bi-eye"></i>
                ) : (
                  <i class="bi bi-eye-slash"></i>
                )}
              </div>
            </button>
          </div>

          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.confirmPassword}
            </div>
          ) : null}
        </div>
      </>
      <div className="text-center">
        <button type="submit" className="btn btn-primary btn-block">
          {authenticating ? "Authenticating....." : "Complete Registration"}
        </button>
      </div>
    </form>
  );
}

export default RegisterSetPassword;

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm Password is Required"),
});
