/** @format */

import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { useVerifyUserOTPMutation } from "store/services/authAPI";
import { useFormik } from "formik";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import { useDispatch } from "react-redux";
import { login } from "store/reducers/AuthReducer";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CountdownTimer from "./timer";
import SMSTimer from "./SMSTimer";

function RegisterValidateOTP({
  user,
  resendOTP,
  authenticating,
  resendOTPasSMS,
  sendingViaSMS,
}) {
  const [verifyUserOTP, { isLoading }] = useVerifyUserOTPMutation();
  const [period] = useState(120);
  const dispatch = useDispatch();

  const [startTimer, setStartTimer] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(false);

  const [startSMSTimer, setStartSMSTimer] = useState(false);
  const [timeSMSElapsed, setSMSTimeElapsed] = useState(false);

  const handleSubmit = async (values) => {
    await verifyUserOTP({
      session_id: user?.session_id,
      otp: String(values?.otp),
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ Login Successful!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        dispatch(login(data));
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text: formattedError || "An error occurred",
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: OTPSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleTime = () => {
    setTimeElapsed(true);
    setStartTimer(false);
  };

  const handleSMSTime = () => {
    setSMSTimeElapsed(true);
    setStartSMSTimer(false);
  };

  return (
    <form
      className="comment-form"
      onSubmit={formik.handleSubmit}
      autocomplete="off"
    >
      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="form-group mb-3">
            <label htmlFor="otp" className="text-black font-w600">
              Enter the code sent to your email
              <span className="required">*</span>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              name="otp"
              id="otp"
              placeholder="Otp"
              {...formik.getFieldProps("otp")}
            />

            {formik.touched.otp && formik.errors.otp ? (
              <div className="text-danger mt-2 fs-12">{formik.errors.otp}</div>
            ) : null}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group mb-3">
            <input
              type="submit"
              disabled={isLoading}
              value={isLoading ? "Validating ...." : "Complete Registration"}
              className="submit btn btn-primary"
              name="Proceed"
            />
          </div>
        </div>

        <div className="col-12">
          <div className="new-account mt-3">
            <p className="">
              Didn't get OTP?{" "}
              {!startSMSTimer &&
                (startTimer ? (
                  timeElapsed ? null : (
                    <CountdownTimer
                      initialTime={period}
                      onTimeout={handleTime}
                    />
                  )
                ) : (
                  <span
                    role="button"
                    onClick={() => {
                      setTimeElapsed(false);
                      setStartTimer(true);
                      resendOTP();
                    }}
                    className="text-primary cursor-pointer"
                  >
                    {authenticating ? "Sending OTP..." : "Resend Email"}
                  </span>
                ))}
              {!startTimer &&
                (startSMSTimer ? (
                  timeSMSElapsed ? null : (
                    <SMSTimer initialTime={period} onTimeout={handleSMSTime} />
                  )
                ) : (
                  <Fragment>
                    &nbsp;or&nbsp;
                    <span
                      role="button"
                      onClick={() => {
                        setSMSTimeElapsed(false);
                        setStartSMSTimer(true);
                        resendOTPasSMS();
                      }}
                      className="text-primary cursor-pointer"
                    >
                      {sendingViaSMS ? "Sending via SMS ..." : "Get an SMS OTP"}
                    </span>
                  </Fragment>
                ))}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
}

export default RegisterValidateOTP;

const OTPSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});
