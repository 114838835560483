/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Modal } from "react-bootstrap";
import { CourseInterest, SkillLevels} from "utils/constant.data";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useUpdateFellowCourseMutation } from "store/services/adminAPI";
import Swal from "sweetalert2";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getProviderProfile } from "store/actions/profile";
import { Button } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import { mapLevel } from "utils/strings";

const SkillModal = ({ showCourses, setShowCourses, profile }) => {
  const dispatch = useDispatch();
  const [updateFellowCourse, { isLoading }] = useUpdateFellowCourseMutation();

  const handleSubmit = async (values) => {
    Swal.fire({
      title: "",
      iconHtml: `<i class="fa fa-info-circle text-greyy" aria-hidden="true"></i>`,
      text: "Are you sure you want to proceed? Course and Skill level cannot be changed once submitted.",
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      customClass: {
        icon: "no-border",
      },
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result?.isConfirmed) {
        await updateFellowCourse(values)
          .unwrap()
          .then((data) => {
            toast.success(`✔️ ${data?.detail} !`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            dispatch(getProviderProfile());
            setShowCourses(false);
          })
          .catch((error) => {
            const formattedError = extractErrorMessage(error)
            Swal.fire({
              title: error?.data?.code
                ? replaceUnderscoresWithSpaces(error?.data?.code)
                : "Error Occured",
              text: formattedError,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      course: profile ? profile?.current_learning_course : "",
      skill_level: profile ? profile?.technical_skill_level : "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });


  return (
    <Modal className="modal fade" show={showCourses}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Confirm Course</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowCourses(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          {/* <p>{JSON.stringify(formik.values)}</p> */}
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              {formik?.values?.skill_level &&
              mapLevel(formik?.values?.skill_level) !== "Beginner" ? (
                <div className="col-lg-12">
                  <Alert variant="info" className="alert-dismissible fade show">
                    <svg
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="me-2"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="16" x2="12" y2="12"></line>
                      <line x1="12" y1="8" x2="12.01" y2="8"></line>
                    </svg>
                    Please note that you will be required to take a short test
                    to assess this skill level.
                  </Alert>
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Course</strong>
                  </label>

                  <select
                    id="course"
                    name="course"
                    className="form-control"
                    {...formik.getFieldProps("course")}
                  >
                    {CourseInterest.map((_course) => {
                      return (
                        <option key={_course} value={_course}>
                          {_course}
                        </option>
                      );
                    })}
                  </select>

                  {formik.touched.course && formik.errors.course ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.course}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Skill Level</strong>
                  </label>

                  <select
                    id="skill_level"
                    name="skill_level"
                    className="form-control"
                    {...formik.getFieldProps("skill_level")}
                  >
                    {SkillLevels.map((_level) => {
                      return (
                        <option key={_level} value={_level}>
                          {_level}
                        </option>
                      );
                    })}
                  </select>

                  {formik.touched.skill_level && formik.errors.skill_level ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.skill_level}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      " Confirm"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default SkillModal;

const FormSchema = Yup.object().shape({
  skill_level: Yup.string().required("Skill level is required"),
  course: Yup.string().required("Course is required"),
});
