/** @format */

import React, { Fragment, useEffect, useState } from "react";
import ProviderLayout from "views/providers/Provider";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import {
  // useGetCourseModuleQuery,
  useGetProviderCourseModuleQuery,
} from "store/services/providerAPI";
// import ModuleCard from ".components/ModuleCard";
import CardModule from "./components/CardModule";
import useApprovedProvider from "hooks/useApprovedProvider";

const ProviderCourses = () => {
  useApprovedProvider();
  const urlParams = useParams();
  const [fetch, setFetch] = useState(false);

  // const {
  //   data: course,
  //   isLoading: fetchingModules,
  //   isFetching,
  //   isSuccess,
  // } = useGetCourseModuleQuery(urlParams?.course, {
  //   skip: fetch,
  // });

  const {
    data: courses,
    isLoading: fetchingModules,
    isFetching,
    isSuccess,
  } = useGetProviderCourseModuleQuery("", {
    skip: fetch,
  });

  useEffect(() => {
    if (urlParams?.course) {
      setFetch(false);
    }
  }, [urlParams]);

  useEffect(() => {
    if (isSuccess) {
      setFetch(true);
    }
  }, [isSuccess]);


  return (
    <ProviderLayout>
      <div className="row">
        {fetchingModules || isFetching ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : courses?.data?.results.length > 0 ? (
          <Fragment>
            <section>
              <h2>All Modules</h2>

              <Row className="row be">
                <Col xl="12" className="course-modules">
                  <Card>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                      <Card.Title>Course Modules</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {/* <Accordion
                        className="accordion accordion-active-header"
                        defaultActiveKey="0"
                      >
                        {course?.data?.course?.modules?.map((module, index) => {
                          return (
                            <ModuleCard
                              module={module}
                              key={module?.id}
                              eventKey={module?.id}
                              setFetch={setFetch}
                            />
                          );
                        })}
                      </Accordion> */}
                      <div className="row">
                        {courses?.data?.results
                          .filter((_) => _?.status === "active")
                          .map((data) => (
                            <CardModule data={data} key={data?.id} />
                          ))}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </section>
          </Fragment>
        ) : (
          <div className="card">
            <div className="card-body text-center ai-icon">
              <BagIcon />
              <h4 className="my-2">No Course Modules</h4>
              <p>You have not created any module at the moment</p>
              <button className="btn my-2 btn-primary btn-lg px-4">
                Create Module
              </button>
            </div>
          </div>
        )}
      </div>
    </ProviderLayout>
  );
};
export default ProviderCourses;
