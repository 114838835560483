/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { Fragment } from "react";
import { FadeLoader } from "react-spinners";
import { Row, Col, Card } from "react-bootstrap";
import FellowLayout from "../../Fellow";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { useGetFellowPerksQuery } from "store/services/adminAPI";

const FellowPerks = () => {
  const { data: perks, isLoading: fetchingPerks } = useGetFellowPerksQuery();

  return (
    <FellowLayout>
      {fetchingPerks ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : perks?.data?.length > 0 ? (
        <Fragment>
          <div className="row">
            <Row>
              {perks?.data?.map((_perk) => {
                return (
                  <Col key={_perk?.id} xl="4">
                    <Card className="perk-card">
                      <Card.Header>
                        <figure>
                          <img
                            className="card-img-top img-fluid"
                            src={_perk?.logo}
                            alt="Card cap"
                          />
                        </figure>
                      </Card.Header>

                      <Card.Body>
                        <Card.Title>{_perk?.name}</Card.Title>
                        <Card.Text>{_perk?.description}</Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <Card.Text className="d-inline">
                          {_perk?.value}
                        </Card.Text>
                        <a
                          href={_perk?.offer_link}
                          rel="noreferrer"
                          target="_blank"
                          className="card-link float-end pe-auto btn btn-outline"
                        >
                          Get Perk
                        </a>
                      </Card.Footer>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Fragment>
      ) : (
        <div className="card">
          <div className="card-body text-center ai-icon">
            <BagIcon />
            <h4 className="my-2">No 3MTT perks yet</h4>
          </div>
        </div>
      )}
    </FellowLayout>
  );
};

export default FellowPerks;
