/** @format */

import React, { Fragment, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { getProviderProfile } from "store/actions/profile";
import { Col, Tab } from "react-bootstrap";
import FellowLayout from "../../Fellow";
import { Link } from "react-router-dom";
import EmptyNotice from "component/ErrorNotice";
import course1 from "images/courses/course1.jpg";
import { courses } from "utils/strings";
import "./fonts/Lato-Bold-bold";
import "./fonts/LavishlyYours-Regular-bold";
import useCommunity from "hooks/useCommunity";

const FoundationalCourse = () => {
  const dispatch = useDispatch();

  useCommunity();

  useEffect(() => {
    dispatch(getProviderProfile());
  }, [dispatch]);

  const { profileInfo, isLoading } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;

  

  const tabData = [
    {
      name: "Online Learning Content",
      title: "online",
    },
    {
      name: "Applied Learning Content",
      title: "applied",
    },
  ];

  return (
    <Fragment>
      <FellowLayout>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : profile ? (
          <>
            <div className="widget-heading d-flex justify-content-between align-items-center mb-3">
              <div>
                <h3 className="m-0"> Foundational Courses</h3>
              </div>

              <div className="d-flex align-items-center gap-x-4">
                <span class="badge badge-pill badge-info badge-lg">
                  {/* {mapLevel(profile?.technical_skill_level)} */}
                </span>
              </div>
            </div>

            <div className="row">
              <Col xl={12}>
                <section className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={tabData[0].name.toLowerCase()}
                  >
                    <>
                      <p>
                        Welcome! Foundational courses are a prerequisite for the
                        3MTT Programme. Click the links below to access your
                        foundational courses. If you complete all Mandatory
                        courses and a Career Essential in your chosen track, you
                        can be fast tracked to the next 3MTT cohort.&nbsp;
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSfI-xnav8KlAyRDGisUjJFoRNpypAgyPFgRtv4y_X2kppnD9g/viewform"
                          className="text-primary"
                          target="blank"
                        >
                          Make sure to fill out this form 
                        </a>
                        after completing your introductory courses to be
                        eligible to join our waitlist.
                      </p>
                      {courses && (
                        <div className="row">
                          {courses?.length > 0 ? (
                            courses?.map((data, index) => (
                              <div className="col-xl-4 col-md-6" key={index}>
                                <div className="card all-crs-wid">
                                  <div className="card-body">
                                    <div className="courses-bx">
                                      <div className="dlab-media">
                                        <img
                                          src={
                                            data?.image ? data?.image : course1
                                          }
                                          alt=""
                                          className="contain-img"
                                        />
                                      </div>
                                      <div className="dlab-info">
                                        <div className="dlab-title d-flex justify-content-between">
                                          <div>
                                            <h4>
                                              <Link
                                                to={data?.link}
                                                target="_blank"
                                              >
                                                {data?.name}
                                              </Link>
                                            </h4>
                                            <p className="m-0">
                                              {/* {data?.subtitle} */}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between content align-items-center">
                                          <span></span>
                                          <Link
                                            to={data?.link}
                                            target="_blank"
                                            className="btn btn-primary btn-sm"
                                          >
                                            Go to Course
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="bg-white py-5 px-5 text-center">
                              <EmptyNotice text="No content at the moment" />
                              <p className="">
                                No foundational courses at the moment
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  </Tab.Container>
                </section>
              </Col>
            </div>
          </>
        ) : null}
      </FellowLayout>
    </Fragment>
  );
};

export default FoundationalCourse;
