/** @format */

import { ReactComponent as BagIcon } from "images/svg/bag.svg";

const EmptyNotice = ({ text }) => {
  return (
    <section className="d-flex flex-column">
      <div className="d-flex justify-content-center">
        <BagIcon />
      </div>
      <div className="d-flex justify-content-center">
        <p>{text}</p>
      </div>
    </section>
  );
};

export default EmptyNotice;
