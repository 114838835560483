/** @format */

import React from "react";
import ProviderLayout from "views/providers/Provider";
import { useGetAllFellowsQuery } from "store/services/adminAPI";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";

import useApprovedProvider from "hooks/useApprovedProvider";

const ProviderFellowsCohorts = () => {
  useApprovedProvider();

  const navigate = useNavigate();
  const { data, isLoading: fetchingFellows } = useGetAllFellowsQuery("taiwo");

  const pathName = window.location.pathname.split("/");
  const currentCourse = pathName[3];

  return (
    <ProviderLayout>
      <div className="row">
        {fetchingFellows ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : data?.data?.length > 0 ? (
          <Col xl="12" className="course-modules">
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Course Cohorts</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="accordion-header  accordion-header-primary">
                  <div className="course__cohort">
                    <h4> Cohort 1</h4>
                    <button
                      className="btn btn-outline mr-2"
                      onClick={() =>
                        navigate(
                          `${Path.PROVIDER_FELLOWS}${currentCourse}/cohorts/cohort1`
                        )
                      }
                    >
                      View Fellows
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <div className="card">
            <div className="card-body text-center ai-icon">
              <BagIcon />
              <h4 className="my-2">No Cohorts yet</h4>
            </div>
          </div>
        )}
      </div>
    </ProviderLayout>
  );
};
export default ProviderFellowsCohorts;
