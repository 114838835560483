/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

const PromptModal = ({ showPrompt, setShowPrompt }) => {
  return (
    <Modal className="modal fade" show={showPrompt}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">General Notice</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setShowPrompt(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="d-flex flex-column">
            <p>
              Welcome to your 3MTT dashboard. Complete the steps
              highlighted on the dashboard to start your learning adventure.
            </p>

            <div className="col-lg-12  w-100 d-flex justify-content-center">
              <div className="form-group mb-3">
                <button
                  type="button"
                  onClick={() => setShowPrompt(false)}
                  className="submit btn btn-pigment-green"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PromptModal;
