/** @format */

import React, { useState } from "react";
import MMLogo from "images/3MTT/3MM.png";
import { Link } from "react-router-dom";
import { Path } from "utils/constant.data";
import { FadeLoader } from "react-spinners";
import { Col, Tab, Nav } from "react-bootstrap";
import JobsTab from "./containers/JobsTab";
import GigsTab from "./containers/GigsTab";
import { useGetAllJobsQuery } from "store/services/adminAPI";

const PublicJobs = () => {
  const [activeTab, setActive] = useState("jobs");
  const { data, isLoading: fetchingJobs } = useGetAllJobsQuery();

  const tabData = [
    {
      name: "Jobs",
      title: "jobs",
    },
    {
      name: "Gigs",
      title: "gigs",
    },
  ];

  const jobs = data?.data?.results ?? [];

  return (
    <section className="external__table p-4">
      <div className="d-flex justify-content-center align-items-center logo__holder">
        <div className="text-center mm__logo mb-2">
          <Link to={Path.LOGIN}>
            <img src={MMLogo} alt="FMCIDE logo" />
          </Link>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center logo__holder mb">
        <div className="text-center">
          <h3>3MTT JOBS AND GIGS</h3>
        </div>
        <div className="text-center">
          <p>
            Discover a variety of job opportunities and freelance gigs that
            match your skills and interests. Whether you're looking for
            full-time positions or short-term projects, we've got you covered!
          </p>
        </div>
      </div>

      {fetchingJobs ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <div className="row justify-content-center">
          <Col xl={9}>
            <section className="custom-tab-1">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="nav-tabs">
                  {tabData.map((data) => (
                    <Nav.Item
                      as="li"
                      key={data.title}
                      onClick={() => setActive(data.title)}
                    >
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>

                {activeTab === "jobs" && <JobsTab jobs={jobs} />}
                {activeTab === "gigs" && <GigsTab jobs={jobs} />}
              </Tab.Container>
            </section>
          </Col>
        </div>
      )}
    </section>
  );
};

export default PublicJobs;
