import { Colors } from "utils/strings";

  const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? Colors.polyGreen : Colors.pigmentGreen,
  });

  const valueContainer = (base, state) => ({
    ...base,
    border: "0",
    boxShadow: "none",
  });

  const control = (base, state) => ({
    ...base,
    border: "1px solid #DBDBDB",
    padding: "0.375rem 0.75rem",
    boxShadow: "none",
    '&:hover': {
        border: "1px solid #DBDBDB"
     }
 
  });

  const multiValue = (base, state) => ({
    ...base,
    color: state.isFocused ? Colors.polyGreen : Colors.pigmentGreen,
  });

  const multiValueLabel = (base, state) => ({
    ...base,
    color: state.isFocused ? Colors.polyGreen : Colors.pigmentGreen,
  });

  const indicatorSeparator = (base, state) => ({
    ...base,
    backgroundColor: "transparent",
  });

  export const Style = {
    control: control,
    container: valueContainer,
    clearIndicator: ClearIndicatorStyles,
    multiValue: multiValue,
    multiValueLabel: multiValueLabel,
    indicatorSeparator: indicatorSeparator,
  };