/** @format */

import { Navigate, Outlet } from "react-router-dom";
import { Path } from "utils/constant.data";

export const ProviderRoute = ({ user }) => {
  let userRole = user?.account_type;

  return user && userRole === "PROVIDER" ? (
    <Outlet />
  ) : (
    <Navigate to={Path.LOGIN} replace />
  );
};

export const FellowRoute = ({ user }) => {
  let userRole = user?.account_type;

  return user && userRole === "FELLOW" ? (
    <Outlet />
  ) : (
    <Navigate to={Path.LOGIN} replace />
  );
};

export const CommunityRoute = ({ user }) => {
  let userRole = user?.account_type;

  return user && userRole === "COMMUNITY" ? (
    <Outlet />
  ) : (
    <Navigate to={Path.LOGIN} replace />
  );
};

export const GuestRoute = ({ user }) => {
  let userRole = user?.account_type;

  return user ? (
    <Navigate
      to={
        userRole === "FELLOW"
          ? Path.FELLOW_DASHBOARD
          : userRole === "PROVIDER"
          ? Path.PROVIDER_DASHBOARD
          : Path.LOGIN
      }
      replace
    />
  ) : (
    <Outlet />
  );
};
