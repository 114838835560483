/** @format */

import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { capitalize } from "utils/strings";

const ProfileContent = ({ member }) => {
  const [activeToggle, setActiveToggle] = useState("aboutMe");

  const data = member?.data?.profile;

  return (
    <Fragment>
      {data && (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <ul className="nav nav-tabs">
                      <li
                        className="nav-item"
                        onClick={() => setActiveToggle("aboutMe")}
                      >
                        <Link
                          to="#about-me"
                          data-toggle="tab"
                          className={`nav-link text-pigment-green ${
                            activeToggle === "aboutMe" ? "active show" : ""
                          } text-sm`}
                        >
                          Member Information
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        id="about-me"
                        className={`tab-pane fade ${
                          activeToggle === "aboutMe" ? "active show" : ""
                        }`}
                      >
                        <div className="profile-about-me">
                          <div className="pt-4 border-bottom-1 pb-3"></div>
                        </div>


                        <div className="profile-personal-info">
                          <h4 className="text-primary mb-4">
                            Personal Information
                          </h4>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                {" "}
                                Name<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>
                                {capitalize(data?.first_name)}{" "}
                                {capitalize(data?.last_name)}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Email<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(member?.data?.email)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Address<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.address)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Age<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.age)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Course<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>
                                {capitalize(data?.fellow_course?.title)}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Employment Status
                                <span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.employment_status)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Gender<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.gender)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Education Level
                                <span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>
                                {capitalize(data?.level_of_education)}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Phone<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.phone)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                State of Residence
                                <span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.residence)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                State of Origin
                                <span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>{capitalize(data?.state_of_origin)}</span>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-3">
                              <h5 className="f-w-500">
                                Skill Level<span className="pull-right">:</span>
                              </h5>
                            </div>
                            <div className="col-9">
                              <span>
                                {capitalize(data?.technical_skill_level)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProfileContent;
