import { useState } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import { extractErrorMessage, FellowProficiencyLevel, FellowProficiencyRating } from "utils/strings";
import { useSetFellowProficiencyMutation } from "store/services/adminAPI";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import Select from "react-select";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";

const RankFellowModal = ({ showRank, closeRankModal, fellowID }) => {
  const [selectedLevel, setLevel] = useState(null);
  const [selectedRank, setRank] = useState(null);

  const [setProficiency, { isLoading }] = useSetFellowProficiencyMutation();

  const handleSubmit = async (values) => {
    await setProficiency({
        id: fellowID,
        ...values
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${data?.message} !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        closeRankModal();
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) ||
            "Error Occurred",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      proficiency_level: "", 
      proficiency_rating: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  return (
    <div>
      <Modal
        show={showRank}
        onHide={closeRankModal}
        className="modal fade"
        id="statusModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title"> Rank Fellow</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => closeRankModal()}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={formik.handleSubmit}>
                <Col md={12}>
                  <p className="p-1 m-0">Proficiency level</p>
                  <div className="form-group mb-3">
                  <Select
                    value={selectedLevel}
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : "";
                        formik.setFieldValue("proficiency_level", selectedValue);
                        setLevel(selectedOption);
                    }}
                    options={FellowProficiencyLevel}
                />
                    {formik.errors.proficiency_level &&
                      formik.touched.proficiency_level && (
                        <div className="text-danger">
                          {formik.errors.proficiency_level}
                        </div>
                      )}
                  </div>
                </Col>
                <Col md={12}>
                  <p className="p-1 m-0">Rank</p>
                  <div className="form-group mb-3">
                  <Select
                    value={selectedRank}
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : "";
                        formik.setFieldValue("proficiency_rating", selectedValue);
                        setRank(selectedOption);
                    }}
                    options={FellowProficiencyRating}
                    />
                    {formik.errors.proficiency_rating &&
                      formik.touched.proficiency_rating && (
                        <div className="text-danger">
                          {formik.errors.proficiency_rating}
                        </div>
                      )}
                  </div>
                </Col>
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <button
                    type="submit"
                    className={`ms-2 pull-right py-3 btn ${
                      isLoading ? "btn-secondary" : "btn-pigment-green"
                    }`}
                    disabled={!formik.isValid || isLoading}
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RankFellowModal;

const FormSchema = Yup.object().shape({
  proficiency_level: Yup.string().required("Proficiency level is required"), 
  proficiency_rating: Yup.number().required("Proficiency rating is required"), 
});
