/** @format */

import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";

import { COLUMNS } from "./helpers/Columns";
import { SubmissionModal } from "./SubmissionModal";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";


const SupportTicketsTable = ({
  tickets,
  metadata,
  isLoading,
}) => {
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)

  const [data, setData] = useState([]);


  useEffect(() => {
    setData(tickets)
  }, [isLoading, tickets]);

  const columns = useMemo(
    () => COLUMNS(),[]
  );

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div>
      <SubmissionModal
        setShowSubmissionModal={setShowSubmissionModal}
        handleCloseSubmissionModal={()=>setShowSubmissionModal(false)}
        showSubmissionModal={showSubmissionModal}
      />

      <div className={`card ${isLoading ? "disabled" : ""}`}>
        <div className={`card-header ${isLoading ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title">Support Tickets</h4>
          </div>
          
          <div className="d-flex  align-items-center"> 
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={()=>setShowSubmissionModal(true)}
            >
              Report an Issue
            </button>
          </div>       
        </div>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {tickets?.length > 0 ? (
              <>
                <div className="table-responsive">
                  <div className="dataTables_wrapper">
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    
                  </div>
                </div>

              </>
            ) : (
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">
                    You haven't raised any issues yet.
                  </h4>
                </div>
             )}
          </div>
        )}
      </div>
    </div>
  );
};
export default SupportTicketsTable;
