import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTime, onTimeout }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time <= 0) {
      // Call the onTimeout function when the timer reaches zero
      onTimeout();
    } else {
      // Set up a timer to decrement the time every second
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      // Clean up the timer when the component unmounts
      return () => clearInterval(timer);
    }
  }, [time, onTimeout]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}m:${String(remainingSeconds).padStart(2, '0')}s`;
  };

  return (
      <h2>{formatTime(time)}</h2>
  );
};

export default CountdownTimer;
