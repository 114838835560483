/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { level_five } from "utils/constant.data";
import { countWords } from "utils/strings";

const ProfileLevelFive = ({ profile, formik, validateFormStep, setStep, updating, handleSubmit }) => {
  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe the physical infrastructure available for in-person
            training delivery, including space, equipment, and work tools. If
            utilising a partner organisation’s infrastructure, provide the name
            and address of the partner organisation, and describe the available
            infrastructure. Also include the maximum trainee capacity per
            session for in-person training at your facility or the partner
            organisation's facility. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.infrastructure_physical_proof : ""}
          id="infrastructure_physical_proof"
          name="infrastructure_physical_proof"
          {...formik.getFieldProps("infrastructure_physical_proof")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.infrastructure_physical_proof ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.infrastructure_physical_proof}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.infrastructure_physical_proof
              ? `${countWords(
                  formik?.values.infrastructure_physical_proof
                )}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Describe the technological infrastructure available for online
            training delivery (blended learning models), including platforms,
            software, and any additional systems your organisation has in place
            for training delivery outside the classroom. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.infrastructure_technological_proof : ""}
          id="infrastructure_technological_proof"
          name="infrastructure_technological_proof"
          {...formik.getFieldProps("infrastructure_technological_proof")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.infrastructure_technological_proof ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.infrastructure_technological_proof}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.infrastructure_technological_proof
              ? `${countWords(
                  formik?.values.infrastructure_technological_proof
                )}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          className="ms-2 me-2 py-3 btn-pigment-green-outline "
          disabled={updating}
          style={{ width: "100% !important" }}
          type="button"
          onClick={() => {
            setStep(4);
          }}
        >
          Previous
        </Button>

        <Button
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
          onClick={() => {
            const L5_ERROR = validateFormStep(level_five);
            if (L5_ERROR) {
              return
            }
            handleSubmit(formik.values, level_five, 5)
            }
          }
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Save"
          )}
        </Button>

        <Button
          onClick={() => {
            const L5_ERROR = validateFormStep(level_five);
            if (!L5_ERROR) {
              setStep(6);
            }
          }}
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelFive;
