/** @format */

import { useState } from "react";
import { Modal, Button, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import dayjs from "dayjs";
import { isAfter, isBefore, isSameDay } from "date-fns";
import Swal from "sweetalert2";

import { useBulkMarkAttendanceMutation } from "store/services/providerAPI";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";

export const AttendanceModal = ({
  setAction,
  action,
  selectedIds,
  cohorts,
  setSelectedIds,
}) => {
  const [selectedCohort, setCohorts] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [bulkMarkAttendance, { isLoading }] = useBulkMarkAttendanceMutation();

  const handleSubmit = async () => {
    await bulkMarkAttendance({
      schedule_id: schedule?.id,
      status: "present",
      fellows: [...selectedIds],
      confirmation_page_link:
        "https://app.3mtt.training/fellow/confirm-attendance",
    })
      .unwrap()
      .then((data) => {
        Swal.fire({
          title: "Success",
          text: `${
            schedule?.label
          } Attendance has now been completed for ${
            selectedIds?.length
          } Fellow${selectedIds?.length > 1 ? "s" : ""}`,
          // iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          icon: "success",
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "ok",
        });
        setSelectedIds([]);
        setAction("");
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const CohortOptions =
    cohorts?.length > 0
      ? cohorts?.map((cohort) => ({
          value: cohort?.id,
          label: `Cohort ${cohort?.rank}`,
        }))
      : [];

  return (
    <Modal
      show={action === "mark_attendance" ? true : false}
      onHide={() => setAction("")}
      className="modal fade"
      id="statusModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Mark Fellows Attendance</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setAction("")}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <div className="col-xl-12">
            <div className="course-dedails-bx">
              <div className="pt-0 d-flex flex-column">
                {CohortOptions?.length > 0 && (
                  <Col md={12}>
                    <p className="p-1 m-0">Choose Cohort</p>
                    <div className="form-group mb-3">
                      <Select
                        value={selectedCohort}
                        onChange={setCohorts}
                        options={CohortOptions}
                        styles={Style}
                      />
                    </div>
                  </Col>
                )}

                {selectedCohort && (
                  <Col md={12}>
                    <p className="p-1 m-0">Weeks</p>
                    <div className="form-group mb-3">
                      {cohorts
                        ?.filter(
                          (_cohort) => _cohort?.id === selectedCohort?.value
                        )[0]
                        ?.schedules?.map((_sche) => {
                          const startDate = new Date(_sche?.start_date);
                          const endDate = new Date(_sche?.end_date);
                          const currentDate = new Date();

                          const isEligible =
                            isAfter(currentDate, startDate) &&
                            (isBefore(currentDate, endDate) ||
                              isSameDay(currentDate, endDate));

                          return (
                            <div
                              className={`d-flex align-items-center justify-content-between weeks ml-2 mb-2 ${
                                isEligible ? "" : "disabled"
                              }`}
                            >
                              <p className="mr-2">
                                Week {_sche?.week_number} (
                                {dayjs(_sche?.start_date).format(
                                  "MMMM D, YYYY"
                                )}
                                {" - "}
                                {dayjs(_sche?.end_date).format("MMMM D, YYYY")})
                              </p>
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    schedule?.id === _sche?.id ? true : false
                                  }
                                  onChange={() => {
                                    schedule?.id === _sche?.id
                                      ? setSchedule(null)
                                      : setSchedule(_sche);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center align-items-center">
            <Button
              variant="primary"
              disabled={
                selectedIds.length > 0 && selectedCohort && schedule
                  ? false
                  : true
              }
              type="button"
              onClick={() => {
                Swal.fire({
                  title: "Confirm Action",
                  text: "Are you sure you want to proceed with this action?",
                  iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                  showCloseButton: true,
                  customClass: {
                    icon: "no-border",
                  },

                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: "Proceed",
                  cancelButtonText: "Cancel",
                }).then((result) => {
                  if (result.isConfirmed) {
                    handleSubmit();
                  }
                });
              }}
            >
              {isLoading ? (
                <span
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm ms-2"
                ></span>
              ) : (
                ` Mark`
              )}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
