/** @format */

import React, { useEffect, useState } from "react";
import { useGetSelectedFellowsQuery } from "store/services/adminAPI";
import SelectedFellowsTable from "./Table/SelectedFellowsTable";
import { Modal } from "react-bootstrap";

import MMLogo from "images/3MTT/3MM.png";
import { Link } from "react-router-dom";
import { Path } from "utils/constant.data";

const SelectedFellows = () => {
  const [skip, setSkip] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [filter, setFilter] = useState("page=1&cohort_id=3330e7dc-75c5-452d-ace9-8a4acfaef48d");


  const {
    data,
    isLoading: fetchingFellows,
    isFetching,
  } = useGetSelectedFellowsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);

  return (
    <>
      <Modal className="fade" show={welcome} size="lg">
        <Modal.Header>
          <Modal.Title>
            <h2>Guidelines</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Some information to note as you navigate our webpage of selected
            fellows:
          </h5>
          <p>
            1. For data privacy reasons, only intials were provided for
            firstname. Kindly search the list with your last name for optimal
            results.
          </p>
          <p>
            2. If you can’t find your name on the list, kindly login to see your
            status on your dashboard.
          </p>
          <p>
            3. If you received a selection email and cannot find your name or
            your status is not updated to “Selected” on your dashboard, please
            send an email to support@3mtt.training
          </p>
          <p>
            4. Courses in each state are based on the selected training
            providers for this phase. Hence, not all states have fellows for all
            courses.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setWelcome(false);
            }}
            className="btn btn-pigment-green"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <section className="external__table">
        <div className="d-flex justify-content-center align -items-center logo__holder">
          <div className="text-center mm__logo mb-2">
            <Link to={Path.LOGIN}>
              <img src={MMLogo} alt="" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align -items-center logo__holder mb">
          <div className="text-center">
            <h3>SELECTED FELLOWS 1%-10% COHORT 1,2,3,4 </h3>
          </div>
          <div className="text-center">
            <p>
              This represents the 1%-10% of the overall target, inline with the
              Ministry’s 1%-10%-100% implementation approach.
            </p>

            <p>
              Once you find your name on the list,{" "}
              <Link to={Path.LOGIN} className="text-primary">
                login to your 3MTT portal
              </Link>
              .{" "}
            </p>
            <button className="btn btn-text" onClick={() => setWelcome(true)}>
              Review the guidelines as you explore the list of selected fellows.
            </button>
          </div>
        </div>
        <div className="d-flex h-100 minvh">
          <div className="container h-100">
            <div className="row">
              <>
                <SelectedFellowsTable
                  fellows={data}
                  setFilter={setFilter}
                  setSkip={setSkip}
                  filtering={fetchingFellows || isFetching}
                  filter={filter}
                />
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectedFellows;
