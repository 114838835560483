/** @format */

import React, { Fragment } from "react";
import ProviderLayout from "../../Provider";

import ProfileHeader from "./components/ProfileHeader";
import ProfileContent from "./components/ProfileContent";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { FadeLoader } from "react-spinners";


const ProviderProfile = () => {
  const { profileInfo, isLoading } = useSelector(profileSelector);

  return (
    <ProviderLayout>
      <Fragment>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <Fragment>
            <ProfileHeader profileInfo={profileInfo} />
            <ProfileContent />
          </Fragment>
        )}
      </Fragment>
    </ProviderLayout>
  );
};

export default ProviderProfile;
