/** @format */

import { useEffect } from "react";
import { Path } from "utils/constant.data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";

const useApprovedProvider = () => {
  const navigate = useNavigate();
  const { profileInfo } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;

  useEffect(() => {
    if (profile) {
      const isProfileValid =
        profile?.status?.toLowerCase() === "selected"
      if (!isProfileValid) {
        navigate(Path.PROVIDER_DASHBOARD);
      }
    }
  }, [navigate, profile]);
};

export default useApprovedProvider;
