/** @format */

import React, { useState, useMemo, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { capitalize, truncateString } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import FilterModal from "./components/ReportFilterModal";
import { extractFilterValues } from "utils/functions";
import { FadeLoader } from "react-spinners";

const AttendaceReportTable = ({
  messages,
  metadata,
  setFilter,
  setSkip,
  messageClass,
  filtering,
}) => {
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [clear, setClear] = useState(false);
  const [modalFilter, setModalFilter] = useState(null);

  useEffect(() => {
    setSkip(true);
  }, [messages, setSkip]);

  const makeFilterString = (_modalFilter, _page) => {
    const queryString = `page=${_page}&page_size=${metadata?.page_size}${
      _modalFilter?.cohort_id ? `&cohort_rank=${_modalFilter?.cohort_id}` : ""
    }${
      _modalFilter?.sessions?.length > 0
        ? `&sessions=${_modalFilter?.sessions?.join(",")}`
        : ""
    }${
      _modalFilter?.week_numbers?.length > 0
        ? `&weeks=${_modalFilter?.week_numbers?.join(",")}`
        : ""
    } `;
    // console.log('queryString;',queryString)
    return queryString;
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const values = extractFilterValues(modalFilter);
    console.log("values", values);
    const filterString = makeFilterString(values, _page);
    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);
  };

  useEffect(() => {
    if (modalFilter) {
      setSearch("");
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Custome Provider Id",
      accessor: "custom_provider_id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(
          truncateString(cell?.row?.original?.custom_provider_id)
        )}`;
      },
    },
    {
      Header: "Fellow ID",
      accessor: "fellow_id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(truncateString(cell?.row?.original?.fellow_id))}`;
      },
    },
    {
      Header: "Full Name",
      accessor: "full_name",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(truncateString(cell?.row?.original?.full_name))}`;
      },
    },
    {
      Header: "Provider Name",
      accessor: "provider_name",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(
          truncateString(cell?.row?.original?.provider_name)
        )}`;
      },
    },

    {
      Header: "Total Sessions",
      accessor: "total_sessions",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return cell?.row?.original?.total_sessions;
      },
    },

    {
      Header: "Attendance",
      accessor: "attendance_percentage",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${Math.ceil(cell?.row?.original?.attendance_percentage || 0)}%`;
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => messages, [messages]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const restoreFellows = () => {
    setFiltered(false);
    setSearch("");
    setFilter(`page=1&page_size=${metadata?.page_size}`);
    setModalFilter(null);
    setSearched(false);
    setClear(!clear);
  };

  return (
    <>
      <FilterModal
        setFilter={setModalFilter}
        setShowFilter={setShowFilter}
        showModal={showFilter}
        filtering={filtering}
        restoreFellows={restoreFellows}
        clearFilter={clear}
      />

      <div className="card">
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title text-pigment-green">All Reports</h4>

            {(filtered || searched) && (
              <button
                onClick={() => {
                  restoreFellows();
                  // setExportFilter("");
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <input
              className="ml-2 input-search form-control w-100"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by fellow ID"
            />

            {searched ? (
              <button
                type="button"
                className="btn btn-outline ml"
                onClick={() => {
                  restoreFellows();
                }}
              >
                Clear
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline ml"
                onClick={() => {
                  if (search?.length > 0) {
                    setClear(!clear);
                    setFilter(
                      `page=1&page_size=${metadata?.page_size}&fellow_id=${search}`
                    );
                    setFiltered(false);
                    setSearched(true);
                  }
                }}
              >
                Search
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary ml"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>

        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        const modalFilterString = modalFilter
                          ? makeFilterString(modalFilter, _newPage)
                          : "";

                        modalFilter
                          ? setFilter(modalFilterString)
                          : setFilter(
                              `page=${_newPage}&page_size=${metadata?.page_size}`
                            );
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;

                        const modalFilterString = modalFilter
                          ? makeFilterString(modalFilter, _newPage)
                          : "";

                        modalFilter
                          ? setFilter(modalFilterString)
                          : setFilter(
                              `page=${_newPage}&page_size=${metadata?.page_size}`
                            );
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">No fellows for the specified filter</h4>
                  <button
                    onClick={() => restoreFellows()}
                    className="btn btn-primary"
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AttendaceReportTable;
