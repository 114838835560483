/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FadeLoader } from "react-spinners";
import { profileSelector } from "store/reducers/ProfileReducer";
import { Row } from "react-bootstrap";
import FellowLayout from "../../Fellow";
import CommunityEvent from "./components/CommunityEvent";
import { useNavigate } from "react-router-dom";
import { getProviderProfile } from "store/actions/profile";
import { Path } from "utils/constant.data";

const FellowEvents = () => {
  const dispatch = useDispatch();
  const { profileInfo, isLoading } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProviderProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      if (
        profile?.status?.toLowerCase() === "selected" ||
        profile?.status?.toLowerCase() === "application_completed" ||
        profile?.status?.toLowerCase() === "certified"
      ) {
        // eslint-disable-next-line no-unused-vars
        let r = true;
      } else {
        navigate(Path.FELLOW_DASHBOARD);
      }
    }
  }, [navigate, profile]);

  return (
    <FellowLayout>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <Fragment>
          <div className="row community-container">
            <Row>
              <CommunityEvent />
            </Row>
          </div>
        </Fragment>
      )}
    </FellowLayout>
  );
};

export default FellowEvents;
