/** @format */

import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as SendIcon } from "images/svg/send.svg";
import FellowLayout from "views/fellows/Fellow";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useParams, useLocation } from "react-router-dom";
import { useGetFellowMessagesQuery } from "store/services/providerAPI";
import { FadeLoader } from "react-spinners";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { formatDate } from "utils/date";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { capitalize } from "utils/strings";
// import useSound from "use-sound";
// import sound from "./chime.mp3";

import useSelectedFellow from "hooks/useSelectedFellow";
import { getLocalStorage } from "utils/secure";

const FellowChatRoom = () => {
  const scrollRef = useRef();
  const chatContainer = useRef();
  const urlParams = useParams();
  const [roomId] = useState(() => {
    return urlParams && urlParams?.roomID ? urlParams?.roomID : null;
  });
  const [message, setMessage] = useState("");
  const [fetchChats, setFetchChats] = useState(true);
  const [conversations, setConversations] = useState([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get("chatroom");

  // const [playSound] = useSound(sound, {
  //   volume: 0.1,
  // });

  const { profileInfo } = useSelector(profileSelector);

  const { data, isLoading: fetchingChats } = useGetFellowMessagesQuery(roomId, {
    skip: fetchChats,
  });

  const socketMounted = useRef(true);
  const socketRef = useRef(null);
  const user = getLocalStorage("3MTUSER") || null;

  const _scroll = () => {
    chatContainer.current?.scrollIntoView({ behaviour: "smooth" });
  };

  useSelectedFellow();

  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  //   return () => {
  //     document.body.style.overflow = "scroll";
  //   };
  // }, []);

  useEffect(() => {
    async function connectSocketNode() {
      socketRef.current = io("https://socket.3mtt.training");

      // socketRef.current.on("connect", () => {
      //   console.log("Connected to socket.io server");
      // });
      // socketRef.current.on("disconnect", () => {
      //   console.log("Not Connected to socket.io server");
      // });
      setFetchChats(false);
      return () => {
        socketRef.current.disconnect();
      };
    }
    connectSocketNode();
  }, [socketMounted]);

  useEffect(() => {
    if (data?.data) {
      setConversations([...data?.data]);
    }
  }, [data]);

  useEffect(() => {
    if (roomId && socketRef?.current && socketRef?.current?.connected) {
      // socketRef.current.on("send_message", (data) => {
      socketRef.current.on(roomId, (data) => {
        // if (profileInfo?.data?.id !== data?.accountuser) {
        //   playSound();
        // }
        setConversations([...conversations, data]);
      });
    }
  });

  useEffect(() => {
    _scroll();
  });

  const sendMessage = (message) => {
    if (message.length > 0) {
      if (socketRef?.current && socketRef?.current?.connected) {
        socketRef.current.emit("send_message", {
          message,
          room: roomId,
          email: user?.email,
          accountuser: profileInfo?.data?.id,
        });
        setMessage("");
      } else {
        toast.error(`✔️ Invalid Connection`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } else {
      toast.error(`✔️ Please enter a message to send!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (socketRef.current.connected) {
      sendMessage(message);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        if (message && message?.length > 0) {
          event.preventDefault();
          handleSubmit(event);
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const authSend = "mb-4 justify-content-end align-items-end";
  const authNotice = "message-sent";

  const fellowSend = "my-4";
  const fellowNotice = "message-received";

  const guidelines = [
    "Keep the conversation relevant to the 3MTT Programme",
    "Treat everyone with courtesy and respect, avoid offensive language, hate speech, harassment, personal attacks, insults, or any form of bullying.",
    "Be mindful of your privacy and that of others; do not share personal information without consent.",
    "Avoid disruptive behavior or intentionally provoking others.",
    "Stay safe, keep your conversations on this forum - Please do not create other groups outside the 3MTT platform",
  ];

  return (
    <FellowLayout>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <h4>Forum Guidelines</h4>
          <ul className="provider-verification ">
            {guidelines.map((guideline) => {
              return (
                <li key={guideline}>
                  <p>{guideline}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-xl-12 col-lg-12">
          <div className="chat-container">
            <div className="card chat-card">
              <div className="card-header mb-0 border-0 pb-0 pt-4">
                <h3>{paramValue ? paramValue : "Live Chat"}</h3>
              </div>
              {fetchingChats ? (
                <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
                  <FadeLoader color="#36d7b7" />
                </div>
              ) : (
                <div className="card-body pt-0">
                  {conversations.length > 0 ? (
                    <section
                      className="chat-box-area dlab-scroll style-1 chat-scroll"
                      id="chartBox2"
                    >
                      {conversations
                        .filter((item) => !item.is_deleted)
                        .map((conversation) => {
                          const isAuth =
                            profileInfo?.data?.id === conversation?.accountuser;
                          return (
                            <div
                              className={`media ${
                                isAuth ? authSend : fellowSend
                              }`}
                              key={conversation?.id}
                              ref={chatContainer}
                            >
                              <div
                                className={`w-auto ${
                                  isAuth ? authNotice : fellowNotice
                                }`}
                              >
                                <h4 className="fs-16 font-w600">
                                  {conversation?.username &&
                                    capitalize(conversation?.username)}
                                </h4>
                                <p className="mb-1">{conversation?.message}</p>
                                <span className="fs-12">
                                  {formatDate(conversation?.created_at)}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </section>
                  ) : (
                    <PerfectScrollbar
                      className="chat-box-area dlab-scroll style-1"
                      id="chartBox3"
                    >
                      <div className="card-body text-center ai-icon">
                        <BagIcon />
                        <h4 className="my-2">No chat yet</h4>
                      </div>
                    </PerfectScrollbar>
                  )}
                  <span ref={scrollRef} className="scrollRef" />

                  <div className="type-massage-container">
                    <div className="type-massage style-1">
                      <div className="input-group">
                        <textarea
                          className="form-control"
                          placeholder="Your message"
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                        ></textarea>
                        <form>
                          <div className="input-group-append">
                            <button
                              onClick={handleSubmit}
                              type="submit"
                              disabled={message?.length > 0 ? false : true}
                              className="btn btn-primary p-2"
                            >
                              <SendIcon />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </FellowLayout>
  );
};

export default FellowChatRoom;
