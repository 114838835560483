/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { level_four } from "utils/constant.data";
import { countWords } from "utils/strings";

const ProfileLevelFour = ({ profile, formik, validateFormStep, setStep, updating, handleSubmit }) => {
  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Provide a brief overview of the key members of your training
            delivery team, including their qualifications and experience related
            to each proposed course. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.core_team_and_skill_set : ""}
          id="core_team_and_skill_set"
          name="core_team_and_skill_set"
          {...formik.getFieldProps("core_team_and_skill_set")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.core_team_and_skill_set ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.core_team_and_skill_set}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.core_team_and_skill_set
              ? `${countWords(formik?.values.core_team_and_skill_set)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Share links to professional profiles, certifications, or
            publications for each key team member. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.professional_profiles : ""}
          id="professional_profiles"
          name="professional_profiles"
          {...formik.getFieldProps("professional_profiles")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.professional_profiles ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.professional_profiles}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.professional_profiles
              ? `${countWords(formik?.values.professional_profiles)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          className="ms-2 me-2 py-3 btn-pigment-green-outline "
          disabled={updating}
          style={{ width: "100% !important" }}
          type="button"
          onClick={() => {
            setStep(3);
          }}
        >
          Previous
        </Button>

        <Button
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
          onClick={() => {
            const L4_ERROR = validateFormStep(level_four);
            if (L4_ERROR) {
              return
            }
            handleSubmit(formik.values, level_four, 4)
            }
          }
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Save"
          )}
        </Button>

        <Button
          onClick={() => {
            const L4_ERROR = validateFormStep(level_four);
            if (!L4_ERROR) {
              setStep(5);
            }
          }}
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelFour;
