/** @format */

import React from "react";
import { FadeLoader } from "react-spinners";

import FellowLayout from "views/fellows/Fellow";
import ProviderLayout from "views/providers/Provider";
import { useGetZohoSupportTicketsQuery } from "store/services/adminAPI";
import SupportTicketsTable from "./components/SupportTicketsTable";
import { getLocalStorage } from "utils/secure";




const user = localStorage.getItem("3MTUSER")
? getLocalStorage("3MTUSER")
: null;

const userRole = user?.account_type

const SupportPage = () => {

  const { data, isLoading } = useGetZohoSupportTicketsQuery();


  return (
    <div>
    {
      userRole === "FELLOW" && (
        <FellowLayout>
        <div className="row">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
              <div>
            <SupportTicketsTable
              tickets={data?.data}
              isLoading={isLoading}
              metadata={data?.data?.metadata}
              // filter ={filter}
              // setFilter={setFilter}
            />
              </div>
          )}
        </div>
      </FellowLayout>
      )
    }
    {
      userRole === "PROVIDER" && (
        <ProviderLayout>
        <div className="row">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
              <div>
            <SupportTicketsTable
              tickets={data?.data}
              isLoading={isLoading}
              metadata={data?.data?.metadata}
              // filter ={filter}
              // setFilter={setFilter}
            />
              </div>
          )}
        </div>
      </ProviderLayout>
      )
    }
   
    </div>
  );
};

export default SupportPage;
