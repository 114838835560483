/** @format */

import React, { useState } from "react";
import { Button, Modal, Col } from "react-bootstrap";
import Select from "react-select";
import { Style } from "utils/select";
import { toast } from "react-toastify";
import { useGetFellowAppliedModulesQuery } from "store/services/adminAPI";
import { weeks } from "utils/constant.data";

export const FilterModal = ({
  showModal,
  setShowFilter,
  setFilter,
  filtering,
}) => {
  const [selectedWeek, setWeek] = useState(null);
  const [selectedModule, setModule] = useState(null);

  const { data: modules, isLoading: loadingModules } =
    useGetFellowAppliedModulesQuery();

  const ClearIndicator = (props) => {
    const {
      children = "Clear Status",
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const handleFilter = () => {
    if (!selectedModule && !selectedWeek) {
      toast.warn("✔️ Please Select a Filter !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      setFilter({
        module_id: selectedModule?.value ? selectedModule?.value : "",
        week_number: selectedWeek?.value ? selectedWeek?.value : "",
      });
    }
  };

  const Modules = modules?.data
    ?.filter((_) => {
      return _?.status === "active";
    })
    ?.map((_) => {
      return {
        value: _?.id,
        label: _?.name,
        week_number: _?.week_number,
      };
    });

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowFilter(false)}
        className="modal fade"
        id="postModal"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Filter Fellows</h5>
            <Button
              variant=""
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setShowFilter(false)}
            >
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <Col md={12}>
                  <p className="p-1 m-0">Week Number</p>
                  <div className="form-group mb-3">
                    <Select
                      value={selectedWeek}
                      onChange={setWeek}
                      options={weeks}
                      styles={Style}
                      components={{ ClearIndicator }}
                    />
                  </div>
                </Col>

                {loadingModules ? (
                  <p>Loading Modules.....</p>
                ) : (
                  Modules && (
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <label>Modules</label>
                        <Select
                          value={selectedModule}
                          onChange={(_) => {
                            setModule(_);
                          }}
                          options={Modules}
                          styles={Style}
                        />
                      </div>
                    </div>
                  )
                )}
              </form>
            </div>

            <div className="d-flex w-100 justify-content-center align-items-center">
              <Button
                className="ms-2 me-2 py-3 btn-pigment-green-outline "
                style={{ width: "100% !important" }}
                type="button"
                onClick={() => {
                  setModule(null);
                  setWeek(null);
                }}
              >
                Clear Filter
              </Button>

              <Button
                onClick={() => {
                  handleFilter();
                }}
                type="button"
                className="ms-2 pull-right  py-3 btn-pigment-green"
              >
                {filtering ? "Filtering ...." : "Filter Resource"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterModal;
