/** @format */

import React, { Fragment } from "react";
import ProfileHeader from "./components/ProfileHeader";
import ProfileContent from "./components/ProfileContent";
import { FadeLoader } from "react-spinners";
import CommunityLayout from "views/community/Community";
import { useGetFellowProfileQuery } from "store/services/adminAPI";

const CommunityProfile = () => {
  const { data: member, isLoading: fetchingFellow } =
    useGetFellowProfileQuery();

  return (
    <CommunityLayout>
      <Fragment>
        {fetchingFellow ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <Fragment>
            <h2>Community Member Profile</h2>
            <ProfileHeader profileInfo={member?.data?.profile} />
            <ProfileContent member={member} />
          </Fragment>
        )}
      </Fragment>
    </CommunityLayout>
  );
};

export default CommunityProfile;
