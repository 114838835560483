import React, { useState } from "react";
import { Modal, Col, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from "react-select";
import { Style } from "utils/select";
import { useFormik } from "formik";
import { useGetAllOnlineLearningProvidersQuery, useGetFellowCourseModulesQuery} from "store/services/adminAPI";
import axios_instance from "store/constant/axiosInstance";
import { extractErrorMessage } from "utils/strings";
import { ReactComponent as InfoIcon } from "images/svg/info.svg";


export const UpdateModal = ({ showUpdateModal, setShowUpdateModal, certificateID, comment }) => {
    const [loading, setLoading] = useState(false);
    const { data } = useGetAllOnlineLearningProvidersQuery();
    const {data: courseModules} = useGetFellowCourseModulesQuery('main')

    const providerOptions = data?.data?.map((provider) => ({
        value: provider.id,
        label: provider.name,
    })) || [];

    const CourseModuleOptions = courseModules?.data?.map((course)=> ({
        value: course.id,
        label: course.name
    })) || []

    const formik = useFormik({
        initialValues: {
            learning_provider: null,
            file: null,
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    async function handleSubmit(values) {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", values.file);
        formData.append("learning_provider", values.learning_provider.value);
        formData.append("online_course", values.online_course.value);

    
        try {
            await axios_instance.patch(`fellow/certificates/${certificateID}`, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            toast.success("Certificate Updated Successfully!");
            setShowUpdateModal(false);
            formik.resetForm();
        } catch (error) {   
            const formattedError = extractErrorMessage(error)         
            
            toast.error(formattedError);
        } finally {
            setLoading(false);
        }
    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
        const maxFileSize = 10 * 1024 * 1024; 

        if (!file) return;

        if (!allowedFileTypes.includes(file.type)) {
            toast.error("Only PDF, JPEG, and PNG files are allowed.");
            return;
        }

        if (file.size > maxFileSize) {
            toast.error("File size exceeds the 10MB limit.");
            return;
        }

        formik.setFieldValue("file", file);
    }

    return (
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Update Submitted Certificate</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                <Col md={12}>
                <Alert variant="info" className="alert-dismissible fade show">
                    <InfoIcon />

                    {comment}
                  </Alert>
                    </Col>
                    <Col md={12}>
                        <label>Online Learning Provider</label>
                        <div className="form-group mb-3">
                            <Select
                                value={formik.values.learning_provider}
                                onChange={(value) => formik.setFieldValue("learning_provider", value)}
                                options={providerOptions}
                                styles={Style}
                            />
                            {formik.touched.learning_provider && formik.errors.learning_provider && (
                                <div className="text-danger">{formik.errors.learning_provider}</div>
                            )}
                        </div>
                    </Col>
                    <Col md={12}>
                        <label>Online course</label>
                        <div className="form-group mb-3">
                            <Select
                                value={formik.values.online_course}
                                onChange={(value) => formik.setFieldValue("online_course", value)}
                                options={CourseModuleOptions}
                                styles={Style}
                            />
                            {formik.touched.online_course && formik.errors.online_course && (
                                <div className="text-danger">{formik.errors.online_course}</div>
                            )}
                        </div>
                    </Col>
                    <div className="mb-3">
                        <label htmlFor="file" className="form-label">
                            Update Certificate (PDF, JPG, PNG)
                        </label>
                        <input
                            className="form-control"
                            type="file"
                            id="file"
                            accept=".pdf,.jpeg,.jpg,.png"
                            onChange={handleFileChange}
                        />
                        {formik.touched.file && formik.errors.file && (
                            <div className="text-danger">{formik.errors.file}</div>
                        )}
                    </div>
                    <div className="text-end">
                        <button className="btn btn-primary btn-sl-sm" type="submit" disabled={loading}>
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            ) : (
                                <>
                                    <i className="fa fa-Update me-2" />
                                    Update
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};
const validationSchema = Yup.object().shape({
    learning_provider: Yup.object().required("Learning provider is required"),
    file: Yup.mixed().required("File is required"),
    online_course: Yup.object().required("Online course is required"),

})