/** @format */

import React from "react";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import bg5 from "images/card/card22.jpg";
import FellowLayout from "views/fellows/Fellow";
import { Path } from "utils/constant.data";

import { useGetFellowRoomsQuery } from "store/services/providerAPI";

import useSelectedFellow from "hooks/useSelectedFellow";

const FellowChatList = () => {
  const navigate = useNavigate();
  const { data, isLoading: fetchingFellows } = useGetFellowRoomsQuery();

  useSelectedFellow();

  return (
    <FellowLayout>
      <div className="row">
        {fetchingFellows ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : data?.data?.length > 0 ? (
          data?.data?.map((room, index) => {
            return (
              <div className="col-xl-4 col-lg-12 col-sm-12" key={room?.id}>
                <div className="card overflow-hidden">
                  <div
                    className={`text-center p-5 overlay-box ${
                      index === 0 ? "zero" : index === 1 ? "one" : "two"
                    }`}
                    style={{ backgroundImage: `url(${bg5})` }}
                  >
                    <figure>
                      <i
                        class="fa fa-comments text-white chat-icon"
                        aria-hidden="true"
                      ></i>
                    </figure>
                    <h3 className="mt-3 mb-0 text-white">{room?.room?.name}</h3>
                    <p className="text-white"> {room?.room?.description} </p>
                  </div>
                  <div className="card-body">
                    <div className="row text-center">
                      <div className="col-6">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">Members</h4>
                          <small>&nbsp; - &nbsp;</small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="bgl-primary rounded p-3">
                          <h4 className="mb-0">Manager</h4>
                          <small>
                            {room?.room?.manager
                              ? room?.room?.manager
                              : "Unassigned"}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer mt-0">
                    <button
                      onClick={() =>
                        navigate(
                          `${Path.FELLOW_FORUM}/${room?.room?.id}?chatroom=${room?.room?.name}`
                        )
                      }
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Enter Forum
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="card">
            <div className="card-body text-center ai-icon">
              <BagIcon />
              <h4 className="my-2">No forum yet</h4>
            </div>
          </div>
        )}
      </div>
    </FellowLayout>
  );
};
export default FellowChatList;
