/** @format */

import React from "react";

import Check from "images/vector/check.png";
import Bookmarks from "images/vector/bookmarks.png";
import Bulb from "images/vector/bulb.png";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";

function RightIcon() {
  return (
    <>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 0.25H4C3.00544 0.25 2.05161 0.645088 1.34835 1.34835C0.645088 2.05161 0.25 3.00544 0.25 4V19C0.25 19.9946 0.645088 20.9484 1.34835 21.6517C2.05161 22.3549 3.00544 22.75 4 22.75H19C19.9946 22.75 20.9484 22.3549 21.6517 21.6517C22.3549 20.9484 22.75 19.9946 22.75 19V4C22.75 3.00544 22.3549 2.05161 21.6517 1.34835C20.9484 0.645088 19.9946 0.25 19 0.25ZM16.875 8.5125L11.1625 16.0125C11.0461 16.1638 10.8965 16.2864 10.7253 16.3709C10.5542 16.4554 10.3659 16.4995 10.175 16.5C9.98513 16.501 9.79753 16.4588 9.62643 16.3765C9.45532 16.2942 9.30522 16.174 9.1875 16.025L6.1375 12.1375C6.03655 12.0078 5.96212 11.8595 5.91848 11.7011C5.87484 11.5426 5.86283 11.3772 5.88314 11.2141C5.90346 11.051 5.95569 10.8935 6.03687 10.7506C6.11804 10.6077 6.22657 10.4822 6.35625 10.3812C6.61815 10.1774 6.95032 10.0859 7.27968 10.1269C7.44276 10.1472 7.60025 10.1994 7.74314 10.2806C7.88604 10.3618 8.01155 10.4703 8.1125 10.6L10.15 13.2L14.875 6.95C14.9751 6.81868 15.1002 6.70836 15.2429 6.62536C15.3857 6.54235 15.5434 6.48828 15.7071 6.46622C15.8707 6.44417 16.0371 6.45457 16.1968 6.49682C16.3564 6.53908 16.5062 6.61237 16.6375 6.7125C16.7688 6.81263 16.8791 6.93765 16.9621 7.08042C17.0451 7.22318 17.0992 7.3809 17.1213 7.54456C17.1433 7.70823 17.1329 7.87463 17.0907 8.03427C17.0484 8.19392 16.9751 8.34368 16.875 8.475V8.5125Z"
          fill="#26a65b"
        />
      </svg>
    </>
  );
}

function BookIcon() {
  return (
    <>
      <svg
        width="23"
        height="23"
        viewBox="0 0 21 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.3201 4.65852H2.86175C2.35544 4.6591 1.87003 4.8592 1.51201 5.21494C1.15399 5.57068 0.952605 6.053 0.952026 6.55609V24.5829C0.952032 24.7573 1.00037 24.9282 1.09175 25.077C1.18312 25.2259 1.31399 25.3468 1.47 25.4266C1.626 25.5063 1.80111 25.5418 1.97609 25.5292C2.15108 25.5166 2.31918 25.4563 2.46195 25.355L8.5901 21.005L14.72 25.355C14.8628 25.4563 15.0309 25.5166 15.2058 25.5292C15.3808 25.5418 15.5559 25.5063 15.7119 25.4265C15.8679 25.3468 15.9988 25.2258 16.0901 25.077C16.1815 24.9282 16.2298 24.7572 16.2298 24.5829V6.55609C16.2292 6.053 16.0278 5.57068 15.6698 5.21494C15.3118 4.8592 14.8264 4.6591 14.3201 4.65852V4.65852Z"
          fill="#21262E"
        />
        <path
          d="M18.1395 0.863403H5.72635C5.4731 0.863403 5.23023 0.963364 5.05116 1.14129C4.87209 1.31923 4.77148 1.56055 4.77148 1.81218C4.77148 2.06382 4.87209 2.30514 5.05116 2.48307C5.23023 2.661 5.4731 2.76096 5.72635 2.76096H18.1395V20.7878C18.1395 21.0394 18.2401 21.2808 18.4192 21.4587C18.5983 21.6366 18.8412 21.7366 19.0944 21.7366C19.3476 21.7366 19.5905 21.6366 19.7696 21.4587C19.9487 21.2808 20.0493 21.0394 20.0493 20.7878V2.76096C20.0487 2.25788 19.8473 1.77556 19.4893 1.41982C19.1313 1.06408 18.6459 0.863979 18.1395 0.863403V0.863403Z"
          fill="#21262E"
        />
      </svg>
    </>
  );
}

export default function CourseBlog() {
  const { profileInfo } = useSelector(profileSelector);

  const CourseBlogData = [
    {
      coloumClass: "col-sm-6",
      classChange: "bg-secondary",
      image: Check,
      imgClass: "",
      title: profileInfo?.data?.profile?.provider_profile_id,
      number: "ALC ID",
      svgicon: <RightIcon />,
    },
    {
      coloumClass: "col-sm-6",
      image: Bookmarks,
      imgClass: "bookmarks",
      title: "All Fellows",
      number: "0",
      svgicon: <BookIcon />,
    },
    {
      coloumClass: "col-sm-12",
      classChange: "bg-danger",
      image: Bulb,
      imgClass: "bulb",
      title: "Total Cohorts",
      number: "0",
      svgicon: <BookIcon />,
    },
  ];

  return (
    <>
      <div className="row">
        {CourseBlogData.map((item, ind) => (
          <div className={`col-xl-4 ${item.coloumClass}`} key={ind}>
            <div className={`dlab-cource ${item.classChange}`}>
              <div className="d-flex align-items-center">
                <span className="course-icon">{item.svgicon}</span>
                <div className="ms-2">
                  <h4 className="mb-0">{item.number}</h4>
                  <span>{item.title}</span>
                </div>
              </div>
              <img src={item.image} alt="" className={item.imgClass} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
