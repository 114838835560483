/** @format */

import React, { Fragment } from "react";
import ProfileHeader from "./components/ProfileHeader";
import ProfileContent from "./components/ProfileContent";
import { FadeLoader } from "react-spinners";

import FellowLayout from "views/fellows/Fellow";
import { useGetFellowProfileQuery } from "store/services/adminAPI";

const FellowProfile = () => {
  const { data: fellow, isLoading: fetchingFellow } =
    useGetFellowProfileQuery();

  return (
    <FellowLayout>
      <Fragment>
        {fetchingFellow ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <Fragment>
            <h2>Fellow Profile</h2>
            <ProfileHeader profileInfo={fellow?.data?.profile} />
            <ProfileContent fellow={fellow} />
          </Fragment>
        )}
      </Fragment>
    </FellowLayout>
  );
};

export default FellowProfile;
