/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { Path } from "utils/constant.data";

const Page404 = () => {
  return (
    <div className="authincation h-100 p-meddle  minvh">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center ">
          <div
            className="col-md-5"
            style={{
              paddingTop: "10rem",
            }}
          >
            <div className="form-input-content text-center error-page">
              <h1 className="error-text font-weight-bold">404</h1>
              <h4>
                <i className="fa fa-exclamation-triangle text-warning" /> The
                page you were looking for is not found!
              </h4>
              <p>
                You may have mistyped the address or the page may have moved.
              </p>
              <div>
                <Link className="btn btn-primary" to={Path.LOGIN}>
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
