/** @format */

import React from "react";
import { FadeLoader } from "react-spinners";

import FellowLayout from "../../Fellow";
import { useGetFellowCertificatesQuery } from "store/services/adminAPI";
import CertificateTable from "./components/CertificatesTable";


const FellowOnlineLearningCertificates = () => {





  const { data, isLoading } = useGetFellowCertificatesQuery();


  return (
    <FellowLayout>
      <div className="row">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
            <div>
          <CertificateTable
            certificates={data?.data}
            isLoading={isLoading}
            metadata={data?.data?.metadata}
            // filter ={filter}
            // setFilter={setFilter}
          />
            </div>
        )}
      </div>
    </FellowLayout>
  );
};

export default FellowOnlineLearningCertificates;
