import React, { useState } from "react";
import { Modal, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios_instance from "store/constant/axiosInstance";
import { extractErrorMessage } from "utils/strings";
import { getLocalStorage } from "utils/secure";



const user = localStorage.getItem("3MTUSER")
? getLocalStorage("3MTUSER")
: null;

const userRole = user?.account_type

export const SubmissionModal = ({ showSubmissionModal, setShowSubmissionModal }) => {
    const [loading, setLoading] = useState(false);


    const FellowCategoryOptions = [
        {
            label: "Select a Category",
            value: ""
        },
        {
            label: "Profile Updates/Changes",
            value: "Profile Updates"
        },
        {
            label: "Applied Learning Contents and Courses",
            value: "Applied Learning Contents and Courses"
        },
        {
            label: "Assessment/Assignment Issues",
            value: "Assessment/Assignment Issues"
        },
        {
            label: "Attendance Issues",
            value: "Attendance Issues"
        },
        {
            label: "Certificates",
            value: "Certificates"
        }
    ];
    
    const ALCCategoryOptions = [
        {
            label: "Select a Category",
            value: ""
        },
        {
            label: "Organization Data Update/Changes",
            value: "Organization Data Update/Changes"
        },
        {
            label: "Fellows Issues",
            value: "Fellows Issues"
        },
        {
            label: "Applied Learning Contents and Courses",
            value: "Applied Learning Contents and Courses"
        },
        {
            label: "Attendance Issues",
            value: "Attendance Issues"
        },
        {
            label: "Assessment/Assignment Issues",
            value: "Assessment/Assignment Issues"
        }
    ];
    



    const formik = useFormik({
        initialValues: {
            category: "",
            subject: "",
            description: ""
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    async function handleSubmit(values) {
        setLoading(true);
        const formData = new FormData();
        if (values.file) {
            formData.append("file", values.file);
        }
        formData.append("category", values.category);
        formData.append("subject", values.subject);
        formData.append("description", values.description);


        axios_instance.post("core/support-ticketing", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            toast.success(data?.data?.message);
            setShowSubmissionModal(false);
            formik.resetForm();
            setLoading(false);
        })
        .catch((error) => {
            const formattedError = extractErrorMessage(error?.response);
            toast.error(formattedError);
            setLoading(false);
        })

    }

    function handleFileChange(event) {
        const file = event.target.files[0];
        const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
        const maxFileSize = 10 * 1024 * 1024;

        if (!file) return;

        if (!allowedFileTypes.includes(file.type)) {
            toast.error("Only PDF, JPEG, and PNG files are allowed.");
            return;
        }

        if (file.size > maxFileSize) {
            toast.error("File size exceeds the 10MB limit.");
            return;
        }

        formik.setFieldValue("file", file);
    }

    return (
        <Modal show={showSubmissionModal} onHide={() => setShowSubmissionModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Report an Issue</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <Col md={12}>
                        <label>Choose a Category</label>
                        <div className="form-group mb-3">
                        <select
                            value={formik.values.category}
                            className="form-control"
                            onChange={(e) => formik.setFieldValue("category", e.target.value)}
                            >
                            {userRole === "FELLOW"
                                ? FellowCategoryOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))
                                : ALCCategoryOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                    {option.label}
                                    </option>
                                ))}
                            </select>
                            {formik.touched.category && formik.errors.category && (
                                <div className="text-danger">{formik.errors.category}</div>
                            )}
                        </div>
                    </Col>
                    <Col md={12}>
                        <label>Subject</label>
                        <div className="mb-3">

                        <input 
                                name="subject"
                                id="subject"
                                className="form-control"
                                placeholder="Subject"
                                {...formik.getFieldProps("subject")}
                            />
                        {formik.touched.subject && formik.errors.subject && (
                                <div className="text-danger">{formik.errors.subject}</div>
                            )}
                        </div>
                    </Col>
                    <Col md={12}>
                        <label>Description</label>
                        <div className="mb-3">
                        <textarea 
                                name="description"
                                id="description"
                                className="form-control"
                                placeholder="Description"
                                {...formik.getFieldProps("description")}
                            />
                        {formik.touched.description && formik.errors.description && (
                                <div className="text-danger">{formik.errors.description}</div>
                            )}
                        </div>
                    </Col>
                    <div className="mb-3">
                        <label htmlFor="file" className="form-label">
                            Upload attachment (PDF, JPG, PNG)
                        </label>
                        <div className="mb-3">
                        <input
                            className="form-control"
                            type="file"
                            id="file"
                            accept=".pdf,.jpeg,.jpg,.png"
                            onChange={handleFileChange}
                        />
                        {formik.touched.file && formik.errors.file && (
                            <div className="text-danger">{formik.errors.file}</div>
                        )}
                        </div>
                    </div>
                    <div className="text-end">
                        <button className="btn btn-primary btn-sl-sm" type="submit" disabled={loading}>
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            ) : (
                                <>
                                    Submit
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

const validationSchema = Yup.object().shape({
    category: Yup.string().required("Issue Category is required"),
    subject: Yup.string().required("Subject is required"),
    description: Yup.string().required("Description is required"),
});