/** @format */

import React, { Fragment } from "react";
import { Tab } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";
import { Accordion } from "react-bootstrap";

const SelectedFaqs = ({ faqs }) => {

  return (
    <Fragment>
      <Tab.Content className="pt-4">
        <p>
          The purpose of FAQs is to provide users or customers with quick and
          accessible information to address common queries without the need for
          direct interaction with customer support or service representatives.
        </p>

        <div className="custome-accordion">
          <Accordion id="accordionExample" defaultActiveKey="0">
            {faqs?.length > 0 ? (
              faqs.map((data, i) => (
                <Accordion.Item className="card" key={i} eventKey={`${i}`}>
                  <Accordion.Header
                    as="h2"
                    className="accordion-header border-0"
                    id="headingOne"
                  >
                    <span className="acc-heading"> {data.question}</span>
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={`${i}`} id="collapseOne">
                    <div className="accordion-body card-body pt-0">
                      <p className="fs-18 m-0">{data.answer}</p>
                    </div>
                  </Accordion.Collapse>
                </Accordion.Item>
              ))
            ) : (
              <div className="bg-white py-5">
                <EmptyNotice text="No faq at the moment" />
              </div>
            )}
          </Accordion>
        </div>
      </Tab.Content>
    </Fragment>
  );
};

export default SelectedFaqs;
