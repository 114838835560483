/** @format */

import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          <b>
            Federal Ministry of Communications, Innovation and Digital Economy
          </b>{" "}
          © {new Date().getFullYear()} All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
