/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "react-bootstrap";
// import videoimg from "images/courses/video-img.jpg";
import ModalVideo from "react-modal-video";
import { ReactComponent as PlayIcon } from "images/svg/play.svg";
import MMLogo from "images/3MTT/main.png";

const HowTo = () => {
  const [isOpen, setOpen] = useState(false);
  const [activeVideo, setVideo] = useState(null);
  // https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/ 
  const videos = [
    {
      title: "Accessing your 3MTT dashboard",
      summary:
        "In this video, you will be guided through the process of accessing your 3MTT dashboard using your email and password.",
      link: "https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/Accessing+Your+3MTT+Dashboard.mp4",
    },
    {
      title: "Navigating your 3MTT dashboard",
      summary:
        "What more can you do on your dashboard? Find out in this video.",
      link: "https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/Navigating+your+3MTT+dashboard.mp4",
    },
    {
      title: "Learn about your Profile and Community pages",
      summary:
        "Discover what's on your profile page and explore the features available on your community page in this video.",
      link: "https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/Your+Profile+and+Community+Pages.mp4",
    },
    {
      title: "Your courses on the 3MTT platform",
      summary:
        "Your learning activities are carefully detailed on the “My Courses” page of the 3MTT platform, watch this video to see what it contains.",
      link: "https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/Your+Courses+on+the+3MTT+Platform.mp4",
    },
    {
      title: "Interact with others learning on the Forum page",
      summary: `You are not alone on your learning journey, so this video will show you how to interact with other learners in this programme and the need to pay attention to the forum guidelines.`,
      link: "https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/Interact+with+other+learners+on+the+Forum+page.mp4",
    },
    {
      title: "Accessing your course for free on Coursera",
      summary:
        "Watch this video to learn how to access your courses for free on Coursera.",
      link: "https://mtt-bucket-upload-new.s3.us-east-2.amazonaws.com/Accessing+your+course+for+free+on+Coursera.mp4",
    },
  ];

  const CourseCard = ({ video }) => {
    return (
      <>
        <div className="col-xs-12 col-sm-6 col-lg-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-wrap mb-2">
                <h3>{video.title}</h3>
              </div>
              <div className="video-img style-1 video-image">
                <div className="view-demo">
                  <img src={MMLogo} alt="" />
                  <div className="play-button text-center">
                    <Link
                      to={"#"}
                      className="popup-youtube"
                      onClick={() => {
                        setVideo(video);
                        setOpen(true);
                      }}
                    >
                      <PlayIcon />
                    </Link>
                  </div>
                </div>
              </div>
              <Tab.Container defaultActiveKey="About">
                <div className="course-details-tab style-2 mt-4">
                  <div className="card-header border-0 pb-0 video-progress-header">
                    {/* <h4>Summary</h4> */}
                  </div>
                  <div className="card-body pt-0 video-progress-footer">
                    <div className="progress-box">
                      {/* <div className="progress ">
                      <div
                        className="progress-bar bg-primary"
                        style={{
                          width: "15%",
                          height: "12px",
                          borderRadius: "4px",
                        }}
                        role="progressbar"
                      ></div>
                    </div> */}

                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0 fs-14 font-w400">
                          {video.summary}
                        </h5>
                        {/* <span className="font-w600">10/110</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row">
        {videos.map((_video) => {
          return <CourseCard video={_video} key={_video?.link} />;
        })}
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        theme="dark"
        // url={video?.link}
        url={activeVideo?.link}
        // url="https://res.cloudinary.com/dvojqclr1/video/upload/v1701635233/3MTT%20Videos/Accessing_Your_3MTT_Dashboard_q2ft56.mp4"
      />
    </>
  );
};

export default HowTo;
