/** @format */

import React, { useState, useEffect } from "react";

import ProviderLayout from "views/providers/Provider";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { FadeLoader } from "react-spinners";
import ProviderBroadcastTable from "views/providers/components/table/fellows/ProviderBroadcastTable";
import CreateBroadcast from "./components/CreateBroadcast";
import { useGetProviderBroadcastsQuery } from "store/services/notification";
import { capitalize } from "utils/strings";

import useApprovedProvider from "hooks/useApprovedProvider";

const ProviderBroadcast = () => {
  useApprovedProvider();
  const [skip, setSkip] = useState(false);
  const [messageClass] = useState("broadcast");
  const [createBroadcast, setCreate] = useState(false);
  const [filter, setFilter] = useState(`page=1&message_class=${messageClass}`);

  const {
    data,
    isLoading: fetchingBroadcasts,
    isFetching,
  } = useGetProviderBroadcastsQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      setSkip(false);
    }
  }, [filter]);


  return (
    <ProviderLayout>
      <div className="row">
        <section className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-end mb-4">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setCreate(true)}
            >
              Add {capitalize(messageClass)}
            </button>
          </div>
          {fetchingBroadcasts || isFetching ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : data?.data?.results?.length > 0 ? (
            <section className="d-flex flex-column">
              <ProviderBroadcastTable
                messages={data?.data?.results}
                metadata={data?.data?.metadata}
                setFilter={setFilter}
                setSkip={setSkip}
                messageClass={messageClass}
              />
            </section>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon">
                <BagIcon />
                <h4 className="my-2">No {capitalize(messageClass)} yet</h4>
                <p></p>
              </div>
            </div>
          )}
          <CreateBroadcast
            createBroadcast={createBroadcast}
            setCreate={setCreate}
            messageClass={messageClass}
          />
        </section>
      </div>
    </ProviderLayout>
  );
};
export default ProviderBroadcast;
