/**
 * eslint-disable no-unused-vars
 *
 * @format
 */

/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { capitalize } from "utils/strings";
// import { formatDate } from "utils/date";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import FilterModal from "./components/FilterModal";
import { FadeLoader } from "react-spinners";
import format from "date-fns/format";

const ProviderAssesmentsTable = ({
  resources,
  metadata,
  setFilter,
  setSkip,
  messageClass,
  filtering,
}) => {
  const [modalFilter, setModalFilter] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    setSkip(true);
  }, [resources, setSkip]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Assement Name",
      accessor: "id",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.title)}`;
      },
    },

    {
      Header: "Cohort",
      accessor: "cohort",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value?.rank ? `Cohort ${value?.rank}` : "General";
      },
    },

    {
      Header: "Week Number",
      accessor: "module",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${cell?.row?.original?.week_number}`;
      },
    },

    {
      Header: "Start Date",
      accessor: "start_date",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${format(new Date(value), "d MMMM, yyyy")}` : "Null";
      },
    },

    {
      Header: "Duration",
      accessor: "duration",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell: { value } }) => {
        return value ? `${value} Days` : "Null";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.status)}`;
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => resources, [resources]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  const makeFilterString = (_modalFilter, _page) => {
    const queryString = `page=${_page}${
      String(modalFilter?.week_number)?.length > 0
        ? `&week_number=${modalFilter?.week_number}`
        : ""
    }${
      String(modalFilter?.cohort_number ?? "")?.length > 0
        ? `&cohort_number=${modalFilter?.cohort_number}`
        : ""
    }${
      modalFilter?.module_id?.length > 0
        ? `&module_id=${modalFilter?.module_id}`
        : ""
    }`;
    return queryString;
  };

  const handleAllFilter = (_modalFilter, _page) => {
    const filterString = makeFilterString(_modalFilter, _page);
    setFiltered(true);
    setFilter(filterString);
    setShowFilter(false);

    // console.log(filterString);
  };

  useEffect(() => {
    if (modalFilter) {
      handleAllFilter(modalFilter, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFilter]);

  return (
    <>
      <FilterModal
        showModal={showFilter}
        setShowFilter={setShowFilter}
        setFilter={setModalFilter}
        filtering={filtering}
      />
      <div className="card">
        <div className={`card-header ${filtering ? "disabled" : ""}`}>
          <div className="d-flex  align-items-center">
            <h4 className="card-title text-pigment-green">All Assessments</h4>

            {filtered && (
              <button
                onClick={() => {
                  setFiltered(false);
                  setFilter(`page=1`);
                  setModalFilter(null);
                }}
                className="btn text-danger"
              >
                Reset Filter
              </button>
            )}
          </div>

          <div className="d-flex  align-items-center">
            <button
              type="button"
              className="btn btn-primary ml ms-2"
              onClick={() => {
                setSkip(true);
                setShowFilter(true);
              }}
            >
              Filter
            </button>
          </div>
        </div>
        {filtering ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <div className="card-body">
            {data?.length > 0 ? (
              <div className="table-responsive">
                <div className="dataTables_wrapper">
                  <table
                    {...getTableProps()}
                    className="table dataTable display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                    <button
                      className={`btn btn-outline ${
                        hasPrev ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page - 1) || 1;
                        const modalFilterString = modalFilter
                          ? makeFilterString(modalFilter, _newPage)
                          : "";

                        modalFilter
                          ? setFilter(modalFilterString)
                          : setFilter(
                              `page=${_newPage}&page_size=${metadata?.page_size}`
                            );
                      }}
                    >
                      Previous
                    </button>

                    {metadata?.count && (
                      <div className="d-flex">
                        <p className="mb-0">
                          Page <strong>{metadata?.page}</strong> of{" "}
                          <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                          &nbsp;
                        </p>
                        <p className="mb-0">
                          Total <strong>{metadata?.count}</strong>
                        </p>
                      </div>
                    )}

                    <button
                      className={`btn btn-outline ${
                        hasNext ? "" : "disabled"
                      } `}
                      onClick={() => {
                        const _newPage = Number(metadata?.page || 0) + 1;

                        const modalFilterString = modalFilter
                          ? makeFilterString(modalFilter, _newPage)
                          : "";

                        modalFilter
                          ? setFilter(modalFilterString)
                          : setFilter(
                              `page=${_newPage}&page_size=${metadata?.page_size}`
                            );
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card">
                <div className="card-body text-center ai-icon  text-primary">
                  <BagIcon />
                  <h4 className="my-2">No data for the specified filter</h4>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProviderAssesmentsTable;
