import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios_instance from "store/constant/axiosInstance";
import { extractErrorMessage } from "utils/strings";

export const UploadModal = ({ showUploadModal, setShowUploadModal }) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            learning_provider: null,
            file: null,
        },
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    async function handleSubmit(values) {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", values.file);
        axios_instance.post("courses/provider/assessments/update/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            toast.success(data?.data?.message);
            setShowUploadModal(false);
            formik.resetForm();
            setLoading(false);
        })
        .catch((error) => {
            const formattedError = extractErrorMessage(error?.response);
            toast.error(formattedError);
            setLoading(false);
        })

    }

    function handleDownload() {
      const downloadUrl = process.env.PUBLIC_URL + '/upload_assessment_grading_sample.csv';
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'upload_assessment_grading_sample.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(`✔️ Sample file downloaded!`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
      });
    };

    

    function handleFileChange(event) {
        const file = event.target.files[0];
        const allowedFileTypes = ["text/csv"];
        const maxFileSize = 10 * 1024 * 1024;

        if (!file) return;

        if (!allowedFileTypes.includes(file.type)) {
            toast.error("Only CSV files are allowed.");
            return;
        }

        if (file.size > maxFileSize) {
            toast.error("File size exceeds the 10MB limit.");
            return;
        }

        formik.setFieldValue("file", file);
    }

    return (
        <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Marked Assessments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="file" className="form-label">
                            Upload File (CSV)
                        </label>
                        <input
                            className="form-control"
                            type="file"
                            id="file"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        {formik.touched.file && formik.errors.file && (
                            <div className="text-danger">{formik.errors.file}</div>
                        )}
                    </div>
                    <div className="text-end">
                        <button
                          type="button"
                          className="btn btn-outline ms-2 mr-2"
                          onClick={() => {
                            handleDownload()
                          }}
                        >
                          Download Sample
                        </button>              
                      
                        <button className="btn btn-primary btn-sl-sm" type="submit" disabled={loading}>
                            {loading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            ) : (
                                <>
                                    <i className="fa fa-upload me-2" />
                                    Upload
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};
const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("File is required"),
})