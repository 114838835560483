/** @format */
import { formatDateWithoutTime } from "utils/date.js";


export const COLUMNS = () => [
  {
    Header: "Ticket ID",
    accessor: "ticket_id",
    disableFilters: true,
    sortable: false,
    
  },
  {
    Header: "Subject",
    accessor: "subject",
    disableFilters: true,
    sortable: false,
    
  },

  {
    Header: "Category",
    accessor: "category",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
      return value
    },
  },
  
  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Cell: ({ value }) => {
        return value
      },
  },
  {
    Header: "Date Submitted",
    accessor: "submitted_at",
    disableFilters: true,
    sortable: false,
    Cell: ({value}) => {
        return formatDateWithoutTime(value)
    },
  },
  
];
