/** @format */

import React, { Fragment } from "react";
import { FadeLoader } from "react-spinners";
import FellowLayout from "../../Fellow";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import EmptyNotice from "component/ErrorNotice";
import { useGetAllFellowSurveysQuery } from "store/services/adminAPI";


const FellowSurveys = () => {

  const {isLoading } = useSelector(profileSelector);

  const {data:surveys, isLoading:loadingSurveys} = useGetAllFellowSurveysQuery()


  return (
    <Fragment>
      <FellowLayout>
        {isLoading || loadingSurveys ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : surveys?.data?.length > 0 ? (
          <div className="row">
          {surveys.data.map((survey, index) => (
            <div key={index} className="col-xl-4 col-md-6 col-sm-12 mb-4">
              <div className="card all-crs-wid">
                <div className="card-body">
                  <div className="courses-bx">
                    <div className="dlab-info">
                      <div className="dlab-title d-flex justify-content-between">
                        <div>
                          <h4>{survey.name}</h4>
                          <p className="m-0">{survey.description}</p>
                        </div>
                      </div>
                      <div className="d-flex float-right">
                      </div>
                        <a
                          href={survey.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="btn btn-primary">
                            Take survey
                          </button>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        ) : (
          <>
          <EmptyNotice text="No surveys available yet" />
         </>
        )}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowSurveys;
