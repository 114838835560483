/** @format */

import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import { level_three } from "utils/constant.data";
import { countWords } from "utils/strings";
import { CourseInterest } from "utils/constant.data";

const ProfileLevelThree = ({
  profile,
  formik,
  validateFormStep,
  setStep,
  courses,
  setCourses,
  updating,
  handleSubmit
}) => {
  return (
    <Fragment>
      <div className="mb-3">
        <label className="mb-2">
          <strong>
            Select the core courses your organisation is proposing to offer
            under this program. Please note that you will be required to provide
            evidence for capacity to deliver training in each course selected.{" "}
          </strong>
        </label>

        <div className="col courses__options">
          {CourseInterest.map((_course) => {
            return (
              <div className="form-check custom-checkbox mb-3 check-xs">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={_course}
                  value={_course}
                  required
                  // disabled={profile?.status.toLowerCase() ==="selected"}
                  checked={courses.indexOf(_course) !== -1 ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCourses((prev) => [...prev, e.target.value]);
                    } else {
                      const selected = courses.filter(
                        (__course) => __course !== e.target.value
                      );
                      setCourses(selected);
                    }
                  }}
                />
                <label className="form-check-label" htmlFor="customCheckBox6">
                  {_course}
                </label>
              </div>
            );
          })}
        </div>

        {/* <div className="d-flex align-items-center justify-content-between">
          {formik.touched.courses && formik.errors.courses  ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.courses}
            </div>
          ) : null}
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          {courses.length < 1 ? (
            <div className="text-danger mt-2 fs-12">
              You must select at least one course
            </div>
          ) : null}
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Provide prior track record of your organisation's training delivery
            in select courses i.e. share proof of existing programs successfully
            executed. Feel free to share viewable links where applicable. (Max
            500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.courses_track_record : ""}
          id=" courses_track_record"
          name=" courses_track_record"
          // disabled={profile?.status.toLowerCase() ==="selected"}
          {...formik.getFieldProps("courses_track_record")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.courses_track_record ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.courses_track_record}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.courses_track_record
              ? `${countWords(formik?.values.courses_track_record)}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            For each proposed course, provide a brief overview of your
            curriculum, including the key learning outcomes, your training
            methodology and the duration of the course. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.courses_overview : ""}
          id=" courses_overview"
          name=" courses_overview"
          // disabled={profile?.status.toLowerCase() ==="selected"}
          {...formik.getFieldProps("courses_overview")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.courses_overview ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.courses_overview}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.courses_overview
              ? `${countWords(formik?.values.courses_overview)}/500`
              : ""}
          </div>
        </div>
      </div>

      {/* <div className="mb-3">
        <label className="mb-1">
          <strong>Courses Link(s)</strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.courses_link : ""}
          id=" courses_link"
          disabled={profile?.is_profile_completed}
          name=" courses_link"
          {...formik.getFieldProps("courses_link")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.courses_link ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.courses_link}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.courses_link
              ? `${countWords(formik?.values.courses_link)}/500`
              : ""}
          </div>
        </div>
      </div> */}

      <div className="mb-3">
        <label className="mb-1">
          <strong>
            Please share your organization's experience in delivering blended
            learning models. Include examples of past programs that utilised a
            hybrid model and the outcomes achieved. Additionally, describe your
            structure for building peer-to-peer communities within your training
            programs. (Max 500 words)
          </strong>
        </label>

        <textarea
          className="w-100 form-control"
          placeholder="Your answer"
          value={profile ? profile?.trainee_capacity_track_record : ""}
          id=" trainee_capacity_track_record"
          // disabled={profile?.status.toLowerCase() ==="selected"}
          name=" trainee_capacity_track_record"
          {...formik.getFieldProps("trainee_capacity_track_record")}
          rows="3"
        />

        <div className="d-flex align-items-center justify-content-between">
          {formik.errors.trainee_capacity_track_record ? (
            <div className="text-danger mt-2 fs-12">
              {formik.errors.trainee_capacity_track_record}
            </div>
          ) : null}
          <div className="text-warning mt-2 fs-12">
            {formik?.values.trainee_capacity_track_record
              ? `${countWords(
                  formik?.values.trainee_capacity_track_record
                )}/500`
              : ""}
          </div>
        </div>
      </div>

      <div className="d-flex w-100 justify-content-flex-start align-items-center">
        <Button
          className="ms-2 me-2 py-3 btn-pigment-green-outline "
          disabled={updating}
          style={{ width: "100% !important" }}
          type="button"
          onClick={() => {
            setStep(2);
          }}
        >
          Previous
        </Button>

        <Button
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
          disabled={updating}
          onClick={() => {
            const L3_ERROR = validateFormStep(level_three);
            if (L3_ERROR) {
              return
            }
            handleSubmit(formik.values, level_three, 3)
            }
          }
        >
          {updating ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-white"
            ></span>
          ) : (
            "Save"
          )}
        </Button>

        <Button
          onClick={() => {
            const L3_ERROR = validateFormStep(level_three);
            if (!L3_ERROR) {
              setStep(4);
            }
          }}
          disabled={(courses.length < 1) || updating}
          type="button"
          className="ms-2 pull-right  py-3 btn-pigment-green"
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default ProfileLevelThree;
