/** @format */

import React, {  useState } from "react";
import { Col, Tab, Nav } from "react-bootstrap";
import FellowLayout from "../../Fellow";
import Assesments from "./containers/Assignements";
import Submissions from "./containers/Submissions";
// import useAllowedFellow from "hooks/useAllowedFellow";

const FellowAssesments = () => {
  const [activeTab, setActive] = useState("assignments");
  // useAllowedFellow();

  const tabData = [
    {
      name: "Assignments",
      title: "assignments",
    },
    {
      name: "Submissions",
      title: "submissions",
    },
  ];

  return (
    <FellowLayout>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">Assessments</h3>
      </div>

      <section className="custom-tab-1">
        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
          <Nav as="ul" className="nav-tabs">
            {tabData.map((data) => (
              <Nav.Item
                as="li"
                key={data.title}
                className="active"
                onClick={() => setActive(data.title)}
              >
                <Nav.Link eventKey={data.name.toLowerCase()}>
                  {data.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <div className="row mt-4">
            <Col xl={12}>
              <section className="custom-tab-1">
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  {activeTab === "assignments" && <Assesments />}
                  {activeTab === "submissions" && <Submissions />}
                </Tab.Container>
              </section>
            </Col>
          </div>
        </Tab.Container>
      </section>
    </FellowLayout>
  );
};

export default FellowAssesments;
