/** @format */

import { Dropdown, Modal, Button, Col } from "react-bootstrap";
import { ColumnFilter } from "./ColumnFilter";
import React, { useState } from "react";
import { capitalize, extractErrorMessage, mapStatusClass, UpdateFellowStatusOptions } from "utils/strings";
import { useUpdateFellowStatusMutation } from "store/services/adminAPI";
import swal from "sweetalert";
import { replaceSpace, mapStatusHeading } from "utils/strings";
import Select from "react-select";
import { Style } from "utils/select";
import dayjs from "dayjs";
import { isAfter, isBefore, isSameDay, isPast } from "date-fns";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useBulkMarkAttendanceMutation } from "store/services/providerAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import RankFellowModal from "../RankFellowModal";

export const COLUMNS = (selectedIds, handleRowSelection, cohorts) => [
  {
    Header: "",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          checked={selectedIds.includes(row?.original?.id) ? true : false}
          onChange={() => handleRowSelection(row?.original?.id)}
        />
      );
    },
  },
  {
    Header: "Name",
    accessor: "first_name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.first_name)} ${capitalize(
        cell?.row?.original?.last_name
      )}`;
    },
  },

  {
    Header: "Email",
    accessor: "email",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    disableFilters: true,
    sortable: false,
    Filter: ColumnFilter,
    Cell: ({ cell: { value } }) => {
      let _class = mapStatusClass(replaceSpace(value));      
      return (
        <span className={`badge light badge-${_class}`}>
          <i className="fa fa-circle text-danger me-1" />
          {mapStatusHeading(value)}
        </span>
      );
    },
  },
  {
    Header: "Fellow ID",
    accessor: "fellow_id",
    disableFilters: true,
    Filter: ColumnFilter,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.fellow_id)}`;
    },
  },
  {
    Header: "Cohort",
    accessor: "cohort",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      const _cohort = cell?.row?.original?.cohort ?? null;
      return _cohort ? `Cohort ${_cohort?.rank}` : "Unassigned";
    },
  },
  {
    Header: "Course",
    accessor: "current_learning_course",
    disableFilters: true,
    Filter: ColumnFilter,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      return `${capitalize(value)}`;
    },
  },
  {
    Header: "Progress",
    accessor: "training_progress",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell: { value } }) => {
      if (value === null) {
        return "0%";
      } else {
        return `${value}%`;
      }
    },
  },
  {
    Header: "Level",
    accessor: "proficiency_level",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      const _proficiency_level = cell?.row?.original?.proficiency_level ?? null;
      return _proficiency_level ? _proficiency_level : "not set"
    },
  },
  {
    Header: "Rating",
    accessor: "proficiency_rating",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      const _proficiency_rating = cell?.row?.original?.proficiency_rating ?? null;
      return _proficiency_rating ? _proficiency_rating : "not rated"
    },
  },

  {
    Header: "Actions",
    disableSortBy: true,
    sortable: false,
    accessor: "",
    Filter: ColumnFilter,
    disableFilters: true,
    Cell: ({ cell }) => {
      const [showProfile, setShowProfile] = useState(false);
      const [showAttendance, setShowAttendance] = useState(false);
      const [status, setStatus] = useState("");
      const [showStatusModal, setStatusModal] = useState(false);
      const [selectedCohort, setCohorts] = useState(null);
      const [schedule, setSchedule] = useState(null);
      const [showRank, setShowRank] = useState(false);

      const closeRankModal = () => {
        setShowRank(false)
      }
      
      const [bulkMarkAttendance, { isLoading }] =
        useBulkMarkAttendanceMutation();

      const [updateFellowStatus, { isLoading: updating }] =
        useUpdateFellowStatusMutation();

      const handleStatus = async () => {
        const payload = {
          id: cell?.row?.original?.id,
          status: status,
        };

        await updateFellowStatus(payload)
          .unwrap()
          .then((data) => {
            swal({
              title: "Success",
              text: data?.detail,
              buttons: {
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: true,
                  className: "btn btn-success btn-block",
                  closeModal: true,
                },
              },
              icon: "success",
            }).then(() => {
              setStatusModal(false);
            });
          })
          .catch((error) => {
            const formattedError = extractErrorMessage(error)
            swal({
              title: "Error occured",
              text: formattedError,
              buttons: {
                confirm: {
                  text: "Try Again",
                  value: true,
                  visible: true,
                  className: "btn btn-primary btn-block",
                  closeModal: true,
                },
              },
              icon: "error",
            });
          });
      };

      const {
        id,
        first_name,
        last_name,
        email,
        phone,
        age,
        skill_level,
        gender,
      } = cell?.row?.original;

      const CohortOptions =
        cohorts?.data?.cohorts?.length > 0
          ? cohorts?.data?.cohorts?.map((cohort) => ({
              value: cohort?.id,
              label: `Cohort ${cohort?.rank}`,
            }))
          : [];

      const handleSubmit = async () => {
        await bulkMarkAttendance({
          schedule_id: schedule,
          status: "present",
          fellows: [id],
          confirmation_page_link:
            "https://app.3mtt.training/fellow/confirm-attendance",
        })
          .unwrap()
          .then((data) => {
            toast.success(`✔️ ${data?.message} !`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setShowAttendance(false);
            setSchedule(null);
          })
          .catch((error) => {
            Swal.fire({
              title:
                replaceUnderscoresWithSpaces(error?.data?.code) ||
                "Error Occured",
              text: error?.data?.detail,
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
      };

      return (
        <>
        <RankFellowModal 
          showRank={showRank}
          closeRankModal={closeRankModal}
          fellowID={id}
        />
          {/* ProfileModal */}
          <Modal
            show={showProfile}
            onHide={() => setShowProfile(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"> Fellow Profile</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowProfile(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <div className="col-xl-12">
                  <div className="card  course-dedails-bx">
                    <div className="card-header border-0 pb-0">
                      <h2>
                        {capitalize(first_name)} &nbsp; {capitalize(last_name)}
                      </h2>
                    </div>
                    <div className="card-body pt-0">
                      <div className="description">
                        <p>{capitalize(email)}</p>
                        <ul className="d-flex align-items-center raiting flex-wrap">
                          <li>
                            <span className="font-w500">
                              {capitalize(phone)}
                            </span>
                          </li>
                          <li>{capitalize(gender)}</li>
                          <li>{capitalize(age)}</li>
                        </ul>
                        <div className="user-pic mb-3">
                          <span>{capitalize(skill_level)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    variant="primary"
                    onClick={(e) => setShowProfile(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          {/* Profile Modal */}

          {/* StatusModal */}
          <Modal
            show={showStatusModal}
            onHide={() => setStatusModal(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Fellow Status</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setStatusModal(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Col md={12}>
                      <p className="p-1 m-0">Status</p>
                      <div className="form-group mb-3">
                        <select
                          defaultValue={"option"}
                          id="inputState"
                          className="form-control"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          {UpdateFellowStatusOptions.map((_status) => {
                            return (
                              <option
                                disabled={
                                  cell?.row?.original?.status?.toLowerCase() ===
                                  _status?.value.toLowerCase()
                                }
                                value={_status?.value}
                              >
                                {_status?.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </form>
                </div>

                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    onClick={() => {
                      handleStatus();
                    }}
                    type="button"
                    className="ms-2 pull-right  py-3 btn-pigment-green"
                    disabled={status?.length < 1}
                  >
                    {updating ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          {/* StatusModal */}

          {/* AttendanceModal */}
          <Modal
            show={showAttendance}
            onHide={() => setShowAttendance(false)}
            className="modal fade"
            id="statusModal"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Mark Fellow Attendance</h5>
                <Button
                  variant=""
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => setShowAttendance(false)}
                >
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <div className="col-xl-12">
                  <div className="course-dedails-bx">
                    <div className="pt-0 d-flex flex-column">
                      {CohortOptions?.length > 0 && (
                        <Col md={12}>
                          <p className="p-1 m-0">Choose Cohort</p>
                          <div className="form-group mb-3">
                            <Select
                              value={selectedCohort}
                              onChange={setCohorts}
                              options={CohortOptions}
                              styles={Style}
                            />
                          </div>
                        </Col>
                      )}

                      {selectedCohort && (
                        <Col md={12}>
                          <p className="p-1 m-0">Weeks</p>
                          <div className="form-group mb-3">
                            {cohorts?.data?.cohorts
                              ?.filter(
                                (_cohort) =>
                                  _cohort?.id === selectedCohort?.value
                              )[0]
                              ?.schedules?.map((_sche) => {
                                const startDate = new Date(_sche?.start_date);
                                const endDate = new Date(_sche?.end_date);
                                const currentDate = new Date();

                                const isEligible =
                                  isAfter(currentDate, startDate) &&
                                  (isBefore(currentDate, endDate) ||
                                    isSameDay(currentDate, endDate));

                                  const isPassed = isPast(endDate);

                                return (
                                  <div
                                    className={`d-flex align-items-center justify-content-between weeks ml-2 mb-2 ${
                                      isEligible ? "" : "disabled"
                                    }`}
                                  >
                                    <p className="mr-2">
                                      Week {_sche?.week_number} (
                                      {dayjs(_sche?.start_date).format(
                                        "MMMM D, YYYY"
                                      )}
                                      {" - "}
                                      {dayjs(_sche?.end_date).format(
                                        "MMMM D, YYYY"
                                      )}
                                      )
                                    </p>
                                    <div>
                                      <input
                                        type="checkbox"
                                        disabled={isPassed ? true : false}
                                        checked={
                                          schedule === _sche?.id || isPassed ? true : false
                                        }
                                        onChange={() => {
                                          schedule === _sche?.id
                                            ? setSchedule(null)
                                            : setSchedule(_sche?.id);
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </Col>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <Button
                    variant="primary"
                    disabled={id && selectedCohort && schedule ? false : true}
                    type="button"
                    onClick={() => {
                      Swal.fire({
                        title: "Confirm Action",
                        text: "Are you sure you want to proceed with this action?",
                        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                        showCloseButton: true,
                        customClass: {
                          icon: "no-border",
                        },

                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: "Proceed",
                        cancelButtonText: "Cancel",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleSubmit();
                        }
                      });
                    }}
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      ` Mark`
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          {/* AttendanceModal */}

          <Dropdown className="dropdown ms-auto text-right">
            <Dropdown.Toggle
              variant=""
              className="btn-link i-false"
              data-toggle="dropdown"
            >
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                  <rect x={0} y={0} width={24} height={24} />
                  <circle fill="#000000" cx={5} cy={12} r={2} />
                  <circle fill="#000000" cx={12} cy={12} r={2} />
                  <circle fill="#000000" cx={19} cy={12} r={2} />
                </g>
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Dropdown.Item onClick={() => setStatusModal(true)}>
                Update Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowProfile(true)}>
                View Profile
              </Dropdown.Item>
              {cell?.row?.original?.status?.toLowerCase() === "selected" && (
                <>
                <Dropdown.Item onClick={() => setShowAttendance(true)}>
                  Mark Attendance
                </Dropdown.Item>
              </>
              )}
              <Dropdown.Item onClick={() => setShowRank(true)}>
                Rank 
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      );
    },
  },
];


export const ATTENDANCE_COLUMNS = (selectedIds, handleRowSelection,  cohort) => [
  {
    Header: "",
    accessor: "id",
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <input
          type="checkbox"
          checked={selectedIds.includes(row?.original?.id) ? true : false}
          onChange={() => handleRowSelection(row?.original?.id)}
        />
      );
    },
  },
  {
    Header: "Name",
    accessor: "first_name",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.first_name)} ${capitalize(
        cell?.row?.original?.last_name
      )}`;
    },
  },

  {
    Header: "Fellow ID",
    accessor: "fellow_id",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell }) => {
      return `${capitalize(cell?.row?.original?.fellow_id)}`;
    },
  },

  {
    Header: "Attendance Marked",
    accessor: "attendancess",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell}) => {
      const attendances = cell?.row?.original?.attendances ?? [] 
      return attendances.length > 0 ?  `Marked` : 'Not Marked';

    },
  },


 
  {
    Header: "Attendance Confirmed",
    accessor: "attendances",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: ({ cell}) => {
      const attendances = cell?.row?.original?.attendances ?? [] 
      if (attendances.length > 0) {
        return attendances[0]?.fellow_confirmed ? `Confirmed ` : `Not Confirmed`
      }
      return "Not Confirmed"
    },
  },


 
  {
    Header: "Cohort",
    accessor: "cohort",
    Filter: ColumnFilter,
    disableFilters: true,
    sortable: false,
    Cell: () => {
      return cohort?.label
    },
  },
  
 
  // {
  //   Header: "Actions",
  //   disableSortBy: true,
  //   sortable: false,
  //   accessor: "",
  //   Filter: ColumnFilter,
  //   disableFilters: true,
  //   Cell: ({ cell }) => {
  //     const [showProfile, setShowProfile] = useState(false);
  //     const [showAttendance, setShowAttendance] = useState(false);
  //     const [status, setStatus] = useState("");
  //     const [showStatusModal, setStatusModal] = useState(false);
  //     const [selectedCohort, setCohorts] = useState(null);
  //     const [schedule, setSchedule] = useState(null);

  //     const [bulkMarkAttendance, { isLoading }] =
  //       useBulkMarkAttendanceMutation();

  //     const [updateFellowStatus, { isLoading: updating }] =
  //       useUpdateFellowStatusMutation();

  //     const handleStatus = async () => {
  //       const payload = {
  //         id: cell?.row?.original?.id,
  //         status: status,
  //       };

  //       await updateFellowStatus(payload)
  //         .unwrap()
  //         .then((data) => {
  //           swal({
  //             title: "Success",
  //             text: data?.detail,
  //             buttons: {
  //               confirm: {
  //                 text: "Ok",
  //                 value: true,
  //                 visible: true,
  //                 className: "btn btn-success btn-block",
  //                 closeModal: true,
  //               },
  //             },
  //             icon: "success",
  //           }).then(() => {
  //             setStatusModal(false);
  //           });
  //         })
  //         .catch((error) => {
  //           swal({
  //             title: "Error occured",
  //             text: error?.data?.detail,
  //             buttons: {
  //               confirm: {
  //                 text: "Try Again",
  //                 value: true,
  //                 visible: true,
  //                 className: "btn btn-primary btn-block",
  //                 closeModal: true,
  //               },
  //             },
  //             icon: "error",
  //           });
  //         });
  //     };

  //     const {
  //       id,
  //       first_name,
  //       last_name,
  //       email,
  //       phone,
  //       age,
  //       skill_level,
  //       gender,
  //     } = cell?.row?.original;

  //     const CohortOptions =
  //       cohorts?.data?.cohorts?.length > 0
  //         ? cohorts?.data?.cohorts?.map((cohort) => ({
  //             value: cohort?.id,
  //             label: `Cohort ${cohort?.rank}`,
  //           }))
  //         : [];

  //     const handleSubmit = async () => {
  //       await bulkMarkAttendance({
  //         schedule_id: schedule,
  //         status: "present",
  //         fellows: [id],
  //         confirmation_page_link:
  //           "https://app.3mtt.training/fellow/confirm-attendance",
  //       })
  //         .unwrap()
  //         .then((data) => {
  //           toast.success(`✔️ ${data?.message} !`, {
  //             position: "top-right",
  //             autoClose: 2500,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //           });
  //           setShowAttendance(false);
  //           setSchedule(null);
  //         })
  //         .catch((error) => {
  //           Swal.fire({
  //             title:
  //               replaceUnderscoresWithSpaces(error?.data?.code) ||
  //               "Error Occured",
  //             text: error?.data?.detail,
  //             iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
  //             showCloseButton: true,
  //             customClass: {
  //               icon: "no-border",
  //             },
  //             confirmButtonText: "Try Again",
  //           });
  //         });
  //     };

  //     return (
  //       <>
  //         {/* ProfileModal */}
  //         <Modal
  //           show={showProfile}
  //           onHide={() => setShowProfile(false)}
  //           className="modal fade"
  //           id="statusModal"
  //         >
  //           <div className="modal-content">
  //             <div className="modal-header">
  //               <h5 className="modal-title"> Fellow Profile</h5>
  //               <Button
  //                 variant=""
  //                 type="button"
  //                 className="close"
  //                 data-dismiss="modal"
  //                 onClick={() => setShowProfile(false)}
  //               >
  //                 <span>×</span>
  //               </Button>
  //             </div>
  //             <div className="modal-body">
  //               <div className="col-xl-12">
  //                 <div className="card  course-dedails-bx">
  //                   <div className="card-header border-0 pb-0">
  //                     <h2>
  //                       {capitalize(first_name)} &nbsp; {capitalize(last_name)}
  //                     </h2>
  //                   </div>
  //                   <div className="card-body pt-0">
  //                     <div className="description">
  //                       <p>{capitalize(email)}</p>
  //                       <ul className="d-flex align-items-center raiting flex-wrap">
  //                         <li>
  //                           <span className="font-w500">
  //                             {capitalize(phone)}
  //                           </span>
  //                         </li>
  //                         <li>{capitalize(gender)}</li>
  //                         <li>{capitalize(age)}</li>
  //                       </ul>
  //                       <div className="user-pic mb-3">
  //                         <span>{capitalize(skill_level)}</span>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="d-flex w-100 justify-content-center align-items-center">
  //                 <Button
  //                   variant="primary"
  //                   onClick={(e) => setShowProfile(false)}
  //                 >
  //                   Close
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         </Modal>
  //         {/* Profile Modal */}

  //         {/* StatusModal */}
  //         <Modal
  //           show={showStatusModal}
  //           onHide={() => setStatusModal(false)}
  //           className="modal fade"
  //           id="statusModal"
  //         >
  //           <div className="modal-content">
  //             <div className="modal-header">
  //               <h5 className="modal-title">Update Fellow Status</h5>
  //               <Button
  //                 variant=""
  //                 type="button"
  //                 className="close"
  //                 data-dismiss="modal"
  //                 onClick={() => setStatusModal(false)}
  //               >
  //                 <span>×</span>
  //               </Button>
  //             </div>
  //             <div className="modal-body">
  //               <div className="basic-form">
  //                 <form onSubmit={(e) => e.preventDefault()}>
  //                   <Col md={12}>
  //                     <p className="p-1 m-0">Status</p>
  //                     <div className="form-group mb-3">
  //                       <select
  //                         defaultValue={"option"}
  //                         id="inputState"
  //                         className="form-control"
  //                         onChange={(e) => {
  //                           setStatus(e.target.value);
  //                         }}
  //                       >
  //                         {StatusOptions.map((_status) => {
  //                           return (
  //                             <option
  //                               disabled={
  //                                 cell?.row?.original?.status?.toLowerCase() ===
  //                                 _status?.value.toLowerCase()
  //                               }
  //                               value={_status?.value}
  //                             >
  //                               {_status?.label}
  //                             </option>
  //                           );
  //                         })}
  //                       </select>
  //                     </div>
  //                   </Col>
  //                 </form>
  //               </div>

  //               <div className="d-flex w-100 justify-content-center align-items-center">
  //                 <Button
  //                   onClick={() => {
  //                     handleStatus();
  //                   }}
  //                   type="button"
  //                   className="ms-2 pull-right  py-3 btn-pigment-green"
  //                   disabled={status?.length < 1}
  //                 >
  //                   {updating ? (
  //                     <span
  //                       role="status"
  //                       aria-hidden="true"
  //                       className="spinner-border spinner-border-sm ms-2"
  //                     ></span>
  //                   ) : (
  //                     "Update"
  //                   )}
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         </Modal>
  //         {/* StatusModal */}

  //         {/* AttendanceModal */}
  //         <Modal
  //           show={showAttendance}
  //           onHide={() => setShowAttendance(false)}
  //           className="modal fade"
  //           id="statusModal"
  //         >
  //           <div className="modal-content">
  //             <div className="modal-header">
  //               <h5 className="modal-title">Mark Fellow Attendance</h5>
  //               <Button
  //                 variant=""
  //                 type="button"
  //                 className="close"
  //                 data-dismiss="modal"
  //                 onClick={() => setShowAttendance(false)}
  //               >
  //                 <span>×</span>
  //               </Button>
  //             </div>
  //             <div className="modal-body">
  //               <div className="col-xl-12">
  //                 <div className="course-dedails-bx">
  //                   <div className="pt-0 d-flex flex-column">
  //                     {CohortOptions?.length > 0 && (
  //                       <Col md={12}>
  //                         <p className="p-1 m-0">Choose Cohort</p>
  //                         <div className="form-group mb-3">
  //                           <Select
  //                             value={selectedCohort}
  //                             onChange={setCohorts}
  //                             options={CohortOptions}
  //                             styles={Style}
  //                           />
  //                         </div>
  //                       </Col>
  //                     )}

  //                     {selectedCohort && (
  //                       <Col md={12}>
  //                         <p className="p-1 m-0">Weeks</p>
  //                         <div className="form-group mb-3">
  //                           {cohorts?.data?.cohorts
  //                             ?.filter(
  //                               (_cohort) =>
  //                                 _cohort?.id === selectedCohort?.value
  //                             )[0]
  //                             ?.schedules?.map((_sche) => {
  //                               const startDate = new Date(_sche?.start_date);
  //                               const endDate = new Date(_sche?.end_date);
  //                               const currentDate = new Date();

  //                               const isEligible =
  //                                 isAfter(currentDate, startDate) &&
  //                                 (isBefore(currentDate, endDate) ||
  //                                   isSameDay(currentDate, endDate));

  //                                 const isPassed = isPast(endDate);

  //                               return (
  //                                 <div
  //                                   className={`d-flex align-items-center justify-content-between weeks ml-2 mb-2 ${
  //                                     isEligible ? "" : "disabled"
  //                                   }`}
  //                                 >
  //                                   <p className="mr-2">
  //                                     Week {_sche?.week_number} (
  //                                     {dayjs(_sche?.start_date).format(
  //                                       "MMMM D, YYYY"
  //                                     )}
  //                                     {" - "}
  //                                     {dayjs(_sche?.end_date).format(
  //                                       "MMMM D, YYYY"
  //                                     )}
  //                                     )
  //                                   </p>
  //                                   <div>
  //                                     <input
  //                                       type="checkbox"
  //                                       disabled={isPassed ? true : false}
  //                                       checked={
  //                                         schedule === _sche?.id || isPassed ? true : false
  //                                       }
  //                                       onChange={() => {
  //                                         schedule === _sche?.id
  //                                           ? setSchedule(null)
  //                                           : setSchedule(_sche?.id);
  //                                       }}
  //                                     />
  //                                   </div>
  //                                 </div>
  //                               );
  //                             })}
  //                         </div>
  //                       </Col>
  //                     )}
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="d-flex w-100 justify-content-center align-items-center">
  //                 <Button
  //                   variant="primary"
  //                   disabled={id && selectedCohort && schedule ? false : true}
  //                   type="button"
  //                   onClick={() => {
  //                     Swal.fire({
  //                       title: "Confirm Action",
  //                       text: "Are you sure you want to proceed with this action?",
  //                       iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
  //                       showCloseButton: true,
  //                       customClass: {
  //                         icon: "no-border",
  //                       },

  //                       showCancelButton: true,
  //                       reverseButtons: true,
  //                       confirmButtonText: "Proceed",
  //                       cancelButtonText: "Cancel",
  //                     }).then((result) => {
  //                       if (result.isConfirmed) {
  //                         handleSubmit();
  //                       }
  //                     });
  //                   }}
  //                 >
  //                   {isLoading ? (
  //                     <span
  //                       role="status"
  //                       aria-hidden="true"
  //                       className="spinner-border spinner-border-sm ms-2"
  //                     ></span>
  //                   ) : (
  //                     ` Mark`
  //                   )}
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //         </Modal>
  //         {/* AttendanceModal */}

  //         <Dropdown className="dropdown ms-auto text-right">
  //           <Dropdown.Toggle
  //             variant=""
  //             className="btn-link i-false"
  //             data-toggle="dropdown"
  //           >
  //             <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
  //               <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
  //                 <rect x={0} y={0} width={24} height={24} />
  //                 <circle fill="#000000" cx={5} cy={12} r={2} />
  //                 <circle fill="#000000" cx={12} cy={12} r={2} />
  //                 <circle fill="#000000" cx={19} cy={12} r={2} />
  //               </g>
  //             </svg>
  //           </Dropdown.Toggle>
  //           <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
  //             <Dropdown.Item onClick={() => setStatusModal(true)}>
  //               Update Status
  //             </Dropdown.Item>
  //             <Dropdown.Item onClick={() => setShowProfile(true)}>
  //               View Profile
  //             </Dropdown.Item>
  //             {cell?.row?.original?.status?.toLowerCase() === "selected" && (
  //               <Dropdown.Item onClick={() => setShowAttendance(true)}>
  //                 Mark Attendance
  //               </Dropdown.Item>
  //             )}
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </>
  //     );
  //   },
  // },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "First Name",
        Footer: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "last_name",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Date of  Birth",
        Footer: "Date of  Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];
