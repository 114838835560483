/** @format */

import { createSlice } from "@reduxjs/toolkit";
import * as actionTypes from "./../actions/actionTypes";

// export const getProfileInfo = (payload) => async (dispatch) => {
//     if (payload?.access_token) {
//         await dispatch(setIsProfile(true));
//         await dispatch(setCurrentUser(payload?.user_data));
//     }
// };

const initialState = {
  isLoading: false,
  error: {},
  profileInfo: {},
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

export const { setLoading } = profileSlice.actions;

export const profileSelector = (state) => state.profile;
const profileSliceReducer = profileSlice.reducer;
export { profileSliceReducer };

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROVIDER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.PROVIDER_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profileInfo: action.payload,
      };
    case actionTypes.PROVIDER_PROFILE_KYB_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.PROVIDER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
