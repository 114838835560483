/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import { getProviderProfile } from "store/actions/profile";
import CountdownTimer from "./timer";
import Swal from "sweetalert2";
import { useGetFellowTestQuery } from "store/services/adminAPI";
import { extractErrorMessage, replaceUnderscoresWithSpaces } from "utils/strings";
import { useSubmitFellowTestMutation } from "store/services/adminAPI";
import FellowLayout from "../../Fellow";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";

const FellowSkillTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [questions, setQuestions] = useState([]);
  const [testDuration, setDuration] = useState(null);
  const [answers, setAnswers] = useState(null);

  const [proceedToTest, setProceedToTest] = useState(true);
  const [timeElapsed, setTimeElapsed] = useState(false);

  const [welcome, setWelcome] = useState(true);
  const {
    data: testInformation,
    isLoading: fetchingQuestions,
    error,
    isError,
  } = useGetFellowTestQuery(undefined, {
    skip: proceedToTest,
  });

  const [submitFellowTest, { isLoading: submittiing }] =
    useSubmitFellowTestMutation();

  const { profileInfo, isLoading } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;

  useEffect(() => {
    dispatch(getProviderProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profileInfo?.data?.profile) {
      if (profileInfo?.data?.profile?.is_skill_test_taken) {
        toast.success(`✔️ You have aleady taken this test!`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
        });
        navigate(Path.FELLOW_DASHBOARD);
      } else {
        if (
          !profileInfo?.data?.profile?.is_skill_profile_updated ||
          !profileInfo?.data?.profile?.is_questionaire_updated
        ) {
          Swal.fire({
            title: "Test Notice",
            text: "You have either not completed  our questionaire or you have not confirmed your course ,kindly go to the dashbaord to do this",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(() => {
            navigate(Path.FELLOW_DASHBOARD);
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo]);

  useEffect(() => {
    if (testInformation) {
      setDuration(testInformation?.test_duration);
      setQuestions([...testInformation?.data]);
      let ans = {};
      testInformation?.data.forEach((_question) => {
        ans[_question?.id] = "";
      });
      setAnswers({ ...ans });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileInfo, testInformation]);

  const handleTestSubmit = async () => {
    await submitFellowTest(answers)
      .unwrap()
      .then(() => {
        toast.success(
          `✔️ Skill test completed, you will be redirected to your dashboard in some moment!`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          }
        );
        navigate(Path.FELLOW_DASHBOARD);
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Ok",
        }).then(() => {
          navigate(Path.FELLOW_DASHBOARD);
        });
      });
  };

  const handleTimeout = async () => {
    setTimeElapsed(true);
    handleTestSubmit();
    Swal.fire({
      title: "Time Elapsed",
      text: `Your time for this test has elapsed and your test has been automatically submitted`,
      iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
      customClass: {
        icon: "no-border",
      },
      confirmButtonText: "Ok",
    });
  };

  return (
    <Fragment>
      <FellowLayout>
        {isLoading || fetchingQuestions ? (
          <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
            <FadeLoader color="#36d7b7" />
          </div>
        ) : isError ? (
          <div className="card">
            <div className="card-body text-center ai-icon">
              <BagIcon />
              <h4 className="my-2">Questionnaire Error</h4>
              <p>
                {error?.data?.detail ||
                  "Error occured setting up your Questionnaire"}
              </p>
              <button
                className="btn my-2 btn-primary btn-lg px-4"
                onClick={() => navigate(Path.FELLOW_DASHBOARD)}
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        ) : (
          <Fragment>
            {!proceedToTest && (
              <div className="row">
                {timeElapsed ? null : (
                  <div className="col-xl-6 col-xxl-12 d-flex justify-content-between align-itens-center">
                    <h2> {profile?.current_learning_course}&nbsp;Skill Test</h2>

                    {testDuration && (
                      <CountdownTimer
                        initialTime={testDuration}
                        onTimeout={handleTimeout}
                      />
                    )}
                  </div>
                )}

                {questions.length > 0 &&
                  questions.map((_question) => {
                    return (
                      <div key={_question?.id} className="card">
                        <div className="card-header">
                          <h4 className="card-title text-trans-none">
                            {_question?.question}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group mb-0">
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      name="answer"
                                      value={_question?.options?.a}
                                      onChange={(e) => {
                                        setAnswers((prevState) => ({
                                          ...prevState,
                                          [_question?.id]: "a",
                                        }));
                                      }}
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {_question?.options?.a}
                                    </span>
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value={_question?.options?.b}
                                      onChange={(e) => {
                                        setAnswers((prevState) => ({
                                          ...prevState,
                                          [_question?.id]: "b",
                                        }));
                                      }}
                                      name="answer"
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {_question?.options?.b}
                                    </span>
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value={_question?.options?.c}
                                      onChange={(e) => {
                                        setAnswers((prevState) => ({
                                          ...prevState,
                                          [_question?.id]: "c",
                                        }));
                                      }}
                                      name="answer"
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {_question?.options?.c}
                                    </span>
                                  </label>
                                </div>
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value={_question?.options?.d}
                                      onChange={(e) => {
                                        setAnswers((prevState) => ({
                                          ...prevState,
                                          [_question?.id]: "d",
                                        }));
                                      }}
                                      name="answer"
                                    />
                                    <span
                                      style={{
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {_question?.options?.d}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                <div className="col-xxl-12">
                  <div className="form-group mb-3 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => {
                        Swal.fire({
                          title: "Confirm Submisssion",
                          text: " You can't undo this action once you proceed, are you sure you want to continue?",
                          icon: "success",
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                          reverseButtons: true,
                          showCancelButton: true,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            handleTestSubmit();
                          }
                        });
                      }}
                      className="submit btn btn-pigment-green"
                    >
                      {submittiing ? (
                        <span
                          role="status"
                          aria-hidden="true"
                          className="spinner-border spinner-border-sm ms-2"
                        ></span>
                      ) : (
                        " Submit Answers"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            <Modal className="fade" show={welcome} size="lg">
              <Modal.Header>
                <Modal.Title>
                  <h2>Skill Test Information</h2>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>
                  You will be required to answer 15 questions in 5 minutes to
                  complete the test.
                </h5>

                <p>
                  Please note that the responses you provide will not be used
                  for assessment, but will help us understand how to design the
                  best learning pathway for you.
                </p>
                {/* <p>
                  Pace Yourself: Manage your time wisely. Don't get stuck on
                  difficult questions; mark them and return later if permitted.
                </p>
                <p>
                  Eliminate Distractions: If you're taking the test on a
                  computer, ensure a distraction-free environment.
                </p>
                <p>Double-Check: Review your answers if time allows.</p>
                <p>
                  Follow Directions: Stick to any specific instructions for each
                  section.
                </p>

                <p className="text-danger">
                  If you are not ready to take the test, click go back to
                  dashboard
                </p>
                <p className="text-success">
                  If you are ready to take the test, click proceed to test
                </p> */}
                <p className="text-danger">
                  If you are not ready to take the test, click "Go back to
                  dashboard"
                </p>
                <p className="text-success">
                  If you are ready to take the test, click "Proceed to test"
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => {
                    setWelcome(false);
                    navigate(Path.FELLOW_DASHBOARD);
                  }}
                  className="btn btn-error"
                >
                  Go back to Dashboard
                </button>

                <button
                  onClick={() => {
                    setProceedToTest(false);
                    setWelcome(false);
                  }}
                  className="btn btn-pigment-green"
                >
                  Proceed To Test
                </button>
              </Modal.Footer>
            </Modal>
          </Fragment>
        )}
      </FellowLayout>
    </Fragment>
  );
};

export default FellowSkillTest;
