/** @format */

import React from "react";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces, capitalize, extractErrorMessage } from "utils/strings";
import { toast } from "react-toastify";
import { useCreateNewBroadcastMutation } from "store/services/notification";

const CreateBroadcast = ({ createBroadcast, setCreate, messageClass }) => {
  const [createNewBroadcast, { isLoading }] = useCreateNewBroadcastMutation();

  const handleSubmit = async (values, { resetForm }) => {
    await createNewBroadcast({
      ...values,
      recipient_type: "fellow",
      message_type: "info",
      message_class: messageClass,
    })
      .unwrap()
      .then((data) => {
        toast.success(`✔️ ${capitalize(messageClass)} sent !`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
        });

        resetForm();
        setCreate(false);
      })
      .catch((error) => {
        const formattedError = extractErrorMessage(error)
        Swal.fire({
          title:
            replaceUnderscoresWithSpaces(error?.data?.code) || "Error Occured",
          text: formattedError,
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Try Again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      message_title: "",
      message_body: "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal className="modal fade" show={createBroadcast}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create new {messageClass}</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => setCreate(false)}
          >
            <span>×</span>
          </Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Title</strong>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="message_title"
                    name="message_title"
                    placeholder="Title"
                    {...formik.getFieldProps("message_title")}
                  />

                  {formik.touched.message_title &&
                  formik.errors.message_title ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.message_title}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="mb-1">
                    <strong>Content</strong>
                  </label>

                  <textarea
                    className="w-100 form-control"
                    placeholder="Your answer"
                    id="message_body"
                    name="message_body"
                    {...formik.getFieldProps("message_body")}
                    rows="3"
                  ></textarea>

                  {formik.touched.message_body && formik.errors.message_body ? (
                    <div className="text-danger mt-2 fs-12">
                      {formik.errors.message_body}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 d-flex justify-content-end">
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="submit btn btn-pigment-green"
                  >
                    {isLoading ? (
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm ms-2"
                      ></span>
                    ) : (
                      `Create ${capitalize(messageClass)}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBroadcast;

const FormSchema = Yup.object().shape({
  message_title: Yup.string().required("Title is required"),
  message_body: Yup.string().required("Message body is required"),
});
