import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useResetUserEmailMutation } from "store/services/authAPI";
import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import loginbg from "../../images/bg-1.svg";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";
import MMLogo from "images/3MTT/3MM.png";

const ResetEmailPage = () => {
  const [resetting, setResetting] = useState(false);
  const navigate = useNavigate();
  const [resetUserEmail] = useResetUserEmailMutation();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const current_node = searchParams.get("current_node");
  const new_node =  searchParams.get("new_node")

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (token && new_node) {
      setResetting(true);
      await resetUserEmail({
        token: token,
        current_node: current_node,
        new_node: new_node,
      })
        .unwrap()
        .then((data) => {
          Swal.fire({
            title: "Email successfully changed",
            icon: "success",
            text: data?.detail,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
          }).then(() => {
            navigate(Path.LOGIN);
          });
        })
        .catch((error) => {
          const errorText = error?.data?.detail;
          const btnText = "Try Again";

          Swal.fire({
            title: error?.data?.code
              ? replaceUnderscoresWithSpaces(error?.data?.code)
              : "Error Occurred",
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            text: errorText || "Error Occurred",
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            cancelButtonText: btnText,
            showConfirmButton: false,
            showCancelButton: true,
          });
        })
        .finally(() => {
          setResetting(false);
        });
    } else {
      Swal.fire({
        title: "Error",
        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        text: "Error Occurred",
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        cancelButtonText: "Ok",
        showConfirmButton: false,
        showCancelButton: true,
      });
    }
  };


  if (!token || !new_node || !current_node) {
    return <Navigate to={Path.LOGIN} />;
  }

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center mb-4 ">Reset Email</h4>

                      <form className="form" onSubmit={(e)=>handleSubmit(e)}>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>New Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="new_node"
                            name="new_node"
                            placeholder="e.g. example@domainname.com"
                            value={new_node}
                            disabled={true}
                          />
                        </div>
                        <div className="row d-flex justify-content-between mt-2 mb-2">
                          <p className="">
                            <Link className="text-primary" to={Path.LOGIN}>
                              Back to Login
                            </Link>
                          </p>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {resetting ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              "Confirm Email Reset"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmailPage;
