/** @format */
import { useGetFellowCourseModulesQuery } from "store/services/adminAPI";
import React, { Fragment } from "react";
import { FadeLoader } from "react-spinners";
import { Col, Card, ListGroup } from "react-bootstrap";
import EmptyNotice from "component/ErrorNotice";

const CommunityCluster  = () => {
  const { data: courses, isLoading: fetchingCourses } =
    useGetFellowCourseModulesQuery('community');

  return (
    <Col xl="12">
      {fetchingCourses ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
        <FadeLoader color="#36d7b7" />
      </div>
      ) : (
        <Card>
          <Card.Header className="d-block">
            <Card.Title>Cluster Information</Card.Title>
            <Card.Text className="m-0 subtitle">
            Check regularly for cluster Information
            </Card.Text>
          </Card.Header>

          {courses &&
            (courses?.data?.length > 120 ? (
              <Card.Body className="pt-0 community-card cscrollable">
                <ListGroup variant="flush">
                  {courses?.data.map((list) => (
                    <Fragment key={list?.link}>
                      <ListGroup.Item
                        target="_blank"
                        href={list.link}
                        as="a"
                        className="link-hover"
                      >
                        {list.name}
                      </ListGroup.Item>
                    </Fragment>
                  ))}
                  <ListGroup.Item target="_blank"></ListGroup.Item>
                </ListGroup>
              </Card.Body>
            ) : (
              <Card.Body>
                <EmptyNotice text="No cluster information yet" />
              </Card.Body>
            ))}
        </Card>
      )}
    </Col>
  );
};

export default CommunityCluster;
