/** @format */
import { Col, Tab, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ProviderLayout from "views/providers/Provider";
import ProviderAssigments from "./Assignments";
import ProviderSubmissions from "./Submissions";
import { useLocation } from "react-router-dom";

const ProviderAssesments = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("tab");
  const [activeTab, setActive] = useState(tab ? tab : "assesments");

  const tabData = [
    {
      name: "Assesments",
      title: "assesments",
    },
    {
      name: "Submissions",
      title: "submissions",
    },
  ];


  useEffect(() => {
    
  },[tab])

  return (
    <ProviderLayout>
      <div className="widget-heading d-flex justify-content-between align-items-center">
        <h3 className="m-0">Assessments</h3>
      </div>

      <div className="row">
        <Col xl={12}>
          <section className="custom-tab-1">
            <Tab.Container
              defaultActiveKey={tabData[
                tab === "submissions" ? 1 : 0
              ].name.toLowerCase()}
            >
              <Nav as="ul" className="nav-tabs mb-4">
                {tabData.map((data) => (
                  <Nav.Item
                    as="li"
                    key={data.title}
                    onClick={() => {
                      setActive(data.title);
                    }}
                  >
                    <Nav.Link eventKey={data.name.toLowerCase()}>
                      {data.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              {activeTab === "assesments" && <ProviderAssigments tab={activeTab}  />}
              {activeTab === "submissions" && (
                <ProviderSubmissions tab={activeTab} />
              )}
            </Tab.Container>
          </section>
        </Col>
      </div>
    </ProviderLayout>
  );
};
export default ProviderAssesments;
