/** @format */

import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import loginbg from "images/bg-1.svg";
import MMLogo from "images/3MTT/3MM.png";
import { Path } from "utils/constant.data";

import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useForgotPasswordMutation } from "store/services/authAPI";
import { replaceUnderscoresWithSpaces } from "utils/strings";

function ForgotPasswordPage() {
  const [forgotPassword, { isLoading: requesting }] =
    useForgotPasswordMutation();

  const handleSubmit = async (values) => {
    await forgotPassword({
      redirect_url: `${process.env.REACT_APP_FRONTEND_URL}reset-password`,
      email: values.email,
    })
      .unwrap()
      .then((data) => {
        Swal.fire({
          title: "Notice",
          icon: "success",
          text: data?.detail,
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        const errorText = error?.data?.detail;
        const btnText = "Try Again";

        Swal.fire({
          title: error?.data?.code
            ? replaceUnderscoresWithSpaces(error?.data?.code)
            : "Error Occured",
          iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
          text: errorText || "Error Occured",
          showCloseButton: true,
          customClass: {
            icon: "no-border",
          },
          cancelButtonText: btnText,
          showConfirmButton: false,
          showCancelButton: true,
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center mb-4 ">Forgot Password</h4>

                      <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>Email </strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="e.g. example@domainname.com"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="row d-flex justify-content-between mt-2 mb-2">
                          <p className="">
                            <Link className="text-primary" to={Path.LOGIN}>
                              Back to Login
                            </Link>
                          </p>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {requesting ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              "Request Link"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;

const UserSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
});
