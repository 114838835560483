/** @format */

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { Path } from "utils/constant.data";

const useSelectedFellow = () => {
  const navigate = useNavigate();
  const { profileInfo } = useSelector(profileSelector);
  const profile = profileInfo?.data?.profile;

  useEffect(() => {
    if (profile) {
      const isProfileValid =
        (profile?.status?.toLowerCase() === "selected" || profile?.status?.toLowerCase() === "certified" || profile?.status?.toLowerCase() === "completed_alc_training")  &&
        profile?.consent_accepted;

      if (!isProfileValid) {
        navigate(Path.FELLOW_DASHBOARD);
      }
    }
  }, [navigate, profile]);
};

export default useSelectedFellow;
