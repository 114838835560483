/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useResetPasswordMutation } from "store/services/authAPI";
import { useFormik } from "formik";
import MMLogo from "images/3MTT/3MM.png";

import Swal from "sweetalert2";
import { replaceUnderscoresWithSpaces } from "utils/strings";

import loginbg from "../../images/bg-1.svg";

import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { Path } from "utils/constant.data";

const ResetPasswordPage = () => {
  const [isShown, setIsSHown] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [resetPassword, { isLoading: resetting }] = useResetPasswordMutation();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const token = searchParams.get("token");
  const node = searchParams.get("node");

  const handleSubmit = async (values) => {
    if (token && node) {
      await resetPassword({
        node,
        password: values.password,
        token,
      })
        .unwrap()
        .then((data) => {
          Swal.fire({
            title: "Notice",
            icon: "success",
            text: data?.detail,
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            confirmButtonText: "Ok",
          }).then(() => {
            navigate(Path.LOGIN);
          });
        })
        .catch((error) => {
          const errorText = error?.data?.detail;
          const btnText = "Try Again";

          Swal.fire({
            title: error?.data?.code
              ? replaceUnderscoresWithSpaces(error?.data?.code)
              : "Error Occured",
            iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
            text: errorText || "Error Occured",
            showCloseButton: true,
            customClass: {
              icon: "no-border",
            },
            cancelButtonText: btnText,
            showConfirmButton: false,
            showCancelButton: true,
          });
        });
    } else {
      Swal.fire({
        title: "Error",
        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        text: "Error Occured",
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        cancelButtonText: "Ok",
        showConfirmButton: false,
        showCancelButton: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: PasswordSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  const toggleConfirm = () => {
    setShow((show) => !show);
  };

  if (!token || !node) {
    return <Navigate to={Path.LOGIN} />;
  }

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>
                      <h4 className="text-center mb-4 ">Reset Password</h4>

                      <form className="form" onSubmit={formik.handleSubmit}>
                        <div className="mb-3 from-group">
                          <label className="mb-1">
                            <strong>Password</strong>
                          </label>

                          <div className="password__container">
                            <input
                              type={isShown ? "text" : "password"}
                              id="password"
                              name="password"
                              placeholder="Enter Password"
                              className="form-control"
                              {...formik.getFieldProps("password")}
                            ></input>
                            <button type="button" onClick={togglePassword}>
                              <div class="icon">
                                {isShown ? (
                                  <i class="bi bi-eye"></i>
                                ) : (
                                  <i class="bi bi-eye-slash"></i>
                                )}
                              </div>
                            </button>
                          </div>

                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3 from-group">
                          <label className="mb-1">
                            <strong>Confirm Password</strong>
                          </label>

                          <div className="password__container">
                            <input
                              type={show ? "text" : "password"}
                              id="confirmPassword"
                              name="confirmPassword"
                              className="form-control"
                              placeholder="Confirm Password"
                              {...formik.getFieldProps("confirmPassword")}
                            ></input>
                            <button type="button" onClick={toggleConfirm}>
                              <div class="icon">
                                {show ? (
                                  <i class="bi bi-eye"></i>
                                ) : (
                                  <i class="bi bi-eye-slash"></i>
                                )}
                              </div>
                            </button>
                          </div>

                          {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.confirmPassword}
                            </div>
                          ) : null}
                        </div>

                        <div className="row d-flex justify-content-between mt-2 mb-2">
                          <p className="">
                            <Link className="text-primary" to={Path.LOGIN}>
                              Back to Login
                            </Link>
                          </p>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {resetting ? (
                              <span
                                role="status"
                                aria-hidden="true"
                                className="spinner-border spinner-border-sm ms-2"
                              ></span>
                            ) : (
                              "Reset Password"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required("Confirm Password is Required"),
});

// http:///reset-password?token=G6RPB0HiKzAEQHavLnGrdvwT1osYdm&amp;node=olacoderpad@gmail.co
